<template>
  <div class="content-grid" id="settings">
    <div class="info-area">
      <h2>Kundendetails</h2>
      <div class="short-info-grid">
        <div class="wrapper">
          <div class="data-list">
            <span>Kundenname: </span
            ><span>{{ customerInfo.kdNameTabellenAnzeige }}</span>
            <span>Kundenadresse: </span
            ><span
              >{{ customerInfo.kdAnschriftStr }}
              {{ customerInfo.kdAnschriftHNr }},
              {{ customerInfo.kdAnschriftPLZ }}
              {{ customerInfo.kdAnschriftOrt }}</span
            >
            <span>Kundennummer: </span
            ><span>{{ customerInfo.kundenNummer }}</span>
            <span>Matchcode: </span><span>{{ customerInfo.kdMatch }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-area">
      <SwipeBar :elements="swipeBarElements" class="tab-bar" type="DEVICE" />
      <router-view />
    </div>
  </div>
</template>

<script>
import SwipeBar from "../../helper/SwipeBar";

export default {
  name: "Logbuch",
  data() {
    return {
      swipeBarElements: [
        {
          name: "Logbücher",
          route: "logbuecher"
        },
        {
          name: "Kältemittel",
          route: "logbuchKaeltemittel"
        },
        {
          name: "Statistik",
          route: "logbuchStatistikJahre"
        }
      ],
      customerInfo: undefined
    };
  },
  components: {
    SwipeBar
  },
  created() {
    this.getCustomerInfo();
  },
  methods: {
    async getCustomerInfo() {
      const customerInfo = await this.$technicianInfoService.getCustomerInfo(
        this.$route.params.nr
      );
      if (customerInfo) {
        this.customerInfo = customerInfo.data;
      }
    }
  }
};
</script>

<style scoped></style>
