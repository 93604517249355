<template>
  <div class="content-background" id="ProfileMain">
    <div>
      <h4>Anlagenhersteller/Service:</h4>
      <div class="basic-data-grid margin-top-20">
        <div>Firma:</div>
        <div>{{ logbuchInfo.herstellerName }}</div>
        <div>Straße:</div>
        <div>{{ logbuchInfo.herstellerStraße }}</div>
        <div>Hausnummer:</div>
        <div>{{ logbuchInfo.herstellerHausnummer }}</div>
        <div>Postleitzahl:</div>
        <div>{{ logbuchInfo.herstellerPlz }}</div>
        <div>Ort:</div>
        <div>{{ logbuchInfo.herstellerOrt }}</div>
        <div>Zertifizierungsnummer:</div>
        <div>{{ logbuchInfo.zertifizierungsnummer }}</div>
        <div>Abfallschlüssel Nr. Kältemittel:</div>
        <div>{{ logbuchInfo.abfallschluesselKaeltemittel }}</div>
        <div>Abfallschlüssel Nr. Öl:</div>
        <div>{{ logbuchInfo.abfallschluesselOel }}</div>
      </div>
    </div>
    <div @click="editInfo" class="button square-button dark-blue top-corner">
      <font-awesome-icon icon="pen" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AnlagenLogbuch",
  data() {
    return {
      logbuchInfo: {}
    };
  },
  created() {
    this.getLogbuchInfo();
  },
  methods: {
    async getLogbuchInfo() {
      const info = await this.$apiCall(
        this.$technicianInfoService.getLogbuchInfo()
      );
      if (info) {
        this.logbuchInfo = info.data;
      }
    },
    editInfo() {
      this.$router.push({ name: "anlagenLogbuchSettings" });
    }
  }
};
</script>

<style scoped></style>
