<template>
  <div id="deviceDetails" class="content-grid">
    <div class="info-area">
      <h2>Gerätedetails</h2>
      <div class="short-info-grid">
        <div class="wrapper">
          <div class="data-list">
            <span>Gerätenummer: </span><span>{{ device.nummer }}</span>
            <span>Name: </span><span>{{ device.name }}</span>
            <span>Baujahr: </span><span>{{ device.baujahr }}</span>
            <span>Beschreibung: </span><span>{{ device.beschreibung }}</span>
          </div>
          <div class="data-list">
            <span>Kundenname: </span
            ><span>{{ customer.kdNameTabellenAnzeige }}</span>
            <span>Kundenadresse: </span
            ><span
              >{{ customer.kdAnschriftStr }} {{ customer.kdAnschriftHNr }},
              {{ customer.kdAnschriftPLZ }} {{ customer.kdAnschriftOrt }}</span
            >
            <span>Kundennummer: </span><span>{{ customer.kundenNummer }}</span>
            <span>Matchcode: </span><span>{{ customer.kdMatch }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-area">
      <SwipeBar :elements="swipeBarElements" class="tab-bar" type="DEVICE" />
      <router-view
        :key="device.nummer"
        :device="device"
        :emptyFailureResponse="emptyFailureResponse"
        :emptyYearsResponse="emptyYearsResponse"
        :years="device.messungJahresZahlen"
        :geraeteNummer="device.nummer"
        :dataLoaded="dataLoaded"
        @reload-device-details="loadDeviceDetails"
      />
    </div>
  </div>
</template>

<script>
import SwipeBar from "../../helper/SwipeBar";

export default {
  name: "DeviceDetails",
  components: {
    SwipeBar
  },
  data() {
    return {
      device: {
        id: null,
        geraeteStoerungsStatus: null,
        offeneStoerungen: [],
        messungJahresZahlen: [],
        zusFunktionen: null,
        kaeltemittelart: null,
        kuhlsolehersteller: null,
        kaelteleistung: null,
        anzInneneinheit: null,
        positionierung: null,
        co2Aequivalent: null,
        gwp: null,
        hatLogbuch: false,
        oelmenge: null,
        bauart: null,
        oel: null,
        spannung: null,
        laufendeNummer: null,
        pumpentyp: null,
        nennstrom: null,
        serienNrVerdichte3: null,
        serienNrVerdichte5: null,
        verfluessigerOrt: null,
        ausloesevorrichtun: null,
        standortbeschreibu: null,
        funktionHeizKuehl: null,
        serienNrVerdichte6: null,
        serienNrVerdichte4: null,
        kaeltemittelmenge: null,
        frequenzumrichter: null,
        bauartVerdichter: null,
        artdesVerdichters: null,
        serienNrVerfluessi: null,
        anzVerflueLuefter: null,
        artderWaermepumpe: null,
        kuehlsoleherstelle: null,
        zulassungsnummer: null,
        keilriemenGroesse: null,
        zusaetzKomponenten: null,
        serienNrVerdichte2: null,
        herstellerVerflues: null,
        anschlussleistung: null,
        serienNrVerdichte1: null,
        typVerfluessiger: null,
        kondensatentsorgun: null,
        artdesVerfuessiger: null,
        artderVerflueDruck: null,
        artderFernbedienun: null,
        kaeltaemittelart: null,
        posVerfluessiger: null,
        keilriehmenAnzahl: null,
        fabTypVerdichter: null,
        anzahlVerdichter: null,
        kaelteWaermetraege: null,
        befeuchterleistung: null,
        zugehoerigesGeraet: null,
        bauartVerdampfer: null,
        wasserKuehlsolekon: null,
        fabTypVerfluessige: null,
        kuehlmittelmenge: null,
        anzahlVentilatoren: null,
        anzahlKuehlkreise: null,
        bauartVerfluessige: null,
        anzahlWaermetausch: null,
        rabTypVerdampfer: null,
        endschalter: null,
        konzentration: null,
        maxZulDruckHD: null,
        kaeltetraeger: null,
        anzVerdampfer: null,
        maxZulDruckND: null,
        revisionen: null,
        bauform2: null,
        bauform3: null,
        funktionHeizen: null,
        artVerdampfer: null,
        bauform1: null,
        anzKuehlstellen: null,
        verdichter6Typ: null,
        ventilator: null,
        verdichter1Typ: null,
        volumenstrom: null,
        verdichter3Typ: null,
        verdichter5Typ: null,
        verdichter2Typ: null,
        verdichter4Typ: null,
        rahmenstaerke1: null,
        luftfilter1: null,
        tiefe1: null,
        typV: null,
        taschenanzahl1: null,
        filterklasse1: null,
        breite1: null,
        luftfilter2: null,
        antriebsart: null,
        hersteller: null,
        anzahl2: null,
        breite2: null,
        hoehe1: null,
        anzahl1: null,
        hoehe2: null,
        tiefe2: null,
        anzahl3: null,
        breite3: null,
        rahmenstaerke2: null,
        anzahl4: null,
        hoehe4: null,
        breite4: null,
        rahmenstaerke3: null,
        tiefe4: null,
        tiefe3: null,
        luftfilter3: null,
        taschenanzahl3: null,
        hoehe3: null,
        filterklasse3: null,
        filterklasse2: null,
        taschenanzahl2: null,
        luftfilter4: null,
        waermeleistung: null,
        taschenanzahl4: null,
        typA: null,
        leistung: null,
        luftfilter: null,
        bypassklappe: null,
        kuehlregister: null,
        heizleistung: null,
        luftleistung: null,
        kuehlleistung: null,
        rahmenstaerke4: null,
        keilriemenTyp: null,
        heizregister: null,
        filterklasse4: null,
        funktionen: null,
        heizsystem: null,
        hoehe: null,
        breite: null,
        rahmenstaerke: null,
        einbauhoehe: null,
        kreislaufnummer: null,
        tiefe: null,
        taschenanzahl: null,
        kreislaufanzahl: null,
        art: null,
        regelungsart: null,
        anzahl: null,
        filterklasse: null,
        posVerdampfer: null,
        wasserkreislauf: null,
        posVerdichter: null,
        frostschutz: null,
        windfang: null,
        kuehlkreise: null,
        tuerart: null,
        tuerbreite: null,
        geraetebreite: null,
        montageart: null,
        aufstellung: null,
        kuehlmittelart: null,
        druck: null,
        geraet: null,
        typVerdichter: null,
        ortPosition: null,
        ansteuerung1: null,
        ansteuerung2: null,
        ansteuerung3: null,
        behaelterinhalt: null,
        name: null,
        ursprungsTabelle: null,
        garanteVL: null,
        info: null,
        typ: null,
        status: null,
        sn: null,
        agId: null,
        vtId: null,
        agNr: null,
        nummer: null,
        fabrikat: null,
        baujahr: null,
        bstNr: null,
        einbau: null,
        garantie: null,
        vtNr: null,
        hstNr: null,
        anlagennummer: null
      },
      customer: {},
      deviceClosedFailures: [],
      swipeBarElements: [
        { name: "Info", route: "deviceInfo" },
        { name: "Störung", route: "deviceFailures" },
        { name: "Technische Daten", route: "technischeDaten" },
        { name: "Messungen", route: "deviceMeasurements" },
        { name: "Logbuch", route: "deviceLogbuch" }
      ],
      measurements: [],
      measurementsToShow: 0,
      measurementDetailId: 0,
      emptyFailureResponse: false,
      emptyYearsResponse: false,
      dataLoaded: false
    };
  },
  created() {
    this.loadDeviceDetails();
  },
  methods: {
    async loadDeviceDetails() {
      let deviceNumber = this.$route.params.nr;
      const deviceInfo = await this.$apiCall(
        this.$technicianInfoService.getDeviceFullInfoByDeviceNumber(
          deviceNumber
        )
      );
      if (deviceInfo) {
        this.device = deviceInfo.data;
        this.$store.dispatch("misc/setContract", this.device.vtId);
        this.$store.dispatch("misc/setPlantNumber", this.device.anlagennummer);
        if (deviceInfo.data.offeneStoerungen.length === 0)
          this.emptyFailureResponse = true;
        if (deviceInfo.data.messungJahresZahlen.length === 0) {
          this.emptyYearsResponse = true;
        }
        const customerInfo = await this.$technicianInfoService.getCustomerInfo(
          deviceInfo.data.vtId
        );
        if (customerInfo) {
          this.customer = customerInfo.data;
        }
        this.dataLoaded = true;
      }
      this.reloadSwipebar();
    },
    reloadSwipebar() {
      let swipeBar = [
        { name: "Info", route: "deviceInfo" },
        { name: "Störung", route: "deviceFailures" },
        { name: "Technische Daten", route: "technischeDaten" },
        { name: "Messungen", route: "deviceMeasurements" },
        { name: "Logbuch", route: "deviceLogbuch" }
      ];
      /*
      if (this.device.hatLogbuch) {
        swipeBar.push({
          name: "Logbuch",
          route: "deviceLogbuch"
        });
      }*/
      swipeBar.push({
        name: "Störung melden",
        route: "deviceSendFailureMessage",
        params: {
          deviceNr: this.device.nummer,
          plantNr: this.device.anlagennummer
        }
      });
      this.swipeBarElements = swipeBar;
    }
  }
};
</script>
