<template>
  <tbody :class="isLinking ? '' : 'nicht-clickbar'">
    <tr
      v-for="(item, trIndex) in tableData"
      v-bind:key="item.nr"
      @click="clickEvent(trIndex)"
    >
      <router-link
        v-if="isLinking"
        :to="{
          name: routeName,
          params: {
            nr: getRouteParameter(item),
            appId: getAppIdParameter(item),
            secondparam: getRouteSecondParameter(item)
          }
        }"
      >
        <td v-for="(itemB, index) in tableContent" v-bind:key="itemB.nr">
          <div v-if="filter[index] === 'date'">
            {{ item[itemB] | date }}
          </div>
          <div v-else-if="filter[index] === 'dateWithoutTime'">
            {{ item[itemB] | dateWithoutTime }}
          </div>
          <div v-else-if="filter[index] === 'aktiv'">
            {{ item[itemB] | aktiv }}
          </div>
          <div v-else-if="filter[index] === 'status'">
            {{ item[itemB] | status }}
          </div>
          <div v-else-if="filter[index] === 'role'">
            {{ item[itemB] | role }}
          </div>
          <div v-else-if="filter[index] === 'boolean'">
            {{ item[itemB] | boolean }}
          </div>
          <div v-else-if="filter[index] === 'maintenanceState'">
            {{ item[itemB] | maintenanceState }}
          </div>
          <div v-else>
            <div
              v-if="showFailureStatus(item, item[itemB])"
              :class="
                hasNotResolvedFailures(item)
                  ? 'red-circle'
                  : isInProcess(item)
                  ? 'yellow-circle'
                  : 'green-circle'
              "
            />
            <div v-else>
              {{ item[itemB] }}
            </div>
          </div>
        </td>
        <td v-if="functionColumn" class="function-column">
          <slot name="function" :item="item" :index="trIndex"></slot>
        </td>
      </router-link>
      <td
        v-for="(itemB, index) in tableContent"
        v-else
        v-bind:key="itemB.nr"
        style="cursor: default"
      >
        <div v-if="filter[index] === 'date'">
          {{ item[itemB] | date }}
        </div>
        <div v-else-if="filter[index] === 'dateWithoutTime'">
          {{ item[itemB] | dateWithoutTime }}
        </div>
        <div v-else-if="filter[index] === 'aktiv'">
          {{ item[itemB] | aktiv }}
        </div>
        <div v-else-if="filter[index] === 'status'">
          {{ item[itemB] | status }}
        </div>
        <div v-else-if="filter[index] === 'role'">
          {{ item[itemB] | role }}
        </div>
        <div v-else-if="filter[index] === 'maintenanceState'">
          {{ item[itemB] | maintenanceState }}
        </div>
        <div v-else>{{ item[itemB] }}</div>
      </td>
      <td v-if="functionColumn" class="function-column">
        <slot name="function" :item="item" :index="trIndex"></slot>
      </td>
    </tr>
    <tr v-if="summenZeile" class="summen-zeile">
      <td>Summe</td>
      <td v-for="(summe, index) in summenInhalt" :key="index">{{ summe }}</td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "GeneralTableBody",
  data() {
    return {
      DATA_TYPES: Object.freeze({
        PLANT: "PLANT",
        DEVICES: "DEVICES",
        CONTRACTS: "CONTRACTS",
        CUSTOMERS: "CUSTOMERS",
        CUSTOMERSLOGBUCH: "CUSTOMERSLOGBUCH",
        CONTRACTS_FOR_CUSTOMER: "CONTRACTS_FOR_CUSTOMER",
        CONTRACTS_FOR_CUSTOMER_LOGBUCH: "CONTRACTS_FOR_CUSTOMER_LOGBUCH",
        USER: "USER",
        ADMINISTRATION: "ADMINISTRATION",
        FAILURE_LIST: "FAILURE_LIST",
        FAILURE_LIST_MELZER: "FAILURE_LIST_MELZER",
        FAULT: "FAULT",
        FAULT_MELZER: "FAULT_MELZER",
        CUSTOMERS_NOT_ACTIVATED_TECHNICIANS:
          "CUSTOMERS_NOT_ACTIVATED_TECHNICIANS",
        LOGBUCHUEBERSICHT: "LOGBUCHUEBERSICHT",
        LOGBUCHKAELTEMITTEL: "LOGBUCHKAELTEMITTEL",
        LOGBUCHJAHRE: "LOGBUCHJAHRE",
        LOGBUCHSTATISTIK: "LOGBUCHSTATISTIK",
        DEVICELOGBUCH: "DEVICELOGBUCH",
        DEVICETECHNISCHEDATEN: "DEVICETECHNISCHEDATEN",
        LOGBUCHUEBERISCHT: "LOGBUCHUEBERSICHT"
      }),
      notLinkingTypes: [
        "FAULT",
        "FAULT_MELZER",
        "MAINTENANCE",
        "MEASUREMENT_DATE",
        "MEASUREMENTS_PER_YEAR",
        "LOGBUCHKAELTEMITTEL",
        "LOGBUCHSTATISTIK",
        "DEVICETECHNISCHEDATEN",
        "DEVICELOGBUCH"
      ]
    };
  },
  props: {
    datatype: {
      type: String
    },
    tableContent: {
      type: Array
    },
    tableData: {
      type: Array
    },
    filter: {
      type: Array
    },
    routeName: {
      type: String,
      default: ""
    },
    functionColumn: {
      type: Boolean,
      default: false
    },
    summenZeile: {
      type: Boolean,
      default: false
    },
    summenInhalt: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    // prueft, ob ein Router-Link vor die table-data gesetzt werden soll; dadurch wird verhindert, dass leere Routen erzeugt werden, die das Routing stoeren
    isLinking: function() {
      return this.notLinkingTypes.indexOf(this.datatype) === -1;
    }
  },
  methods: {
    getRouteParameter(item) {
      if (this.datatype === this.DATA_TYPES.PLANT) {
        return item.nr;
      } else if (this.datatype === this.DATA_TYPES.DEVICES) {
        return item.nummer;
      } else if (this.datatype === this.DATA_TYPES.CONTRACTS) {
        return item.id;
      } else if (this.datatype === this.DATA_TYPES.CUSTOMERS) {
        return item.kundenNummer;
      } else if (this.datatype === this.DATA_TYPES.CUSTOMERSLOGBUCH) {
        return item.kundenNummer;
      } else if (this.datatype === this.DATA_TYPES.CONTRACTS_FOR_CUSTOMER) {
        return item.id;
      } else if (
        this.datatype === this.DATA_TYPES.CONTRACTS_FOR_CUSTOMER_LOGBUCH
      ) {
        return item.id;
      } else if (this.datatype === this.DATA_TYPES.LOGBUCHJAHRE) {
        return this.$attrs.vertragsId;
      } else if (this.datatype === this.DATA_TYPES.LOGBUCHUEBERISCHT) {
        return item.geraeteNummer;
      } else if (this.datatype === this.DATA_TYPES.USER) {
        return item.identifikationsName;
      } else if (
        this.datatype === this.DATA_TYPES.ADMINISTRATION ||
        this.datatype === this.DATA_TYPES.CUSTOMERS_NOT_ACTIVATED_TECHNICIANS
      ) {
        return item.kundenNummer;
      } else if (
        this.datatype === this.DATA_TYPES.FAILURE_LIST ||
        this.datatype === this.DATA_TYPES.FAILURE_LIST_MELZER
      ) {
        return item.geraeteNummer;
      }
      return "";
    },
    getAppIdParameter(item) {
      if (
        this.datatype === this.DATA_TYPES.ADMINISTRATION ||
        this.datatype === this.DATA_TYPES.CUSTOMERS_NOT_ACTIVATED_TECHNICIANS
      ) {
        return item.appKundenId;
      } else if (
        this.datatype === this.DATA_TYPES.CONTRACTS_FOR_CUSTOMER_LOGBUCH
      ) {
        return item.nr;
      }
      return null;
    },
    getRouteSecondParameter(item) {
      if (this.datatype === this.DATA_TYPES.LOGBUCHJAHRE) {
        return item.year;
      }
    },
    clickEvent(index) {
      if (this.datatype === this.DATA_TYPES.FAULT) {
        this.$emit("fault-click-event", index);
      }
      if (this.datatype === this.DATA_TYPES.FAULT_MELZER) {
        this.$emit("fault-click-event", index);
      } else {
        this.$emit("click-event", index);
      }
    },
    showFailureStatus(item, itemAtB) {
      return (
        (item.geraeteStoerungsStatus &&
          itemAtB === item.geraeteStoerungsStatus) ||
        (item.bearbeitungsStatus && itemAtB === item.bearbeitungsStatus)
      );
    },
    hasNotResolvedFailures(item) {
      return (
        (item.geraeteStoerungsStatus &&
          item.geraeteStoerungsStatus === "HatOffeneStoerungen") ||
        (item.bearbeitungsStatus && item.bearbeitungsStatus === "Neu")
      );
    },
    isInProcess(item) {
      return (
        (item.geraeteStoerungsStatus &&
          item.geraeteStoerungsStatus === "IstInBearbeitung") ||
        (item.bearbeitungsStatus && item.bearbeitungsStatus === "InBearbeitung")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.summen-zeile {
  td {
    border-top: 2px solid #0d2660;
  }
}

.general-table {
  tr.summen-zeile {
    &:hover {
      td {
        background: inherit;
        color: inherit;
      }
    }
  }
}
</style>
