<template>
  <div id="plantsForCustomer" class="table-has-filter">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      location-heading="Kundenanlagen"
      @update-filter="updateFilter"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="plants"
      datatype="PLANT"
      @change-order-by-click="changeOrderByClick"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import TechnicianInfoService from "@/services/technicianInfoService.js";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

const technicianInfo = new TechnicianInfoService();

export default {
  name: "PlantsForCustomer",
  data() {
    return {
      plants: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "nr",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  components: { FrontendFilter, TableNew },
  created() {
    this.getPlantTableByContractId();
  },
  methods: {
    async getPlantTableByContractId() {
      let contractId = this.$route.params.nr;
      await this.$store.dispatch("misc/setContract", contractId);
      const plant = await this.$apiCall(
        technicianInfo.getPlantTableByContractId(contractId)
      );
      if (plant) {
        this.plants = plant.data;
        this.tableDataBackup = plant.data;
        if (plant.data.length === 0) this.emptyResponse = true;
        this.sortTable();
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.plants = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
    }
  }
};
</script>

<style scoped></style>
