<template>
  <div id="dashboard-notifications">
    <div
      @click="routeToUsers"
      class="newTechnician-bar"
      v-if="deactivatedUsersLength > 0"
    >
      <span
        >{{ deactivatedUsersLength }}
        {{ deactivatedUsersLength > 1 ? "neue" : "neuer" }} Techniker</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardNotifications",
  data() {
    return {
      failureList: [],
      deactivatedUsersLength: null
    };
  },
  computed: {
    isMelzerAdmin() {
      return this.$store.getters["user/role"] === 10;
    }
  },
  created() {
    this.getNotActivatedUsers();
  },
  methods: {
    async getNotActivatedUsers() {
      if (this.isMelzerAdmin) {
        const activatedUser = await this.$apiCall(
          this.$technicianInfoService.getNotActivatedUsersForAllCustomers()
        );
        if (activatedUser) {
          this.deactivatedUsersLength =
            activatedUser.data.anzahlNichtFreigeschalteterNutzer;
        }
      } else {
        const deactivatedUser = await this.$apiCall(
          this.$technicianInfoService.getMyDeactivatedUsers()
        );
        if (deactivatedUser) {
          this.deactivatedUsersLength = deactivatedUser.data.length;
        }
      }
    },
    routeToUsers() {
      if (this.isMelzerAdmin) {
        this.$router.push({ name: "customerNotActivatedMelzerUsers" });
      } else this.$router.push({ name: "user" });
    }
  }
};
</script>

<style scoped></style>
