<template>
  <div
    @click="clickOnElement"
    class="tab-bar-element"
    v-bind:class="{ selected: selected }"
  >
    {{ element.name }}
  </div>
</template>

<script>
export default {
  name: "SwipeBarElement",
  props: {
    element: {
      type: Object
    }
  },
  computed: {
    selected: function() {
      return this.$route.name === this.element.route;
    }
  },
  methods: {
    clickOnElement() {
      let route = {
        name: this.element.route
      };
      if (this.element.params) {
        route.params = this.element.params;
      }
      this.$router.push(route);
    }
  }
};
</script>
