<template>
  <div id="home">
    <LeftSideMenu id="leftsidemenu" />
    <div id="main">
      <div id="datebar">
        <div class="date-row">
          <BackButton class="backbutton" />
          <PlantsOverviewButton class="plants-overview-button" />
          <TopDate />
        </div>
        <div class="search-row">
          <SearchBar />
        </div>
      </div>
      <!-- Durch das key-binding wird das router-view auch geupdatet, wenn die route gleich bleibt und sich nur die parameter aendern, z.B. bei der Suche-->
      <router-view :key="$route.params.nr" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LeftSideMenu from "@/components/navigation/LeftSideMenu.vue";
import TopDate from "@/components/main/TopDate.vue";
import BackButton from "@/components/helper/BackButton.vue";
import PlantsOverviewButton from "@/components/helper/PlantsOverviewButton.vue";
import SearchBar from "../components/main/SearchBar";

export default {
  name: "home",
  components: {
    SearchBar,
    LeftSideMenu,
    TopDate,
    BackButton,
    PlantsOverviewButton
  },
  created() {
    // TODO: Weiterleitung zur InfoPage wenn ein QR-Code gelesen wird. Das sollte am besten direkt beim Routing passieren und nicht in der PageNotFound
    if (this.$route.path.includes("QR=") || this.$route.path.includes("id=")) {
      let deviceNumber = this.$route.path.substr(4, this.$route.path.length);
      this.$router.push({
        name: "infoPage",
        params: {
          deviceNumber: this.$route.path.substr(4, this.$route.path.length)
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.plants-overview-button {
  position: absolute;
  left: 100px;
}
</style>
