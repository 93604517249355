<template>
  <div>
    <div class="side-overlay-background"></div>
    <div id="sideOverlay" v-on-clickaway="away">
      <div class="side-overlay-header">
        <h3>Störung im Status {{ bearbeitungsStatusComp }}</h3>
        <div class="flex-row-center">
          <div @click="changeBearbeitungsStatus" class="button margin-right-20">
            {{ labelButtonStatus }}
          </div>
          <div @click="closeSideOverlay()" class="button square-button">
            <font-awesome-icon icon="times" />
          </div>
        </div>
      </div>
      <div class="meldungsdaten">
        <p>Störungsmeldung Nummer: {{ sideData.id }}</p>
        <p>
          Datum der Störungsaufnahme: {{ sideData.stoerungsZeitpunkt | date }}
        </p>
        <p>
          Aufgenommen von:
          <a
            :href="
              'mailto:' +
                sideData.eMailAdresse +
                '?subject=' +
                encodeURIComponent('Störungsmeldung ' + sideData.geraeteNummer)
            "
            class="font-lightblue"
            >{{ sideData.eMailAdresse }}</a
          >
        </p>
        <p>Störungsbeschreibung: {{ sideData.stoerungsbeschreibung }}</p>
        <div
          class="button button-gallery"
          @click="loadGallery"
          v-if="sideData.anzahlBilder > 0 && !isGalleryLoaded"
        >
          Bilder laden ({{ sideData.anzahlBilder }} Anhänge)
        </div>
        <div
          class="picture-gallery margin-bottom-20"
          v-if="sideData.anzahlBilder > 0 && isGalleryLoaded"
        >
          <img
            :src="picture.base64Data"
            v-bind:key="picture.id"
            v-for="picture in sideData.stoerungsBilder"
            @click="
              showImg($event);
              bigPicture = true;
            "
          />
        </div>
        <div
          v-show="bigPicture"
          id="bigPictureStoerung"
          class="img-container"
          @click="bigPicture = false"
        ></div>
        <div
          v-for="bemerkung in sideData.stoerungsBemerkungen"
          :key="bemerkung.id"
          :class="[
            bemerkung.automatischErstellt ? 'systemnachricht' : 'bemerkung',
            {
              'ist-ungelesen': !bemerkung.gelesen,
              'ist-eigene':
                bemerkung.vonMelzerErstellt === userDTO.istMelzerUser
            }
          ]"
        >
          <div class="nachricht">
            <div
              class="button"
              @click="bemerkungBestaetigen(bemerkung.id)"
              v-if="
                !bemerkung.gelesen &&
                  bemerkung.vonMelzerErstellt !== userDTO.istMelzerUser
              "
            >
              zur Kenntnis genommen
            </div>
            {{ bemerkung.bemerkungsText }}
          </div>
          <div class="metadaten">
            {{ bemerkung.verfasserVorname }} {{ bemerkung.verfasserName }}
            {{
              bemerkung.verfasserFirmaName
                ? "von " + bemerkung.verfasserFirmaName + ";"
                : ""
            }}
            {{ bemerkung.erstellDatum | date }}
          </div>
          <div
            class="metadaten"
            v-if="!bemerkung.automatischErstellt && bemerkung.gelesen"
          >
            {{
              bemerkung.gelesenStatus === 2
                ? "Autom. bestätigt"
                : "Zur Kenntnis genommen"
            }};
            {{ bemerkung.gelesenDatum | date }}
          </div>
        </div>
        <div
          class="bemerkung-schreiben"
          v-if="sideData.bearbeitungsStatus !== 'Behoben'"
        >
          <span>Bemerkung:</span>
          <textarea
            :class="{
              validationError:
                !$v.eingabeNeueBemerkung.maxLength ||
                meldungBemerkungBenoetigtSichtbar
            }"
            v-model="$v.eingabeNeueBemerkung.$model"
            placeholder="Bitte eine Bemerkung angeben."
          />
          <div
            class="error-label margin-bottom-10"
            v-if="meldungBemerkungBenoetigtSichtbar"
          >
            Störung kann erst Erledigt markiert werden, wenn mindestens eine
            Anmerkung hinzugefügt wurde.
          </div>
          <div
            class="button"
            @click="bemerkungHinzufuegen"
            v-show="
              !$v.eingabeNeueBemerkung.$anyError &&
                $v.eingabeNeueBemerkung.$dirty
            "
          >
            Anmerkung Speichern
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  directives: {
    onClickaway: onClickaway
  },
  name: "sideOverlay",
  props: {
    sideData: {
      type: Object,
      default() {
        return {
          id: 0,
          kundenNummer: 0,
          geraeteNummer: 0,
          anlagenNummer: 0,
          eMailAdresse: "",
          stoerungsbeschreibung: "",
          stoerungsZeitpunkt: null,
          emailBestaetigt: false,
          behobenAm: null,
          anmerkung: null,
          bearbeitungsStatus: "",
          stoerungsBilder: []
        };
      }
    }
  },
  data() {
    return {
      userDTO: {},
      eingabeNeueBemerkung: "",
      meldungBemerkungBenoetigtSichtbar: false,
      showGallery: false,
      bigPicture: false
    };
  },
  computed: {
    bearbeitungsStatusComp() {
      switch (this.sideData.bearbeitungsStatus) {
        case "InBearbeitung":
          return "In Bearbeitung";
          break;
        case "Neu":
          return "Offen";
          break;
        case "Behoben":
          return "Erledigt";
          break;
      }
    },
    labelButtonStatus() {
      if (this.sideData.bearbeitungsStatus === "Neu") {
        return "Störung in Bearbeitung nehmen";
      } else if (this.sideData.bearbeitungsStatus === "InBearbeitung") {
        return "Störung erledigen";
      }
      // else === Behoben
      return "Störung erneut öffnen";
    },
    isGalleryLoaded() {
      for (let bild of this.sideData.stoerungsBilder) {
        if (!bild.base64Data) {
          return false;
        }
      }
      return true;
    }
  },
  created() {
    this.initUserDTO();
  },
  methods: {
    initUserDTO() {
      // TODO: UserDTO aus parent oder store holen und nicht beim Öffnen jeder Störung erneut abfragen
      this.$technicianInfoService
        .getMyUser()
        .then(response => {
          this.userDTO = response.data;
        }, this)
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    closeSideOverlay() {
      this.$emit("close-event");
    },
    away: function() {
      this.$emit("close-click");
    },
    loadGallery() {
      // die Bilder werden in der Stoerung gespeichert, dadurch wird verhindert,
      // dass bei einem mehrfachen oeffnen derselben Stoerung die Bilder neu geladen werden muessen
      if (!this.isGalleryLoaded) {
        this.$technicianInfoService
          .getFailurePictures(this.sideData.id)
          .then(response => {
            this.sideData.stoerungsBilder = response.data;
          })
          .catch(error => {
            this.$handleRestExceptions(error.response.status, this.$router);
          });
      }
    },
    changeBearbeitungsStatus() {
      if (this.sideData.bearbeitungsStatus === "Neu") {
        this.sideData.bearbeitungsStatus = "InBearbeitung";
      } else if (this.sideData.bearbeitungsStatus === "InBearbeitung") {
        if (this.sideData.kannSchliesen) {
          this.sideData.bearbeitungsStatus = "Behoben";
        } else {
          this.meldungBemerkungBenoetigtSichtbar = true;
        }
      } else if (this.sideData.bearbeitungsStatus === "Behoben") {
        this.sideData.bearbeitungsStatus = "Neu";
      }
      this.updateFailureMessage();
    },
    updateFailureMessage() {
      this.$technicianInfoService
        .updateFailureMessage(this.sideData)
        .then(response => {
          this.reloadDeviceDetails();
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    reloadDeviceDetails() {
      this.$emit("reload-device-details");
    },
    async bemerkungHinzufuegen() {
      if (
        !this.$v.eingabeNeueBemerkung.$anyError &&
        this.$v.eingabeNeueBemerkung.$dirty
      ) {
        let bemerkung = {
          stoerungsMeldungID: this.sideData.id,
          bemerkungsText: this.eingabeNeueBemerkung
        };
        await this.$technicianInfoService
          .stoerungsBemerkungHinzufuegen(bemerkung, self)
          .then(response => {
            this.reloadDeviceDetails();
            this.eingabeNeueBemerkung = "";
            this.meldungBemerkungBenoetigtSichtbar = false;
            this.$v.eingabeNeueBemerkung.$reset();
          })
          .catch(error => {
            this.$handleRestExceptions(error.response.status, this.$router);
          });
      }
    },
    async bemerkungBestaetigen(bemerkungId) {
      let bestaetigung = {
        id: bemerkungId,
        stoerungsMeldungID: this.sideData.id
      };
      await this.$technicianInfoService
        .stoerungsBemerkungBestaetigen(bestaetigung, self)
        .then(response => {
          this.reloadDeviceDetails();
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    showImg(e) {
      const imgContainer = document.getElementById("bigPictureStoerung");
      imgContainer.textContent = "";
      const image = e.target;
      const imageCopy = image.cloneNode(true);
      imgContainer.appendChild(imageCopy);
    }
  },
  validations() {
    return {
      eingabeNeueBemerkung: {
        required,
        maxLength: maxLength(450)
      }
    };
  }
};
</script>

<style lang="scss">
.img-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  img {
    object-fit: contain;
    cursor: pointer;
  }
}
</style>
