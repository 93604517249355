<template>
  <div @click="goBack" id="backButton" class="button">
    <font-awesome-icon icon="level-down-alt" class="turn-upside-down" /> zurück
  </div>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    goBack: function() {
      // goes one step back
      if (!this.$store.state.misc.enableRouterBack) {
        this.$store.dispatch("misc/setAlternativeRouteBack", true);
        this.$store.dispatch("misc/setEnableRouterBack", true);
      } else {
        this.$store.dispatch("misc/setAlternativeRouteBack", false);
        this.$router.go(-1);
      }
    }
  }
};
</script>
