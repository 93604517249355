<template>
  <div
    id="MeasurementDetails"
    class="content-background full-height overflow-y-scroll"
  >
    <div class="grid-table">
      <div
        v-for="(value, key, index) in measurementBereinigt"
        :key="index"
        class="grid-row"
      >
        <span>{{ $filterMeaningfulNames(key) }}</span>
        <span>{{ $filterValueKey(value, key) }}</span>
      </div>
    </div>
    <div
      v-if="restDataLoaded"
      class="button square-button top-corner "
      v-on:click="createPDF"
    >
      <font-awesome-icon class="" icon="file-alt" />
    </div>
  </div>
</template>

<script>
import geraeteTypMessungen from "@/catalogue/geraete-typ-messungen";

export default {
  name: "MeasurementDetails",
  data() {
    return {
      measurement: {},
      measurementBereinigt: {},
      restDataLoaded: false,
      hiddenProperties: [],
      geraeteTypMessungen: geraeteTypMessungen
    };
  },
  props: {
    measurementDetailId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    geraeteTyp() {
      switch (this.measurement.ursprungsTabelle) {
        case "Messung001":
          return "Innengeraet";
        case "Messung002":
          return "Aussengeraet";
        case "Messung003":
          return "Verdampfer";
        case "Messung004":
          return "Kuehlaggregat";
        case "Messung005":
          return "steckerfKuehlgeraet";
        case "Messung007":
          return "Kaltwassersatz";
        case "Messung009":
          return "Brandschutzklappe";
        case "Messung010":
          return "Verbund";
        case "Messung011":
          return "Geblaesekonvektor";
        case "Messung012":
          return "Zuluftsystem";
        case "Messung013":
          return "Abluftsystem";
        case "Messung014":
          return "Waermerueckgewinnung";
        case "Messung015":
          return "Luftbefeuchtung";
        case "Messung016":
          return "Regelungstechnik";
        case "Messung017":
          return "Verdampferkit";
        case "Messung018":
          return "Tuerluftschleier";
        case "Messung019":
          return "Hydromodul";
        case "Messung020":
          return "Trockenrueckkuehler";
        case "Messung021":
          return "Rauchmelder";
        case "Messung022":
          return "Kaeltemaschine";
        case "Messung023":
          return "Waermepumpe";
        case "Messung024":
          return "Redundanzschaltung";
        case "Messung025":
          return "Pufferspeicher";
        case "Messung026":
          return "Ozongeraet";
        case "Messung027":
          return "ZonenWaermetauscher";
        case "Messung028":
          return "Kaeltemaschine";
        case "Messung029":
          return "Brandschutzklappe";
        case "Messung030":
          return "Kaltwassersatz";
        case "Messung031":
          return "Verbund";
        case "Messung032":
          return "Kuehlaggregat";
        case "Messung033":
          return "Waermepumpe";
        case "Messung034":
          return "steckerfKuehlgeraet";
        case "Messung035":
          return "Verdampfer";
        case "Messung036":
          return "Verdampferkit";
        case "Messung037":
          return "ZonenWaermetauscher";
        case "Messung038":
          return "Hydromodul";
        case "Messung039":
          return "Trockenrueckkuehler";
        case "Messung040":
          return "Tuerluftschleier";
        case "Messung041":
          return "Redundanzschaltung";
        case "Messung042":
          return "Regelungstechnik";
        case "Messung043":
          return "Rauchmelder";
        case "Messung044":
          return "Pufferspeicher";
        case "Messung045":
          return "Leiter";
        case "Messung046":
          return "Fuehlerabgleich";
        case "Messung047":
          return "Gaswarngeraet";
        default:
          return undefined;
      }
    }
  },
  created() {
    this.getMeasurementFullInfoById();
    this.$store.dispatch("misc/setEnableRouterBack", false);
  },
  methods: {
    async getMeasurementFullInfoById() {
      const measurementInfo = await this.$apiCall(
        this.$technicianInfoService.getMeasurementFullInfoById(
          this.measurementDetailId
        )
      );
      if (measurementInfo) {
        const self = this;
        this.measurement = measurementInfo.data;
        const geraeteTypMessungen = this.geraeteTypMessungen.geraeteTypObject[
          this.geraeteTyp
        ];
        this.restDataLoaded = true;
        Object.entries(self.measurement).forEach(entry => {
          let [key, value] = entry;
          const keyVar = key;
          const valueVar = value;
          Object.entries(geraeteTypMessungen).forEach(entry => {
            let [key, value] = entry;
            if (key === keyVar) {
              self.measurementBereinigt[value] = valueVar;
            }
          });
        });
      }
    },
    async createPDF() {
      const pdf = await this.$apiCall(
        this.$technicianInfoService.getMeasurementPDF(this.measurementDetailId)
      );
      if (pdf) {
        const url = window.URL.createObjectURL(new Blob([pdf.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename =
          "Wartungsprotokoll_" +
          this.measurement.grtName +
          "_" +
          this.measurement.grtNummer +
          ".pdf";
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-table {
  width: 800px;
  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
  }
}
</style>
