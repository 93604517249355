<template>
  <div id="FrontendFilter">
    <div class="flex-row-start">
      <h2 v-if="locationHeading" class="no-margin margin-right-20">
        {{ locationHeading }}
      </h2>
      <input
        class="margin-right-5"
        id="frontendFilterInput"
        placeholder="Filter eingeben"
        type="text"
        v-model="newFilter"
        v-on:keyup.enter="addFilter"
      />
      <input
        @click="addFilter"
        id="addFilter"
        type="button"
        value="Hinzufügen"
      />
      <label for="addFilter">
        <font-awesome-icon class="button margin-right-20" icon="plus" />
      </label>
      <div
        class="filter-tag margin-right-20"
        v-bind:key="filter"
        v-for="(filter, index) in filters"
      >
        <span class="margin-right-5">{{ filter }}</span>
        <font-awesome-icon
          @click="deleteFilter(index)"
          class="font-lightblue cursor-pointer"
          icon="times-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontendFilter",
  data() {
    return {
      newFilter: ""
    };
  },
  props: {
    filters: {
      type: Array
    },
    locationHeading: {
      type: String
    }
  },
  methods: {
    addFilter() {
      if (this.newFilter !== "") {
        this.filters.push(this.newFilter);
        this.newFilter = "";
        this.emitFilter();
      }
    },
    deleteFilter(index) {
      if (this.filters.length > 0) {
        this.filters.splice(index, 1);
        this.emitFilter();
      }
    },
    emitFilter() {
      this.$emit("update-filter", this.filters);
    }
  }
};
</script>

<style scoped></style>
