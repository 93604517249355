<template>
  <div id="swipeBar">
    <h2>{{ $attrs.customer }}</h2>
    <SwipeBarElement
      :element="element"
      v-bind:key="element.name"
      v-for="element in elements"
    />
  </div>
</template>

<script>
import SwipeBarElement from "./SwipeBarElement";
import PlantsOverviewButton from "@/components/helper/PlantsOverviewButton";

export default {
  name: "SwipeBar",
  props: {
    elements: {
      type: Array
    }
  },
  components: {
    SwipeBarElement,
    PlantsOverviewButton
  }
};
</script>
