<template>
  <div class="general-wrapper center-grid" id="login">
    <div class="flex-column-center">
      <img
        alt="Logo der Firma"
        class="short-info-logo"
        src="@/assets/melzer_flocke.png"
      />
      <div class="width500">
        <input
          id="email"
          placeholder="E-Mail"
          type="email"
          v-model="$v.email.$model"
        />
        <span class="error-label" v-show="userLoginFailureMessage">{{
          userLoginFailureMessage
        }}</span>
        <div class="position-relative">
          <input
            id="password"
            placeholder="Passwort"
            type="password"
            v-model="$v.password.$model"
            v-on:keyup.enter="login"
          />
          <label for="loginButton">
            <font-awesome-icon class="loginButtonArrow" icon="arrow-right" />
          </label>
        </div>
        <span
          class="error-label margin-bottom-20"
          v-show="passwordLoginFailureMessage"
          >{{ passwordLoginFailureMessage }}</span
        >
        <span
          >Noch nicht registriert?
          <router-link class="link" to="/registration">
            Registrieren Sie sich hier.
          </router-link>
        </span>
        <span
          >Passwort vergessen?
          <router-link class="link" to="/requestNewPassword">
            Setzen Sie es hier zurück.
          </router-link>
        </span>
      </div>
      <div>
        <input
          @click="login"
          id="loginButton"
          type="button"
          value="Einloggen"
        />
      </div>
    </div>

    <modal
      :clickToClose="false"
      :name="datenschutzerklaerungModalName"
      height="150px"
    >
      <div class="modal-grid">
        <div class="modal-text">
          Ich habe die Informationen zum
          <a :href="datenschutzerklaerungPdfLink" class="link" target="_blank"
            >Datenschutz</a
          >
          gelesen und akzeptiert
        </div>
        <input
          @click="hideDatenschutzerklaerungModal"
          class="modal-button-no button confirm-button"
          type="button"
          value="Nein"
        />
        <input
          @click="acceptDatenschutzerklaerung"
          class="modal-button-yes button confirm-button button-lightblue"
          type="button"
          value="Ja"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ResponseEnum } from "../enums/responseEnum";
import datenschutzerklaerungLink from "@/assets/Datenschutzerklärung Melzer.pdf";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      userLoginFailureMessage: "",
      passwordLoginFailureMessage: "",
      userLoginFailureMessages: Object.freeze({
        NOT_ACTIVATED:
          "Ihr Nutzerprofil muss zunächst durch einen Techniker freigeschaltet werden.",
        NOT_EXISTING: "Ihr Benutzer existiert nicht."
      }),
      passwordLoginFailureMessages: Object.freeze({
        WRONG_PASSWORD: "Diese Zugangsdaten sind nicht korrekt."
      }),
      datenschutzerklaerungModalName: "datenschutzerklaerung-modal",
      tempLoginData: {},
      datenschutzerklaerungPdfLink: datenschutzerklaerungLink
    };
  },
  validations: {
    email: {
      required
    },
    password: {
      required
    }
  },
  created() {
    if (
      this.$route.path.includes("id=") &&
      this.$route.path.includes("geheimnis=")
    ) {
      let deviceNumber = this.$route.path.substr(4, this.$route.path.length);
      this.$router.push({
        name: "infoPage",
        params: {
          deviceNumber: deviceNumber
        }
      });
    }
    // TODO: Weiterleitung zur InfoPage wenn ein QR-Code gelesen wird. Das sollte am besten direkt beim Routing passieren und nicht in der PageNotFound
    if (this.$route.path.includes("QR=") || this.$route.path.includes("id=")) {
      this.$router.push({
        name: "infoPage",
        params: {
          deviceNumber: this.$route.path.substr(4, this.$route.path.length)
        }
      });
    }

    // Benutzer ist angemeldet und wird direkt zum Dashboard geleitet
    if (
      this.$store.getters["user/userName"] &&
      this.$store.getters["user/token'"]
    ) {
    }
  },
  methods: {
    async login() {
      if (this.$v.$error) {
        this.$toast.error("Bitte Email und Passwort eingeben!");
      } else {
        this.userLoginFailureMessage = "";
        this.passwordLoginFailureMessage = "";
        const loginResponse = await this.$apiCall(
          this.$loginService.login(this.email, this.password)
        );
        if (loginResponse) {
          switch (loginResponse.data) {
            case ResponseEnum.BenutzerNichtFreigeschaltet:
              this.userLoginFailureMessage = this.userLoginFailureMessages.NOT_ACTIVATED;
              break;
            case ResponseEnum.FalscheDaten:
            case ResponseEnum.PasswortFalsch:
              this.passwordLoginFailureMessage = this.passwordLoginFailureMessages.WRONG_PASSWORD;
              break;
            default:
              if (loginResponse.data["dsgvoAkzeptiert"] === true) {
                if (loginResponse.data["token"]) {
                  // Workaround fuer Chrome, der automatisch anmeldedaten tauscht
                  loginResponse.data.email = this.email;
                  this.setUserData(loginResponse.data);
                }
              } else {
                this.tempLoginData = loginResponse.data;
                // Workaround fuer Chrome, der automatisch anmeldedaten tauscht
                this.tempLoginData.email = this.email;
                this.showDatenschutzerklaerungModal();
              }
          }
        }
      }
    },
    showDatenschutzerklaerungModal() {
      this.$modal.show(this.datenschutzerklaerungModalName);
    },
    hideDatenschutzerklaerungModal() {
      this.$modal.hide(this.datenschutzerklaerungModalName);
    },
    async acceptDatenschutzerklaerung() {
      const response = await this.$apiCall(
        this.$userInfoService.acceptDSGVO(this.tempLoginData)
      );
      if (response) {
        this.setUserData(this.tempLoginData);
      }
    },
    setUserData(data) {
      this.$store.dispatch("user/setToken", data["token"]);
      this.$store.dispatch("user/setRole", data["berechtigungsStufe"]);
      this.$store.dispatch("user/setIsMelzerUser", data["istMelzerUser"]);
      this.$store.dispatch("user/setAppUserId", data["appKundenID"]);
      this.$store.dispatch("user/setCustomerNumber", data["kundenNummer"]);
      this.$store.dispatch("user/setUserName", data["email"]);
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>

<style scoped>
.modal-grid {
  display: grid;
  height: calc(100% - 20px);
  grid-template-columns: auto auto;
  grid-column-gap: 40%;
  grid-row-gap: 15px;
  padding: 10px 10px 10px 10px;
  grid-template-areas: "text text" "button-no button-yes";
}

.modal-text {
  grid-area: text;
}

.modal-button-yes {
  grid-area: button-yes;
  align-self: end;
}

.modal-button-no {
  grid-area: button-no;
  align-self: end;
}
</style>
