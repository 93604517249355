String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

let exceptionTime = new Date(),
  exceptionTimer = 1000 * 2; // 2 Seconds

export default {
  install(Vue, options) {
    Vue.mixin({
      created() {
        // console.log("hello");
      }
    });
    /** Api Handling **/
    Vue.prototype.$apiCall = async function(method) {
      this.$store.commit("misc/setCallPending", true);
      try {
        const response = await method;
        this.$store.commit("misc/setCallPending", false);
        return response;
      } catch (error) {
        this.$store.commit("misc/setCallPending", false);
        fehlerHandling(error.response.status, this.$router, this.$toast);
      }
    };

    /** Fehler Handling **/
    const fehlerHandling = function(code, router, toast) {
      // Zeigt Fehlermeldung nur einmal alle exceptionTimer Sekunden
      let notDuplicatedException =
        new Date() > new Date(exceptionTime.getTime() + exceptionTimer);
      if (notDuplicatedException) {
        exceptionTime = new Date();
      }
      switch (code) {
        // Forbidden
        case 403:
          if (notDuplicatedException) toast.error("Anmeldetoken abgelaufen!");
          router.push({ name: "login" }).catch(err => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.error(err);
            }
          });
          break;
        case 500:
        case 404:
          if (notDuplicatedException)
            toast.error(
              "Ein interner Fehler ist aufgetreten! Versuchen Sie es später noch einmal."
            );
          break;
        case 406:
          if (notDuplicatedException)
            toast.error(
              "Sie haben keine Berechtigung diese Aktion durchzuführen"
            );
          this.$router.go(-1);
          break;
        default:
          if (notDuplicatedException)
            toast.error(
              "Ein interner Fehler ist aufgetreten! Versuchen Sie es später noch einmal."
            );
          router.push({ name: "login" }).catch(err => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.error(err);
            }
          });
          break;
      }
    };

    // Vue Filter als Vorbereitung auf Vue 3 ueber Plugin
    Vue.prototype.$filterMeaningfulNames = function(name) {
      switch (name) {
        // Equally for all types of devices
        case "Date":
          return "Datum";
        case "vtId":
          return "VertragsID";
        case "agId":
          return "AnlagenID";
        case "VtNr":
          return "Vertragsnummer";
        case "AgNr":
          return "Anlagennummer";
        case "einbau":
          return "Einbaudatum";
        case "garantieverlaengerung":
          return "Garantieverlängerung";

        // Aussengeraet
        case "kaeltemittelart":
          return "Kältemittelart";
        case "gwp":
          return "GWP";
        case "kaeltemittelmenge":
          return "Kältemittelmenge";
        case "co2Aequivalent":
          return "CO2-Äquivalent";
        case "oel":
          return "Öl";
        case "oelmenge":
          return "Ölmenge";
        case "maxZulDruckHD":
          return "Max. Zul. Druck HD";
        case "maxZulDruckND":
          return "Max. Zul. Druck ND";
        case "bauartVerdichter":
          return "Bauart Verdichter";
        case "artdesVerfuessiger":
          return "Art des Verflüssigers";
        case "anzInneneinheit":
          return "Anzahl der angeschlossenen Inneneinheiten";
        case "positionierung":
          return "Ort/Positionierung";
        case "kaelteleistung":
          return " Kälteleistung";

        // Verdampfer
        case "laufendeNummer":
          return "Laufende Nummer verdampfer";

        // Kuehlaggregat
        case "anzVerdampfer":
          return "Anzahl der angeschlossenen Verdampfer";

        // Kuehlgeraet
        case "bauform2":
          return "Bauform/ Typ des Verdampfers";
        case "bauform3":
          return "Bauform/ Typ des Verflüssigers";
        case "zusaetzKomponenten":
          return "Zusätzliche Gerätekomponenten";

        // Sonstiges
        case "artderFernbedienun":
          return "Art der Fernbedienung";
        case "kondensatentsorgun":
          return "Art der Kondensatentsorung";
        default:
          return name.capitalize();
        /*
          case "":
            return "";
            */
      }
    };

    Vue.prototype.$filterValueKey = function(value, key) {
      if (value !== true && value !== false) {
        switch (key) {
          case "einbau":
          case "date":
          case "datumUnterschrift":
          case "Datum / Unterschrift":
          case "Datum":
            return this.$filterOnlyDate(value);
          default:
            return value;
        }
      } else if (value === true || value === false) {
        switch (value) {
          case true:
            return "Ja";
          case false:
            return "Nein";
          default:
            return value;
        }
      } else return;
    };

    Vue.prototype.$filterDate = function(date) {
      if (!date) return "";
      let onlyDate = date.toString().substr(0, date.toString().indexOf("T"));
      let time = date.toString().substr(date.toString().indexOf("T") + 1, 8);
      let dateArray = onlyDate.split("-");
      let timeArray = time.split(":");
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      return new Date(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        timeArray[0],
        timeArray[1],
        timeArray[2]
      )
        .addHours(2)
        .toLocaleDateString("de-DE", options);
    };

    Vue.prototype.$filterOnlyDate = function(date) {
      if (!date) return "";
      let onlyDate = date.toString().substr(0, date.toString().indexOf("T"));
      let dateArray = onlyDate.split("-");
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
        .addHours(2)
        .toLocaleDateString("de-DE", options);
    };
  }
};
