<template>
  <div class="content-background width800" id="CreateUser">
    <div class="basic-data-grid">
      <div v-if="showMelzerTechnicianButton">Melzer Techniker anlegen</div>
      <div v-if="showMelzerTechnicianButton">
        <input
          @click="changeIsMelzerTechnician"
          type="checkbox"
          v-model="userDTO.istMelzerUser"
        />
      </div>
      <div>Nachname</div>
      <div>
        <input
          placeholder="Nachname"
          type="text"
          v-bind:class="{
            validationError: !$v.userDTO.nachname.maxLength
          }"
          v-model="$v.userDTO.nachname.$model"
        />
        <span class="error-label" v-show="!$v.userDTO.nachname.required"
          >Nachname erforderlich</span
        >
        <span class="error-label" v-show="!$v.userDTO.nachname.maxLength"
          >Maximal 255 Zeichen</span
        >
      </div>
      <div>Vorname</div>
      <div>
        <input
          placeholder="Vorname"
          type="text"
          v-bind:class="{
            validationError: !$v.userDTO.vorname.maxLength
          }"
          v-model="$v.userDTO.vorname.$model"
        />
        <span class="error-label" v-show="!$v.userDTO.vorname.required"
          >Vorname erforderlich</span
        >
        <span class="error-label" v-show="!$v.userDTO.vorname.maxLength"
          >Maximal 255 Zeichen</span
        >
      </div>
      <div>Telefon</div>
      <div>
        <input
          placeholder="Telefonnummer"
          type="text"
          v-bind:class="{
            validationError: !$v.userDTO.telefonNummer.maxLength
          }"
          v-model="$v.userDTO.telefonNummer.$model"
        />
        <span class="error-label" v-show="!$v.userDTO.telefonNummer.required"
          >Telefonnummer erforderlich</span
        >
        <span class="error-label" v-show="!$v.userDTO.telefonNummer.maxLength"
          >Maximal 255 Zeichen</span
        >
      </div>
      <div>E-Mail</div>
      <div>
        <input
          placeholder="E-Mail"
          type="email"
          v-bind:class="{
            validationError: !$v.userDTO.identifikationsName.maxLength
          }"
          v-model="$v.userDTO.identifikationsName.$model"
        />
        <span
          class="error-label"
          v-show="
            !$v.userDTO.identifikationsName.required ||
              !$v.userDTO.identifikationsName.email
          "
          >Gültige E-Mail Adresse erforderlich</span
        >
        <span
          class="error-label"
          v-show="!$v.userDTO.identifikationsName.maxLength"
          >Maximal 255 Zeichen</span
        >
      </div>
      <div>Kundennummer</div>
      <div>
        <input
          :disabled="isRegistration ? false : true"
          placeholder="Kundennummer"
          type="text"
          v-model="$v.userDTO.kundenNummer.$model"
        />
        <span
          v-if="isRegistration"
          class="error-label"
          v-show="!$v.userDTO.kundenNummer.required"
          >Kundennummer erforderlich</span
        >
      </div>
      <div>Passwort</div>
      <div>
        <input
          :placeholder="
            'Passwort mit mindestens ' +
              validationLengths.minPassword +
              ' Zeichen'
          "
          type="password"
          v-bind:class="{
            validationError: !$v.userDTO.passwort.minLength
          }"
          v-model="$v.userDTO.passwort.$model"
        />
        <span class="error-label" v-show="!$v.userDTO.passwort.required"
          >Passwort erforderlich</span
        >
        <span
          class="error-label"
          v-show="
            !$v.userDTO.passwort.maxLength || !$v.userDTO.passwort.minLength
          "
          >Das Passwort muss aus 8 Zeichen bestehen.</span
        >
      </div>
      <div v-if="isRegistration">
        Ich habe die Informationen zum
        <a :href="datenschutzerklaerungPdfLink" class="link" target="_blank"
          >Datenschutz</a
        >
        gelesen und akzeptiert
      </div>
      <div v-if="isRegistration">
        <input type="checkbox" v-model="$v.userDTO.dsgvo.$model" />
      </div>
      <div></div>
      <div class="text-align-right margin-top-20 width800">
        <input
          @click="cancel"
          class="button confirm-button margin-right-20"
          type="button"
          value="Abbrechen"
        />
        <input
          :disabled="!canSave"
          @click="save"
          class="button confirm-button"
          type="button"
          v-bind:class="{
            'button-lightblue': canSave,
            'button-disabled': !canSave
          }"
          value="Speichern"
        />
      </div>
    </div>
    <div v-show="successfullCreated === false">{{ failureMessage }}</div>
  </div>
</template>

<script>
import {
  email,
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import sameAs from "vuelidate/lib/validators/sameAs";
import datenschutzerklaerungLink from "@/assets/Datenschutzerklärung Melzer.pdf";

export default {
  name: "CreateUser",
  data() {
    return {
      userDTO: {
        appKundenID: null,
        identifikationsName: "",
        berechtigungsStufe: 6,
        zuletztVeraendertDurch: "",
        istMelzerUser: false,
        firma: "",
        freigeschaltet: false,
        vorname: "",
        nachname: "",
        passwort: "",
        telefonNummer: "",
        aufgabenbereich: "",
        altesPasswort: "",
        kundenNummer: 0,
        dsgvo: false
      },
      successfullCreated: null,
      failureMessage: "",
      validationLengths: {
        kundeNummer: 5,
        textInputs: 255,
        minPassword: 8
      },
      datenschutzerklaerungPdfLink: datenschutzerklaerungLink
    };
  },
  props: {
    isRegistration: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.userDTO.kundenNummer = this.$route.params.customerNumber;
    this.userDTO.appKundenID = this.$route.params.appUserId;
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    showMelzerTechnicianButton() {
      return (
        this.$store.getters["user/role"] === 10 &&
        this.$route.params.customerNumber === 0
      );
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.userDTO.firma === "Melzer") {
          this.userDTO.istMelzerUser = true;
        }
        // Registrierung als unangemeldeter User
        if (this.$route.name === "registration") {
          const userInfo = await this.$apiCall(
            this.$userInfoService.registerUser(this.userDTO)
          );
          if (userInfo) {
            if (userInfo.data === "BenutzernameExistiertBereits") {
              this.$toast.error("E-Mail existiert bereits!");
            } else if (userInfo.data === "UngueltigeEingabe") {
              this.$toast.error("Bitte prüfen Sie Ihre Eingaben!");
            } else if (userInfo.data === "OK") {
              this.$toast.success(
                "Benutzer erfolgreich erstellt. Sie werden in Kürze freigeschaltet."
              );
              this.$router.go(-1);
            }
          }
        } else if (this.$store.getters["user/role"] === 10) {
          const adminUserInfo = await this.$apiCall(
            this.$administrationService.createUser(this.userDTO)
          );
          if (adminUserInfo) {
            // successfully created
            if (adminUserInfo.data === 0) {
              this.$toast.success("Benutzer erfolgreich erstellt.");
              this.$router.go(-1);
            }
            // internal Null
            else if (adminUserInfo.data === 1) {
              this.successfullCreated = false;
              this.$toast.error("Nur Administratoren dürfen Nutzer anlegen!");
            }
            // not successfull
            else if (adminUserInfo.data === 2) {
              this.successfullCreated = false;
              this.$toast.error("Der Nutzer existiert bereits!");
            }
          }
        } else {
          const techUserInfo = await this.$apiCall(
            this.$technicianInfoService.createUser(this.userDTO)
          );
          if (techUserInfo) {
            // successfully created
            if (techUserInfo.data === 0) {
              this.$toast.success("Benutzer erfolgreich erstellt.");
              this.$router.go(-1);
            }
            // internal Null
            else if (techUserInfo.data === 1) {
              this.successfullCreated = false;
              this.$toast.error("Nur Melzer Benutzer dürfen Nutzer anlegen!");
            }
            // not successfull
            else if (techUserInfo.data === 2) {
              this.successfullCreated = false;
              this.$toast.error("Der Nutzer konnte nicht erstellt werden!");
            }
          }
        }
      }
    },
    changeIsMelzerTechnician() {
      this.userDTO.kundenNummer = null;
    }
  },
  validations() {
    let v = {
      userDTO: {
        nachname: {
          required,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        vorname: {
          required,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        telefonNummer: {
          required,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        kundenNummer: {
          required: requiredIf(function() {
            return this.isRegistration;
          })
        },
        identifikationsName: {
          required,
          email,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        passwort: {
          required,
          minLength: minLength(this.validationLengths.minPassword),
          maxLength: maxLength(this.validationLengths.textInputs)
        }
      }
    };
    if (this.isRegistration) {
      v.userDTO.dsgvo = {
        sameAs: sameAs(() => true)
      };
    }
    return v;
  }
};
</script>

<style scoped></style>
