<template>
  <div id="qr-scan">
    <qrcode-stream @decode="onDecode" id="qr-scan-cam"></qrcode-stream>
    <!-- <div style="height:50px;"> TODO -- Checken ob benötigt oder nicht
                  <input type="text" v-model="deviceNumber" />
                  <input @click="generateQR" type="button" />
                </div> -->
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "ScanQrCode",
  components: {
    QrcodeStream
  },
  data() {
    return {
      deviceNumber: ""
    };
  },
  methods: {
    onDecode(decodedString) {
      if (decodedString) {
        let pos =
          decodedString.indexOf("id=") > -1
            ? decodedString.indexOf("id=")
            : decodedString.indexOf("QR=") > -1
            ? decodedString.indexOf("QR=")
            : -1;
        if (pos > -1) {
          let encryptedDeviceNumber = decodedString.substring(
            pos + 3,
            decodedString.length
          );
          this.$technicianInfoService
            .getDeviceNumberByQR(encryptedDeviceNumber)
            .then(response => {
              this.$router.push({
                name: "deviceInfo",
                params: { nr: response.data }
              });
            })
            .catch(error => {
              this.$handleRestExceptions(error.response.status, this.$router);
            });
        }
      }
    },
    generateQR() {
      this.$technicianInfoService
        .getDecryptedDeviceNumber(this.deviceNumber)
        .then(response => {})
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    }
  }
};
</script>

<style scoped></style>
