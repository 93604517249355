<template>
  <div class="general-wrapper center-grid" id="ResetPassword">
    <div class="flex-column-center" v-if="!passwordResettet">
      <img src="@/assets/img/symbol_melzer_animation.svg" class="flocke" />
      Passwort wird zurueckgesetzt...
    </div>
    <div
      class="flex-column-center"
      v-if="!passwordResettet && bereitsBeantragt"
    >
      <font-awesome-icon icon="ExclamationTriangle" />
      Link ungültig. Dieser Link wurde bereits verwendet!
    </div>
    <div class="flex-column-center" v-if="passwordResettet">
      <h2>Passwort wurde zurückgesetzt!</h2>
      <p>Bitte schauen Sie in Ihren E-Mails nach.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      passwordDTO: { email: "", rueckSetzGeheimnis: "" },
      passwordResettet: false,
      bereitsBeantragt: false
    };
  },
  created() {
    this.passwordDTO.rueckSetzGeheimnis = this.$route.params.secret;
    this.passwordDTO.email = this.$route.params.email;
    if (this.$store.getters["user/token"] === "") {
      this.$loginService
        .login("StandardBenutzer", "123abc")
        .then(response => {
          if (response.data["token"]) {
            this.$store.dispatch("user/setToken", response.data["token"]);
            this.$store.dispatch(
              "user/setRole",
              response.data["berechtigungsStufe"]
            );
            this.$store.dispatch(
              "user/setIsMelzerUser",
              response.data["istMelzerUser"]
            );
          }
          this.savePassword();
        }, this)
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    } else {
      this.savePassword();
    }
  },
  methods: {
    savePassword() {
      this.$userInfoService
        .acceptResetPassword(this.passwordDTO)
        .then(response => {
          if (response.data === true) {
            this.passwordResettet = true;
          }
          if (response.data === false) {
            this.bereitsBeantragt = true;
            this.$toast.error("Dieser Link wurde bereits verwendet!");
          }
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.flocke {
  max-width: 200px;
  max-height: 200px;
}
</style>
