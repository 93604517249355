<template>
  <div id="BackendFilter">
    <div class="flex-row-start">
      <h2 v-if="locationHeading" class="no-margin margin-right-20">
        {{ locationHeading }}
      </h2>
      <input
        class="margin-right-5"
        placeholder="Filter eingeben"
        type="text"
        v-model="newFilter"
        v-on:keyup.enter="addFilter"
        id="backendFilterInput"
      />
      <input
        @click="addFilter"
        id="addFilter"
        type="button"
        value="Hinzufügen"
      />
      <label for="addFilter">
        <font-awesome-icon class="button margin-right-20" icon="plus" />
      </label>
      <div
        class="filter-tag margin-right-20"
        v-bind:key="filter"
        v-for="(filter, index) in paginationDTO.filterBegriffe"
      >
        <span class="margin-right-5">{{ filter }}</span>
        <font-awesome-icon
          @click="deleteFilter(index)"
          class="font-lightblue cursor-pointer"
          icon="times-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackendFilter",
  data() {
    return {
      newFilter: ""
    };
  },
  props: {
    paginationDTO: {
      type: Object,
      default() {
        return {
          seite: 0,
          seitenGroese: 50,
          orderBy: "nutztMIA",
          nutztMIA: null,
          ordneAufsteigend: true,
          filterBegriffe: []
        };
      }
    },
    orderByOptions: {
      type: Array
    },
    locationHeading: {
      type: String
    }
  },
  methods: {
    changePage(page) {
      this.paginationDTO.seite = page - 1;
      this.$emit("update-page");
    },
    addFilter() {
      if (this.newFilter !== "") {
        this.paginationDTO.filterBegriffe.push(this.newFilter);
        this.newFilter = "";
        this.resetPage();
      }
    },
    deleteFilter(index) {
      if (this.paginationDTO.filterBegriffe.length > 0) {
        this.paginationDTO.filterBegriffe.splice(index, 1);
        this.resetPage();
      }
    },
    resetPage() {
      this.paginationDTO.seite = 0;
      this.$emit("update-page");
    }
  }
};
</script>

<style scoped></style>
