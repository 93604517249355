import Vue from "vue";

const dateToString = (date, withTime = true) => {
  if (!date) return "";
  let onlyDate = date.toString().substr(0, date.toString().indexOf("T"));
  let time = date.toString().substr(date.toString().indexOf("T") + 1, 8);
  let dateArray = onlyDate.split("-");
  let timeArray = time.split(":");
  let options = {};
  if (withTime) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
  } else {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
  }
  return new Date(
    dateArray[0],
    dateArray[1] - 1,
    dateArray[2],
    timeArray[0],
    timeArray[1],
    timeArray[2]
  )
    .addHours(2)
    .toLocaleDateString("de-DE", options);
};

Vue.filter("date", date => {
  return dateToString(date);
});

Vue.filter("dateWithoutTime", date => {
  return dateToString(date, false);
});

Vue.filter("aktiv", aktiv => {
  return aktiv ? "aktiv" : "inaktiv";
});

Vue.filter("status", status => {
  return status === null ? "leer" : status === true ? "Aktiv" : "Inaktiv";
});

Vue.filter("role", role => {
  if (!role) {
    return "Techniker";
  }
  return role.toString() === "6" ? "Techniker" : "Administrator";
});

Vue.filter("boolean", value => {
  if (value === true) return "Ja";
  if (value === false && value !== null) return "Nein";
  else return;
});

Vue.filter("maintenanceState", value => {
  switch (value) {
    case "NichtZuSpaet":
      return "Nicht zu Spät";
    case "VierWochenZuSpaet":
      return "Vier Wochen zu spät";
    case "ZwoelfWochenZuSpaet":
      return "Zwölf Wochen zu spät";
    default:
      return "";
  }
});
