export const BASE_URL = process.env.VUE_APP_API_URL;

export const BEARER = "Bearer ";

const agentOptions = {
  pfx: certificate,
  ca: testCert,
  passphrase: "0BMnJRQ67N31VHJB",
  rejectUnauthorized: false
};

const https = require("https");
export const AGENT = new https.Agent(agentOptions);
