<template>
  <div id="administration">
    <BackendFilter
      :orderByOptions="orderByOptions"
      :paginationDTO="customerPaginationDTO"
      @update-page="getCustomers"
      location-heading="Kunden"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="customers"
      @change-order-by-click="changeOrderByClick"
      datatype="ADMINISTRATION"
    />
    <Pagination
      :paginationDTO="customerPaginationDTO"
      @update-page="getCustomers"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import Pagination from "../../helper/table/Pagination";
import BackendFilter from "../../helper/table/BackendFilter";

export default {
  name: "Administration",
  components: {
    BackendFilter,
    Pagination,
    TableNew
  },
  data() {
    return {
      customers: [],
      orderByOptions: [
        { value: "Nummer", id: "kundenNummer" },
        { value: "Firma", id: "KdName1" },
        { value: "Aktiv", id: "nutztMIA" },
        { value: "Störungsmeldungspostfach", id: "stoerungsMeldungsEMailFach" }
      ],
      customerPaginationDTO: {
        seite: 0,
        seitenGroese: 50,
        orderBy: "nutztMIA",
        nutztMIA: null,
        ordneAufsteigend: false,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      this.customers.splice(0, this.customers.length);
      const pagination = await this.$apiCall(
        this.$technicianInfoService.getCustomersPagination(
          this.customerPaginationDTO
        )
      );
      if (pagination) {
        this.customers = pagination.data;
        if (pagination.data.length === 0) this.emptyResponse = true;
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.customerPaginationDTO.orderBy = orderBy;
      this.customerPaginationDTO.ordneAufsteigend = ascending;
      this.getCustomers();
    }
  }
};
</script>

<style scoped></style>
