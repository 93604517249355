<template>
  <div class="img-wrap" id="imageWrapper">
    <div
      @click="deletePicture"
      @mouseout="isHovering = false"
      @mouseover="isHovering = true"
      class="button square-button close"
      v-show="isHovering"
    >
      <font-awesome-icon icon="times" />
    </div>
    <img
      :src="src"
      @mouseout="isHovering = false"
      @mouseover="isHovering = true"
      class="img"
    />
    <span class="error-label" v-show="!isValidImage"
      >Keine gültige Bilddatei</span
    >
  </div>
</template>

<script>
export default {
  name: "ImageWrapper",
  data() {
    return {
      isHovering: false
    };
  },
  props: {
    index: {
      type: Number
    },
    src: {
      type: String
    },
    isValidImage: {
      type: Boolean
    }
  },
  methods: {
    deletePicture() {
      this.$emit("delete-picture", this.index);
    }
  }
};
</script>

<style scoped></style>
