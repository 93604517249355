<template>
  <div id="app">
    <div v-if="callPending" id="loading-spinner" class="loading-spinner">
      <img src="@/assets/img/symbol_melzer_animation.svg" />
      <h2>Bitte warten</h2>
    </div>
    <div v-if="isDevelopment">
      <font-awesome-icon
        class="admin-bar-trigger"
        icon="cog"
        v-on:click="isThere = !isThere"
      />
      <div id="admin-nav" v-show="isThere">
        <router-link to="/">Home</router-link>
        |
        <router-link to="/QR=LlywEjJTrkGNwM-_JOUaOQ">
          Störungsmeldung Senden
        </router-link>
        |
        <router-link to="/login">Login</router-link>
        |
        <span @click="loginHans">Login Hans</span>
        |
        <span @click="loginAdmin">Login ADMIN</span>
        |
        <span @click="loginAnna">Login Anna</span>
        |
        <span @click="loginTechniker">Login Techniker</span>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isThere: false
    };
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === "development";
    },
    callPending() {
      return this.$store.state.misc.callPending;
    }
  },
  methods: {
    loginHans() {
      this.login("hans@mittelRheinMetal.de", "hans@mittelRheinMetal.de");
    },
    loginAdmin() {
      this.login("otto.memmel@quattec.de", "5eff775eff77");
    },
    loginAnna() {
      this.login("anna.apotheke@apotheke.de", "anna.apotheke@apotheke.de");
    },
    loginAnnaTechniker() {
      this.login("anna.techniker@apotheke.de", "anna.techniker@apotheke.de");
    },
    loginTechniker() {
      this.login("techniker@melzer.de", "techniker@melzer.de");
    },
    login(email, pw) {
      this.$loginService
        .login(email, pw)
        .then(response => {
          if (response.data["token"]) {
            this.$store.dispatch("user/setToken", response.data["token"]);
            this.$store.dispatch(
              "user/setRole",
              response.data["berechtigungsStufe"]
            );
            this.$store.dispatch(
              "user/setIsMelzerUser",
              response.data["istMelzerUser"]
            );
            this.$store.dispatch(
              "user/setAppUserId",
              response.data["appKundenID"]
            );
            this.$store.dispatch(
              "user/setCustomerNumber",
              response.data["kundenNummer"]
            );
            this.$store.dispatch("user/setUserName", this.email);
            this.$router.push({ name: "dashboard" });
          }
        }, this)
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    }
  }
};
</script>

<style lang="scss">
@import "./assets/stylesheets/main.scss";

.loading-spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottomn: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  animation: blend 0.5s ease;

  img {
    width: 9rem;
    height: 9rem;
    color: $darkblue;
  }

  @keyframes blend {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
