<template>
  <div class="main-content table-has-filter" id="myPlants">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      location-heading="Anlagen"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="plants"
      @change-order-by-click="changeOrderByClick"
      datatype="PLANT"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import TechnicianInfo from "@/services/technicianInfoService.js";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

const technicianInfo = new TechnicianInfo();

export default {
  name: "MyPlants",
  data() {
    return {
      plants: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  components: { FrontendFilter, TableNew },
  created() {
    this.getMyPlantsShortInfo();
  },
  methods: {
    async getMyPlantsShortInfo() {
      const plant = await this.$apiCall(
        technicianInfo.getMyPlantsShortInfoContract(this.$route.params.nr)
      );
      if (plant) {
        this.plants = plant.data;
        this.tableDataBackup = plant.data;
        if (plant.data.length === 0) this.emptyResponse = true;
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.plants = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
    }
  }
};
</script>
