<template>
  <div class="general-wrapper center-grid" id="registration">
    <div class="flex-column-center">
      <h2>Registrierung</h2>
      <h3>
        Sie sind dabei sich für die Verwendung der MelzerApp zu registrieren.
      </h3>
      <CreateUser :isRegistration="true" />
    </div>
  </div>
</template>

<script>
import CreateUser from "../components/helper/CreateUser";

export default {
  name: "Registration",
  components: { CreateUser },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("user/clear");
    this.loginService();
  },
  methods: {
    async loginService() {
      const response = await this.$apiCall(
        this.$loginService.login("StandardBenutzer", "123abc")
      );
      if (response) {
        if (response.data["token"]) {
          await this.$store.dispatch("user/setToken", response.data["token"]);
          await this.$store.dispatch(
            "user/setRole",
            response.data["berechtigungsStufe"]
          );
          await this.$store.dispatch(
            "user/setIsMelzerUser",
            response.data["istMelzerUser"]
          );
          // this.getshortInfo();
        }
      }
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  validations: {}
};
</script>

<style scoped></style>
