<template>
  <div class="content-background" id="technicianConfiguration">
    <h2>
      E-Mail und Anzeigeoptionen sind nur durch die Melzer Administration
      veränderbar. Logo ist anpassbar.
    </h2>
    <div class="basic-data-grid">
      <h4 class="opacity-half">Störungsmeldungen</h4>
      <div />
      <div>E-Mail:</div>
      <div>
        {{ configurationDTO.stoerungsMeldungsEMailFach }}
      </div>
      <h4 class="margin-top-20">Kurzinformationen</h4>
      <div />
      <div>Logo</div>

      <div class="picture-gallery margin-bottom-10" v-if="showNewLogo">
        <ImageWrapper
          :index="0"
          :isValidImage="isValidLogoType"
          :src="customerLogoDTO.base64Data"
          @delete-picture="deletePicture"
        />
      </div>
      <div v-if="showNewLogo"></div>

      <!--<img
                                                                    :src="customerLogoDTO.base64Data"
                                                                    class="preview-image"
                                                                    v-if="customerLogoDTO.base64Data !== ''"
                                                                  />
                                                                  <div v-if="customerLogoDTO.base64Data !== ''"></div>-->
      <div class="flex-row-start">
        <input
          @change="chooseNewLogo"
          accept="image/*"
          class="button small-button margin-right-20 display-none"
          id="hochladen-button"
          type="file"
          value="Durchsuchen"
        /><label for="hochladen-button">
          <div class="button small-button margin-right-20">
            <span>Durchsuchen</span>
          </div>
        </label>
        <input
          :disabled="!canSetNewLogo"
          @click="setNewLogo"
          class="button small-button"
          type="button"
          v-bind:class="{
            'button-lightblue': canSetNewLogo,
            'button-disabled': !canSetNewLogo
          }"
          value="Hochladen"
        />
      </div>
      <div class="opacity-half margin-top-20">
        Anzeigeoptionen
      </div>
      <div class="checkbox-grid opacity-half margin-top-20">
        <label>zeige Baujahr</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeBaujahr"
        />
        <label>zeige Fabrikat</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeFabrikat"
        />
        <label>zeige Gerätenummer</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeNummer"
        />
        <label>zeige Gerätebeschreibung</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeInfo"
        />
        <label>zeige Anlage</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeAgNr"
        />
        <label>zeige Gerätetyp</label>
        <input
          class="checkbox-switch"
          disabled="disabled"
          type="checkbox"
          v-model="configurationDTO.zeigeTyp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { imageToBase64 } from "../../../services/helpServices";
import ImageWrapper from "../../helper/ImageWrapper";

export default {
  name: "TechnicianConfiguration",
  components: {
    ImageWrapper
  },
  data() {
    return {
      configurationDTO: {
        stoerungsMeldungsEMailFach: ""
      },
      customerLogoDTO: {
        name: "",
        type: "",
        base64Data: "",
        kundenNummer: 0
      }
    };
  },
  created() {
    this.getConfiguration();
  },
  computed: {
    canSetNewLogo() {
      return !!this.customerLogoDTO.base64Data && this.isValidLogoType;
    },
    showNewLogo() {
      return this.customerLogoDTO.base64Data !== "";
    },
    isValidLogoType() {
      return this.customerLogoDTO.type.indexOf("image/") >= 0;
    }
  },
  methods: {
    async getConfiguration() {
      const customerConfig = await this.$apiCall(
        this.$technicianInfoService.getCustomerConfiguration(
          this.$store.getters["user/customerNumber"]
        )
      );
      if (customerConfig) {
        this.configurationDTO = customerConfig.data;
      }
    },
    async chooseNewLogo(event) {
      let file = event.target.files[0];
      this.customerLogoDTO.base64Data = await imageToBase64(file);
      this.customerLogoDTO.name = file.name;
      this.customerLogoDTO.type = file.type;
      this.customerLogoDTO.kundenNummer = this.$store.getters[
        "user/customerNumber"
      ];
    },
    async setNewLogo() {
      const _response = await this.$apiCall(
        this.$technicianInfoService.saveNewLogoForCustomer(this.customerLogoDTO)
      );
      if (_response) {
        this.customerLogoDTO.base64Data = "";
        this.$toast.success("Logo erfolgreich geändert!");
        this.$root.$emit("changed-logo");
      }
    },
    deletePicture(index) {
      this.customerLogoDTO.name = "";
      this.customerLogoDTO.type = "";
      this.customerLogoDTO.base64Data = "";
      this.customerLogoDTO.kundenNummer = 0;
    }
  }
};
</script>

<style scoped></style>
