<template>
  <div class="table-details-has-filter" id="user">
    <FrontendFilter
      class="margin-top-20"
      @update-filter="updateFilter"
      :filters="paginationDTO.filterBegriffe"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="kaeltemittelPage"
      datatype="LOGBUCHKAELTEMITTEL"
      v-if="restDataLoaded"
      @change-order-by-click="changeOrderByClick"
      :summen-zeile="true"
      :summen-inhalt="kaeltemittelSummen"
    />
    <div class="pagination">
      <paginate
        :page-count="pages"
        :page-range="3"
        v-model="paginationDTO.aktuelleSeite"
        :click-handler="paginateTableDataBackup"
        :break-view-class="'break'"
        :break-view-text="'|'"
        :container-class="'pagination-container'"
        :disabled-class="'inactive'"
        :margin-pages="1"
        :next-text="'>>'"
        :page-class="'pagination-page'"
        :prev-text="'<<'"
      />
    </div>
  </div>
</template>

<script>
import TableNew from "../../helper/table/generalTable";
import { changeOrderOfTable } from "../../../services/helpServices";
import FrontendFilter from "../../helper/table/FrontendFilter";

export default {
  name: "kaeltemittel",
  data() {
    return {
      restDataLoaded: false,
      emptyResponse: false,
      paginationDTO: {
        orderBy: "Geraetenummer",
        ordneAufsteigend: false,
        filterBegriffe: [],
        aktuelleSeite: 1
      },
      kaeltemittel: [],
      tableDataBackup: [],
      kaeltemittelPage: [],
      kaeltemittelSummen: {}
    };
  },
  components: { FrontendFilter, TableNew },
  props: {
    nr: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    pages() {
      return Math.ceil((this.kaeltemittel.length - 1) / 20);
    }
  },
  created() {
    this.getEingesetzteKaeltemittelVertrag();
  },
  methods: {
    async getEingesetzteKaeltemittelVertrag() {
      const kaeltemittel = await this.$apiCall(
        this.$technicianInfoService.getEingesetzteKaeltemittelVertrag(this.nr)
      );
      if (kaeltemittel) {
        this.kaeltemittel = kaeltemittel.data.eingesetzteKaeltemittel;
        this.kaeltemittelSummen = kaeltemittel.data.kaelteSummen;
        this.tableDataBackup = kaeltemittel.data.eingesetzteKaeltemittel;
        this.paginateTableDataBackup(this.paginationDTO.aktuelleSeite);
        this.restDataLoaded = true;
        if (kaeltemittel.data.length === 0) this.emptyResponse = true;
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.kaeltemittel = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
      this.paginationDTO.aktuelleSeite = 1;
      this.paginateTableDataBackup();
    },
    paginateTableDataBackup() {
      let page = this.paginationDTO.aktuelleSeite - 1;
      let start = page * 20;
      let end = start + 20;
      let eintraege = this.kaeltemittel.slice(start, end);
      this.kaeltemittelPage = eintraege;
    }
  }
};
</script>

<style scoped></style>
