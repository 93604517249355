import axios from "axios";
import { AGENT, BASE_URL } from "./configuration";

export default class TestService {
  login(email, password) {
    return axios.post(
      BASE_URL + "authentifizierung/erzeugeToken",
      {
        username: email,
        password: password
      },
      {
        headers: { "Content-Type": "application/json" }
      },
      AGENT
    );
  }
}
