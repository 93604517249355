<template>
  <div id="createQrCode">
    <input type="text" v-model="textToEncrypt" />
    <input @click="encrypt" type="button" value="verschluesseln" />
    {{ encryptedText }}
  </div>
</template>

<script>
export default {
  name: "CreateQrCode",
  data() {
    return {
      textToEncrypt: "",
      encryptedText: ""
    };
  },
  methods: {
    async encrypt() {
      const device = await this.$apiCall(
        this.$technicianInfoService.getEncryptedDeviceNumber(this.textToEncrypt)
      );
      if (device) {
        this.encryptedText = device.data;
      }
    }
  }
};
</script>

<style scoped></style>
