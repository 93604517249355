var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideSearchResults),expression:"hideSearchResults"}],ref:"searchWrap",staticClass:"search-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],ref:"search",attrs:{"placeholder":"Kunden, Anlagen oder Geräte suchen","type":"text"},domProps:{"value":(_vm.searchString)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchString=$event.target.value}}}),_c('input',{attrs:{"id":"search-submit","type":"submit"},on:{"click":_vm.search}}),_c('label',{attrs:{"for":"search-submit"}},[_c('font-awesome-icon',{class:_vm.callPending ? 'fa-spin' : '',attrs:{"icon":_vm.callPending ? 'snowflake' : 'search'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchString !== ''),expression:"searchString !== ''"}],attrs:{"id":"search-results"}},[(!_vm.showDialog)?_c('ul',[_c('li',[_c('span',[_vm._v(_vm._s(_vm.searchPlaceholder))])])]):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}]},[(
          _vm.searchDTO.anzahlGefundenerAppKunden === 0 &&
            _vm.searchDTO.anzahlGefundenerAnlagen === 0 &&
            _vm.searchDTO.anzahlGefundenerGeraete === 0
        )?_c('li',[_c('span',[_vm._v("Keine Ergebnisse gefunden!")])]):_vm._e(),_c('li',[(_vm.searchDTO.anzahlGefundenerAppKunden > 0)?_c('ul',[_vm._m(0),_vm._l((_vm.searchDTO.appKunden),function(kunde){return _c('li',{key:kunde.appKundenID,staticClass:"search-result"},[_c('router-link',{attrs:{"to":{
                name: 'contractsForCustomer',
                params: _vm.getCustomerParams(kunde)
              }},on:{"click":_vm.hideSearchResults}},[_vm._v(" "+_vm._s(kunde.kdNameTabellenAnzeige)+" ")])],1)}),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'search',
                params: {
                  type: _vm.SEARCH_CUSTOMER,
                  searchString: _vm.searchString
                }
              }}},[(_vm.searchDTO.anzahlGefundenerAppKunden > 3)?_c('span',[_vm._v("Es wurden insgesamt "+_vm._s(_vm.searchDTO.anzahlGefundenerAppKunden - 3)+" weitere Kunden gefunden")]):_vm._e()])],1)],2):_vm._e()]),_c('li',[(_vm.searchDTO.anzahlGefundenerAnlagen > 0)?_c('ul',{staticClass:"margin-top-20"},[_vm._m(1),_vm._l((_vm.searchDTO.anlagen),function(anlage){return _c('li',{key:anlage.nr,staticClass:"search-result"},[_c('router-link',{attrs:{"to":{ name: 'maintenances', params: { nr: anlage.nr } }}},[_vm._v(" Anlage mit der Nummer: "+_vm._s(anlage.nr)+" ")])],1)}),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'search',
                params: {
                  type: _vm.SEARCH_PLANTS,
                  searchString: _vm.searchString
                }
              }}},[(_vm.searchDTO.anzahlGefundenerAnlagen > 3)?_c('span',[_vm._v("Es wurden insgesamt "+_vm._s(_vm.searchDTO.anzahlGefundenerAnlagen - 3)+" weitere Anlagen gefunden")]):_vm._e()])],1)],2):_vm._e()]),_c('li',[(_vm.searchDTO.anzahlGefundenerGeraete > 0)?_c('ul',{staticClass:"margin-top-20"},[_vm._m(2),_vm._l((_vm.searchDTO.geraete),function(geraet){return _c('li',{key:geraet.nr,staticClass:"search-result"},[_c('router-link',{attrs:{"to":{ name: 'deviceInfo', params: { nr: geraet.nummer } }}},[_c('span',[_vm._v("Gerät mit der Nummer: "+_vm._s(geraet.nummer))])])],1)}),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'search',
                params: {
                  type: _vm.SEARCH_DEVICES,
                  searchString: _vm.searchString
                }
              }}},[(_vm.searchDTO.anzahlGefundenerGeraete > 3)?_c('span',[_vm._v("Es wurden insgesamt "+_vm._s(_vm.searchDTO.anzahlGefundenerGeraete - 3)+" weitere Geräte gefunden")]):_vm._e()])],1)],2):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Kunden")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Anlagen")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Geräte")])])}]

export { render, staticRenderFns }