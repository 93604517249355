let state = {
  token: "",
  role: 4,
  isMelzerUser: false,
  customerId: null,
  customerNumber: null,
  appUserId: null,
  userName: ""
};

const getters = {
  token: state => state.token,
  role: state => state.role,
  isMelzerUser: state => state.isMelzerUser,
  customerId: state => state.customerId,
  customerNumber: state => state.customerNumber,
  appUserId: state => state.appUserId,
  userName: state => state.userName
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setRole(state, role) {
    state.role = role;
  },
  setIsMelzerUser(state, isMelzerUser) {
    state.isMelzerUser = isMelzerUser;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setCustomerNumber(state, customerNumber) {
    state.customerNumber = customerNumber;
  },
  setAppUserId(state, appUserId) {
    state.appUserId = appUserId;
  },
  setUserName(state, userName) {
    state.userName = userName;
  },
  clear(state) {
    state.token = "";
    state.role = 0;
    state.isMelzerUser = false;
    state.customerId = null;
    state.customerNumber = null;
    state.appUserId = null;
    state.appUserId = null;
  }
};

const actions = {
  setToken({ commit }, token) {
    commit("setToken", token);
  },
  setRole({ commit }, role) {
    commit("setRole", role);
  },
  setIsMelzerUser({ commit }, isMelzerUser) {
    commit("setIsMelzerUser", isMelzerUser);
  },
  setCustomerId({ commit }, customerId) {
    commit("setCustomerId", customerId);
  },
  setCustomerNumber({ commit }, customerNumber) {
    commit("setCustomerNumber", customerNumber);
  },
  setAppUserId({ commit }, appUserId) {
    commit("setAppUserId", appUserId);
  },
  setUserName({ commit }, userName) {
    commit("setUserName", userName);
  },
  clear({ commit }) {
    commit("clear");
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
