<template>
  <div class="table-details-has-filter" id="Maintenances">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      class="margin-top-20"
    />
    <TableNew
      :emptyResponse="emptyMaintenancesResponse"
      :tableData="tableData"
      @change-order-by-click="changeOrderByClick"
      datatype="MAINTENANCE"
    />
  </div>
</template>

<script>
import TableNew from "../../helper/table/generalTable";
import { changeOrderOfTable } from "../../../services/helpServices";
import FrontendFilter from "../../helper/table/FrontendFilter";

export default {
  name: "Maintenances",
  components: { FrontendFilter, TableNew },
  data() {
    return {
      tableData: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      }
    };
  },
  props: {
    maintenances: {
      type: Array
    },
    emptyMaintenancesResponse: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.tableData = this.maintenances;
  },
  methods: {
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.tableData = changeOrderOfTable(
        this.maintenances,
        this.paginationDTO
      );
    }
  }
};
</script>

<style scoped></style>
