<template>
  <div class="content-background table-has-swipe-bar overflow-y-scroll">
    <ul>
      <li v-for="(value, key) in device" v-bind:key="key">
        <span
          v-if="render(value, key)"
          style="display: inline-block; width: 200px"
          >{{ $filterMeaningfulNames(key) }}:</span
        >
        <span v-if="render(value, key)">{{ $filterValueKey(value, key) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "deviceInfo",
  data() {
    return {
      hiddenProperties: [
        "id",
        "geraeteStoerungsStatus",
        "messungJahresZahlen",
        "gszeichen",
        "laufendeNummer",
        "ursprungsTabelle",
        "offeneStoerungen",
        "agId",
        "vtId",
        "hatLogbuch"
      ]
    };
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    render(value, key) {
      return !!value && !this.hiddenProperties.some(v => v.includes(key));

      // bessere Variante die alles rausfiltert, auch leere Listen, muss erst abgesprochen werden
      /*return (
                                    (!Array.isArray(value) && !!value) ||
                                    (!!value &&
                                      Array.isArray(value) &&
                                      value.length &&
                                      key !== "offeneStoerungen")
                                  );*/
    }
  }
};
</script>
