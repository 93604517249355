<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "qr-code",
  created() {
    // console.log("QR", this.$route);
  }
};
</script>
