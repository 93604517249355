<template>
  <div id="Search">
    s
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
    />
    <TableNew
      :datatype="tableType"
      :emptyResponse="emptyResponse"
      :tableData="tableData"
      @change-order-by-click="changeOrderByClick"
    ></TableNew>
  </div>
</template>

<script>
import TableNew from "./table/generalTable";
import { changeOrderOfTable } from "../../services/helpServices";
import FrontendFilter from "./table/FrontendFilter";

export default {
  name: "Search",
  components: { FrontendFilter, TableNew },
  data() {
    return {
      tableType: "",
      SEARCH_TYPES: Object.freeze({
        SEARCH_CUSTOMER: "SEARCH_CUSTOMER",
        SEARCH_PLANTS: "SEARCH_PLANTS",
        SEARCH_DEVICES: "SEARCH_DEVICES"
      }),
      tableData: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  async created() {
    let type = this.$route.params.type;
    let searchString = this.$route.params.searchString;
    if (type && searchString) {
      // TODO: pruefen ob fuer die Suche andere Spalten angezeigt werden sollen als fuer die normalen Anzeigen
      // this.tableType = type;
      if (type === this.SEARCH_TYPES.SEARCH_CUSTOMER) {
        this.tableType = "CUSTOMER";
        this.tableData = await this.getCustomers(searchString);
      } else if (type === this.SEARCH_TYPES.SEARCH_PLANTS) {
        this.tableType = "PLANT";
        this.tableData = await this.getPlants(searchString);
      } else if (type === this.SEARCH_TYPES.SEARCH_DEVICES) {
        this.tableType = "DEVICES";
        this.tableData = await this.getDevices(searchString);
      }
      this.tableDataBackup = this.tableData;
      if (this.tableData.length === 0) this.emptyResponse = true;
    }
  },
  methods: {
    async getCustomers(searchString) {
      return this.$technicianInfoService
        .getCustomersForSearch(searchString)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    async getPlants(searchString) {
      return this.$technicianInfoService
        .getPlantsForSearch(searchString)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    async getDevices(searchString) {
      return this.$technicianInfoService
        .getDevicesForSearch(searchString)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.tableData = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
    }
  }
};
</script>

<style scoped></style>
