<template>
  <div class="table-details-has-filter" id="user" v-if="device.hatLogbuch">
    <FrontendFilter
      class="margin-top-20"
      @update-filter="updateFilter"
      :filters="paginationDTO.filterBegriffe"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="logbuchEintraegePage"
      datatype="DEVICELOGBUCH"
      v-if="restDataLoaded"
      @change-order-by-click="changeOrderByClick"
      :function-column="true"
      ><template v-slot:function="slotProps">
        <div class="eintrag-bearbeiten">
          <router-link
            :to="{
              name: 'logbuchEintragbearbeiten',
              params: {
                logbuchDetails: logbuchEintraege[slotProps.index],
                secondparam: logbuchEintraege[slotProps.index].id
              }
            }"
          >
            <input
              type="button"
              :class="
                logbuchEintraege[slotProps.index].id
                  ? 'button button-grau second-lvl'
                  : 'device-logbuch-messung'
              "
              :value="
                !logbuchEintraege[slotProps.index].id ? 'Messung' : 'Bearbeiten'
              "
              :disabled="!logbuchEintraege[slotProps.index].id"
            />
          </router-link>
        </div>
      </template>
    </TableNew>
    <div class="pagination">
      <div class="button-container">
        <input
          v-if="isMelzer && device.hatLogbuch"
          @click="createLogbucheintrag()"
          class="button margin-right-20"
          type="button"
          value="Logbuch-Eintrag erfassen"
        />
        <input
          v-if="logbuchEintraege.length > 0 && device.hatLogbuch"
          @click="createPdf()"
          class="button"
          type="button"
          value="PDF erzeugen"
        />
      </div>
      <paginate
        v-if="device.hatLogbuch"
        :page-count="pages"
        :page-range="3"
        v-model="paginationDTO.aktuelleSeite"
        :click-handler="paginateTableDataBackup"
        :break-view-class="'break'"
        :break-view-text="'|'"
        :container-class="'pagination-container'"
        :disabled-class="'inactive'"
        :margin-pages="1"
        :next-text="'>>'"
        :page-class="'pagination-page'"
        :prev-text="'<<'"
      />
    </div>
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

export default {
  name: "DeviceLogbuch",
  components: {
    FrontendFilter,
    TableNew
  },
  data() {
    return {
      restDataLoaded: false,
      logbuchEintraege: [],
      emptyResponse: false,
      paginationDTO: {
        orderBy: "datum",
        ordneAufsteigend: false,
        filterBegriffe: [],
        aktuelleSeite: 1
      },
      tableDataBackup: [],
      logbuchEintraegePage: []
    };
  },
  props: {
    geraeteNummer: {
      type: String,
      default: ""
    },
    device: {
      type: Object,
      default: null
    },
    dataLoaded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pages() {
      return Math.ceil((this.logbuchEintraege.length - 1) / 20);
    },
    isMelzer() {
      return this.$store.getters["user/isMelzerUser"];
    }
  },
  created() {
    if (this.dataLoaded) {
      if (this.device.hatLogbuch) {
        this.getLogbuchMessungen();
      } else {
        this.$toast.error("Keine Logbücher für diesen Gerätetyp");
      }
    }
  },
  watch: {
    dataLoaded() {
      if (this.device.hatLogbuch) {
        this.getLogbuchMessungen();
      } else {
        this.$toast.error("Keine Logbücher für diesen Gerätetyp");
      }
    }
  },
  methods: {
    async getLogbuchMessungen() {
      const logbuchMessung = await this.$apiCall(
        this.$technicianInfoService.getLogbuchUndMessungen(this.geraeteNummer)
      );
      if (logbuchMessung) {
        this.logbuchEintraege = logbuchMessung.data;
        this.tableDataBackup = logbuchMessung.data;
        this.paginateTableDataBackup(this.paginationDTO.aktuelleSeite);
        this.restDataLoaded = true;
        if (logbuchMessung.data.length === 0) this.emptyResponse = true;
      }
    },
    createLogbucheintrag() {
      this.$router.push({
        name: "neuerlogbuchEintrag",
        params: {
          geraeteNummer: this.geraeteNummer
        }
      });
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.logbuchEintraege = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
      this.paginationDTO.aktuelleSeite = 1;
      this.paginateTableDataBackup();
    },
    paginateTableDataBackup() {
      let page = this.paginationDTO.aktuelleSeite - 1;
      let start = page * 20;
      let end = start + 20;
      let eintraege = this.logbuchEintraege.slice(start, end);
      this.logbuchEintraegePage = eintraege;
    },
    async createPdf() {
      const response = await this.$apiCall(
        this.$technicianInfoService.createLogbuchPdf(this.geraeteNummer)
      );
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename =
          "Anlagen-Logbuch_" + this.geraeteNummer.toString() + ".pdf";
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }
  }
};
</script>

<style scoped></style>
