export async function imageToBase64(image) {
  return await fileToBase64(image)
    .then(res => {
      return res;
    }, this)
    .catch(error => {
      return "";
    });
}

export async function pdfToBase64(image) {
  return await fileToBase64(image)
    .then(res => {
      return res;
    }, this)
    .catch(error => {
      return "";
    });
}

export function formatDateBackend(date) {
  // "yyyy-MM-dd"
  const d = date.getDate();
  const m = date.getMonth() + 1; //Month from 0 to 11
  const y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader(),
      readerBase64 = new FileReader(),
      blob = file.slice(0, 4);
    reader.readAsArrayBuffer(blob);
    reader.onloadend = e => {
      readerBase64.readAsDataURL(file);
    };
    readerBase64.onloadend = () => {
      let res = readerBase64.result;
      resolve(res);
    };
    reader.onerror = error => reject(error);
    readerBase64.onerror = error => reject(error);
  });
}

function resizeImage(file) {
  // Ensure it's an image
  if (file.type.match(/image.*!/)) {
    // Load the image
    let reader = new FileReader();
    reader.onload = function(readerEvent) {
      let image = new Image();
      image.onload = function(imageEvent) {
        // Resize the image
        let canvas = document.createElement("canvas"),
          max_size = 544, // TODO : pull max size from a site config
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        let dataUrl = canvas.toDataURL("image/jpeg");
        let resizedImage = dataURLToBlob(dataUrl);
        $.event.trigger({
          type: "imageResized",
          blob: resizedImage,
          url: dataUrl
        });
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  }
}

var dataURLToBlob = function(dataURL) {
  let BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    let parts = dataURL.split(",");
    let contentType = parts[0].split(":")[1];
    let raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export function changeOrderOfTable(
  array = [],
  paginationDTO = { orderBy: "", ordneAufsteigend: true, filterBegriffe: [] }
) {
  let newTableData = array
    .filter(data => {
      let existingCounter = 0;
      paginationDTO.filterBegriffe.forEach(filter => {
        if (
          Object.values(data).find(val => {
            return (
              val &&
              typeof val === "string" &&
              val !== "" &&
              val.toUpperCase().includes(filter.toUpperCase())
            );
          })
        )
          existingCounter++;
      });
      return existingCounter >= paginationDTO.filterBegriffe.length;
    })
    .sort((a, b) => {
      if (!a[paginationDTO.orderBy] || !b[paginationDTO.orderBy]) {
        return -1;
      }
      let nameA = a[paginationDTO.orderBy].toString().toUpperCase(),
        nameB = b[paginationDTO.orderBy].toString().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // equals
      return 0;
    });
  if (!paginationDTO.ordneAufsteigend) {
    newTableData.reverse();
  }
  return newTableData;
}

export function createUserDisplayName(lastname, firstname) {
  let displayName = !!lastname ? lastname : "";
  if (!!firstname) {
    displayName += !!displayName ? ", " + firstname : firstname;
  }
  return displayName;
}
