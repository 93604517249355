<template>
  <div class="content-background" id="ProfileMain">
    <div>
      <h4>Persönliche Daten</h4>
      <div class="basic-data-grid margin-top-20">
        <div>Nachname:</div>
        <div>{{ userDTO.nachname }}</div>
        <div>Vorname:</div>
        <div>{{ userDTO.vorname }}</div>
        <div>Telefon:</div>
        <div>{{ userDTO.telefonNummer }}</div>
        <div>E-Mail:</div>
        <div>{{ userDTO.identifikationsName }}</div>
      </div>
      <h4 class="margin-top-20">Firma</h4>
      <div class="basic-data-grid margin-top-20">
        <div>Firmenname:</div>
        <div>{{ userDTO.firma }}</div>
        <div>Aufgabenbereich:</div>
        <div>{{ userDTO.aufgabenbereich }}</div>
      </div>
    </div>
    <div @click="editProfile" class="button square-button dark-blue top-corner">
      <font-awesome-icon icon="pen" />
    </div>
    <div
      @click="changePassword"
      class="button square-button dark-blue top-corner margin-top-60"
    >
      <font-awesome-icon icon="key" />
    </div>
    <div
      @click="deleteSelf"
      class="button square-button dark-blue top-corner margin-top-120"
    >
      <font-awesome-icon icon="trash" />
    </div>
  </div>
</template>

<script>
import { ResponseEnum } from "../../../enums/responseEnum";

export default {
  name: "ProfileMain",
  data() {
    return {
      userDTO: {}
    };
  },
  created() {
    this.getMyUser();
  },
  methods: {
    async getMyUser() {
      const user = await this.$apiCall(this.$technicianInfoService.getMyUser());
      if (user) {
        this.userDTO = user.data;
      }
    },
    editProfile() {
      this.$router.push({ name: "editProfile" });
    },
    changePassword() {
      this.$router.push({ name: "changePassword" });
    },
    async deleteSelf() {
      let pw = prompt(
        "Geben Sie bitte Ihr Passwort ein wenn Sie wirklich Ihren Benutzer löschen möchten.",
        ""
      );
      if (pw && pw !== "") {
        this.userDTO.altesPasswort = pw;
        this.userDTO.passwort = pw;
        const response = await this.$apiCall(
          this.$technicianInfoService.deleteMyUser(this.userDTO)
        );
        if (response) {
          if (response.data === ResponseEnum.PasswortFalsch) {
            this.$toast.error("Falsches Passwort eingegeben.");
          } else if (response.data === ResponseEnum.OK) {
            this.$toast.success("Benutzer erfolgreich gelöscht.");
            this.$router.push({ name: "login" });
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
