<template>
  <div id="melzerCustomers" class="main-content table-has-filter-pagination">
    <BackendFilter
      :orderByOptions="orderByOptions"
      :paginationDTO="customerPaginationDTO"
      :sortBy="sortBy"
      location-heading="Kunden"
      @update-page="getCustomers(true)"
    />
    <TableNew
      :key="rerenderKey"
      :emptyResponse="emptyResponse"
      :tableData="customers"
      :datatype="dataTypeComp"
      @change-order-by-click="changeOrderByClick"
    />
    <Pagination
      :paginationDTO="customerPaginationDTO"
      @update-page="getCustomers(true)"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import TechnicianInfo from "@/services/technicianInfoService.js";
import Pagination from "../../helper/table/Pagination";
import BackendFilter from "../../helper/table/BackendFilter";

const technicianInfo = new TechnicianInfo();

export default {
  name: "Customers",
  data() {
    return {
      customers: [],
      orderByOptions: [
        { value: "Nummer", id: "kundenNummer" },
        { value: "Kunde", id: "KdMatch" },
        { value: "Name", id: "KdNameTabellenAnzeige" },
        { value: "Aktiv", id: "nutztMIA" },
        { value: "Standort", id: "KdAnschriftOrt" }
      ],
      customerPaginationDTO: {
        seite: 0,
        seitenGroese: 50,
        orderBy: "",
        nutztMIA: null,
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      sortBy: "kundenNummer",
      emptyResponse: false,
      rerenderKey: 0
    };
  },
  components: { BackendFilter, Pagination, TableNew },
  computed: {
    dataTypeComp() {
      if (this.$route.path.includes("logbuch")) {
        return "CUSTOMERSLOGBUCH";
      } else {
        return "CUSTOMERS";
      }
    }
  },
  created() {
    this.getCustomers();
  },
  methods: {
    async getCustomers(render) {
      this.customers.splice(0, this.customers.length);
      const pagination = await this.$apiCall(
        technicianInfo.getCustomersPagination(this.customerPaginationDTO)
      );
      if (pagination) {
        this.customers = pagination.data;
        if (pagination.data.length === 0) {
          this.emptyResponse = true;
        } else {
          this.emptyResponse = false;
        }
        if (render) {
          this.changeRerender();
        }
      }
    },
    changeOrderByClick(sortBy, sortAsc) {
      this.customerPaginationDTO.orderBy = sortBy;
      this.customerPaginationDTO.ordneAufsteigend = sortAsc;
      this.getCustomers(true);
    },
    changeRerender() {
      this.rerenderKey = Date.now();
    }
  }
};
</script>
