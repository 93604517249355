let state = {
  contract: "",
  plantNumber: "",
  callPending: false,
  enableRouterBack: true,
  alternativeRouteBack: false
};

const getters = {
  contract: state => state.contract,
  plantNumber: state => state.plantNumber,
  callPending: state => state.callPending,
  enableRouterBack: state => state.enableRouterBack,
  alternativeRouteBack: state => state.alternativeRouteBack
};

const mutations = {
  setContract(state, contract) {
    state.contract = contract;
  },
  setPlantNumber(state, plantNumber) {
    state.plantNumber = plantNumber;
  },
  clear(state) {
    state.contract = "";
    state.plantNumber = "";
  },
  setCallPending(state, callPending) {
    state.callPending = callPending;
  },
  setEnableRouterBack(state, routerBack) {
    state.enableRouterBack = routerBack;
  },
  setAlternativeRouteBack(state, alternative) {
    state.alternativeRouteBack = alternative;
  }
};

const actions = {
  setContract({ commit }, contract) {
    commit("setContract", contract);
  },
  setPlantNumber({ commit }, plantNumber) {
    commit("setPlantNumber", plantNumber);
  },
  clear({ commit }) {
    commit("clear");
  },
  setCallPending({ commit }, callPending) {
    commit("setCallPending", callPending);
  },
  setEnableRouterBack({ commit }, enableRouterBack) {
    commit("setEnableRouterBack", enableRouterBack);
  },
  setAlternativeRouteBack({ commit }, alternativeRouteBack) {
    commit("setAlternativeRouteBack", alternativeRouteBack);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
