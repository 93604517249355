<template>
  <div class="content-background">
    <div>
      <h4>Anlagenhersteller/Service:</h4>
      <div class="margin-top-20 width800">
        <div class="basic-data-grid">
          <div>Firma:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.herstellerName"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Straße:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.herstellerStraße"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Hausnummer:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.herstellerHausnummer"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Postleitzahl:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.herstellerPlz"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Ort:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.herstellerOrt"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Zertifizierungsnummer:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.zertifizierungsnummer"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Abfallschlüssel Nr. Kältemittel:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.abfallschluesselKaeltemittel"
          />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Abfallschlüssel Nr. Öl:</div>
          <input
            class="input-edit"
            type="text"
            v-model="logbuchInfo.abfallschluesselOel"
          />
        </div>
        <div class="width800 text-align-right">
          <input
            @click="cancel"
            class="button confirm-button margin-right-20"
            type="button"
            value="Abbrechen"
          />
          <input
            @click="save"
            class="button confirm-button button-lightblue"
            type="button"
            value="Speichern"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "editLogbuchInfo",
  data() {
    return {
      logbuchInfo: {}
    };
  },
  created() {
    this.getLogbuchInfo();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async getLogbuchInfo() {
      const logbuchInfo = await this.$apiCall(
        this.$technicianInfoService.getLogbuchInfo()
      );
      if (logbuchInfo) {
        this.logbuchInfo = logbuchInfo.data;
      }
    },
    async save() {
      await this.$apiCall(
        this.$technicianInfoService.speichereLogbuchInfo(this.logbuchInfo)
      );
      this.$router.push({ name: "anlagenLogbuch" });
    }
  }
};
</script>
