<template>
  <div class="table-details-has-filter" id="AdministrationTechnicians">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      class="margin-top-20"
    />
    <TableNew
      :emptyResponse="emptyTechniciansResponse"
      :tableData="tableData"
      @change-order-by-click="changeOrderByClick"
      datatype="USER"
    />
    <input
      @click="createUser"
      :disabled="callPending"
      :class="
        callPending
          ? 'button margin-top-20 button-disabled'
          : 'button margin-top-20'
      "
      type="button"
      :value="buttonValue"
    />
  </div>
</template>

<script>
import TableNew from "../../helper/table/generalTable";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

export default {
  name: "AdministrationTechnicians",
  components: { FrontendFilter, TableNew },
  data() {
    return {
      tableData: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  props: {
    users: { type: Array },
    customerConfiguration: {
      type: Object
    },
    emptyTechniciansResponse: {
      type: Boolean,
      default: false
    },
    callPending: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    users() {
      this.sortTable();
    }
  },
  computed: {
    buttonValue() {
      return this.callPending ? "Bitte warten" : "Nutzer hinzufügen";
    }
  },
  created() {
    this.tableData = this.users;
  },
  methods: {
    createUser() {
      this.$router.push({
        name: "createUser",
        params: {
          customerNumber: this.customerConfiguration.kundenNummer,
          appUserId: this.customerConfiguration.appKundenId
        }
      });
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.tableData = changeOrderOfTable(this.users, this.paginationDTO);
    }
  }
};
</script>

<style scoped></style>
