<template>
  <div class="content-background" id="CreateLogbuchEintrag">
    <div class=" width800">
      <div class="basic-data-grid">
        <div>Datum:</div>
        <div>
          <v-date-picker v-model="$v.logbuch.datum.$model" :masks="masks">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
          <span class="error-label" v-show="!$v.logbuch.datum.required"
            >Datum erforderlich</span
          >
        </div>
        <div>Kältemittelart:</div>
        <div>
          <select v-model="logbuch.kaeltemittelArt">
            <option :value="''">
              Kein Kältemittel
            </option>
            <option :value="kaeltemittelart">{{ kaeltemittelart }}</option>
          </select>
        </div>
        <div>Gefüllt (kg):</div>
        <div>
          <input
            placeholder="Gefüllt (kg)"
            type="text"
            v-bind:class="{
              validationError: !$v.logbuch.gefuellt
            }"
            v-model="$v.logbuch.gefuellt.$model"
          />
          <span class="error-label" v-show="!$v.logbuch.gefuellt.decVal"
            >Gültige Zahl eingeben. Maximal 3 Nachkommastellen.</span
          >
        </div>
        <div>Entsorgt (kg):</div>
        <div>
          <input
            placeholder="Entsorgt (kg)"
            type="text"
            v-bind:class="{
              validationError: !$v.logbuch.entsorgt
            }"
            v-model="$v.logbuch.entsorgt.$model"
          />
          <span class="error-label" v-show="!$v.logbuch.entsorgt.decVal"
            >Gültige Zahl eingeben. Maximal 3 Nachkommastellen.</span
          >
        </div>
        <div>Angenommer Verlust (kg):</div>
        <div>
          <input
            placeholder="Angenommener Verlust (kg)"
            type="text"
            v-bind:class="{
              validationError: !$v.logbuch.angenommenVerlust
            }"
            v-model="$v.logbuch.angenommenVerlust.$model"
          />
          <span
            class="error-label"
            v-show="!$v.logbuch.angenommenVerlust.decVal"
            >Gültige Zahl eingeben. Maximal 3 Nachkommastellen.</span
          >
        </div>
        <div>Grund:</div>
        <div>
          <input
            placeholder="Grund"
            type="text"
            v-model="logbuch.grund"
            v-bind:class="{
              validationError: !$v.logbuch.grund
            }"
          />
          <span class="error-label" v-show="!$v.logbuch.grund.required"
            >Grund eintragen</span
          >
        </div>
        <div>Sachkundiger:</div>
        <div>
          <input
            placeholder="Sachkundiger"
            type="text"
            v-bind:class="{
              validationError: !$v.logbuch.sachkundiger
            }"
            v-model="$v.logbuch.sachkundiger.$model"
          />
          <span class="error-label" v-show="!$v.logbuch.sachkundiger.required"
            >Sachkundigen eintragen</span
          >
          <span class="error-label" v-show="!$v.logbuch.sachkundiger.maxLength"
            >Maximal 30 Zeichen erlaubt</span
          >
        </div>
        <div></div>
        <div class="text-align-right margin-top-20 width800">
          <input
            @click="cancel"
            class="button confirm-button margin-right-20"
            type="button"
            value="Abbrechen"
          />
          <input
            :disabled="!canSave"
            @click="save"
            class="button confirm-button"
            type="button"
            v-bind:class="{
              'button-lightblue': canSave,
              'button-disabled': !canSave
            }"
            value="Speichern"
          />
        </div>
      </div>
      <div v-show="successfullCreated === false">{{ failureMessage }}</div>
    </div>
  </div>
</template>

<script>
import {
  helpers,
  maxLength,
  numeric,
  required
} from "vuelidate/lib/validators";

// Nur Zahlen, Komma und 3 Nachkommastellen
const decVal = helpers.regex("decVal", /^\d*(?:\,\d{0,3})*$/);

export default {
  name: "LogbucheintragBearbeiten",
  data() {
    return {
      logbuch: {
        angenommenVerlust: null,
        datum: null,
        entsorgt: null,
        gefuellt: null,
        geraeteNummer: null,
        grund: null,
        id: null,
        kaeltemittelArt: null,
        sachkundiger: null
      },
      kaeltemittelart: null,
      successfullCreated: null,
      failureMessage: "",
      validationLengths: {
        textInputs: 30
      },
      masks: {
        input: ["DD.MM.YYYY", "D.M.YY"]
      }
    };
  },
  props: {
    logbuchDetails: {
      type: Object,
      default: undefined
    },
    secondparam: {
      type: String,
      default: ""
    }
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  },
  created() {
    this.kaeltemittelart = this.$attrs.device.kaeltemittelart;
    this.getLogbuch();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async getLogbuch() {
      if (this.logbuchDetails === undefined) {
        const logbuch = await this.$apiCall(
          this.$technicianInfoService.getLogbuch(this.secondparam)
        );
        if (logbuch) {
          this.logbuch = logbuch.data;
        }
      } else {
        this.logbuch = this.logbuchDetails;
      }
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$apiCall(
          this.$technicianInfoService.logbuchAktuallisieren(this.logbuch)
        );
        this.$toast.success("Logbuch erfolgreich aktualisiert!");
        this.$router.go(-1);
      }
    }
  },
  validations() {
    let v = {
      logbuch: {
        grund: {
          required,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        sachkundiger: {
          required,
          maxLength: maxLength(this.validationLengths.textInputs)
        },
        datum: {
          required
        },
        gefuellt: {
          decVal
        },
        entsorgt: {
          decVal
        },
        angenommenVerlust: {
          decVal
        }
      }
    };
    return v;
  }
};
</script>

<style scoped></style>
