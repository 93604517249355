<template>
  <div class="table-details-has-filter" id="PlantDevices">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      class="margin-top-20"
    />
    <TableNew
      :emptyResponse="emptyDevicesResponse"
      :tableData="tableData"
      @change-order-by-click="changeOrderByClick"
      datatype="DEVICES"
    />
  </div>
</template>

<script>
import TableNew from "../../helper/table/generalTable";
import { changeOrderOfTable } from "../../../services/helpServices";
import FrontendFilter from "../../helper/table/FrontendFilter";

export default {
  name: "PlantDevices",
  data() {
    return {
      tableData: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      }
    };
  },
  components: { FrontendFilter, TableNew },
  props: {
    devices: {
      type: Array
    },
    emptyDevicesResponse: { type: Boolean, default: false }
  },
  created() {
    this.tableData = this.devices;
  },
  methods: {
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.tableData = changeOrderOfTable(this.devices, this.paginationDTO);
    }
  }
};
</script>

<style scoped></style>
