<template>
  <thead>
    <th
      @click="clickEvent(hIndex)"
      v-bind:key="h.index"
      v-for="(h, hIndex) in tableHeader.tableHead"
    >
      {{ h }}
    </th>
  </thead>
</template>

<script>
export default {
  name: "GeneralTableHeader",
  data() {
    return {
      sortBy: "kundenNummer",
      sortAsc: true
    };
  },
  props: {
    datatype: {
      type: String
    },
    tableHeader: {
      type: Object
    },
    // dient dazu die Sortierung vorzugeben
    sortByProp: {
      datatype: String,
      default: ""
    },
    // dient dazu die Sortierung vorzugeben
    sortAscProp: {
      datatype: Boolean,
      default: null
    }
  },
  created() {
    if (!!this.sortByProp) {
      this.sortBy = this.sortByProp;
    }
    if (!!this.sortAscProp) {
      this.sortAsc = this.sortAscProp;
    }
  },
  methods: {
    clickEvent(index) {
      // wenn kein explizieter OrderBy angegeben ist, benutze den tableContent, z.B. bei Frontend-Sortierung

      this.sortBy = this.tableHeader.tableHeaderOrderBy
        ? this.tableHeader.tableHeaderOrderBy[index]
        : this.tableHeader.tableContent[index];
      this.sortAsc = !this.sortAsc;
      this.$emit("change-order-by-click", this.sortBy, this.sortAsc);
    }
  }
};
</script>
