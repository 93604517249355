<template>
  <div class="general-wrapper center-grid" id="RequestNewPassword">
    <div class="flex-column-center">
      <h2>Passwort zurücksetzen</h2>
      <div v-if="showForm">
        <h3>
          Sie sind dabei Ihr Passwort zurückzusetzen.
        </h3>
        <div class="width500">
          <input
            placeholder="E-Mail Adresse eintragen"
            type="email"
            v-model="$v.passwordDTO.email.$model"
          />
          <div class="text-align-right">
            <input
              @click="resetPassword"
              :disabled="!canSave"
              class="button confirm-button margin-top-20 text-align-center"
              v-bind:class="{
                'button-lightblue': canSave,
                'button-disabled': !canSave
              }"
              :value="buttonValue"
            />
          </div>
        </div>
      </div>
      <div v-else>
        Passwort zurücksetzen angefordert, bitte schauen Sie in Ihren E-Mails
        nach!
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { ResponseEnum } from "../enums/responseEnum";

export default {
  name: "RequestNewPassword",
  data() {
    return {
      passwordDTO: { email: "", rueckSetzGeheimnis: "" },
      wrongEmail: false,
      showForm: true
    };
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    emailFailureMessage() {
      return this.wrongEmail
        ? "Die eingegebene E-Mail Adresse existiert nicht"
        : !this.$v.passwordDTO.email.email ||
          !this.$v.passwordDTO.email.required
        ? "Gültige E-Mail Adresse eintragen"
        : "";
    },
    buttonValue() {
      return "Zurücksetzen";
    }
  },
  methods: {
    async resetPassword() {
      this.wrongEmail = false;
      await this.$store.dispatch("user/clear");
      const responseLogin = await this.$apiCall(
        this.$loginService.login("StandardBenutzer", "123abc")
      );
      if (responseLogin) {
        if (responseLogin.data["token"]) {
          await this.$store.dispatch(
            "user/setToken",
            responseLogin.data["token"]
          );
          await this.$store.dispatch(
            "user/setRole",
            responseLogin.data["berechtigungsStufe"]
          );
          await this.$store.dispatch(
            "user/setIsMelzerUser",
            responseLogin.data["istMelzerUser"]
          );
          const responsePassword = await this.$apiCall(
            this.$userInfoService.requestResetPassword(this.passwordDTO)
          );
          if (responsePassword) {
            switch (responsePassword.data) {
              case ResponseEnum.BenutzernameExistiertNicht:
                this.wrongEmail = true;
                this.$toast.error(
                  "Die eingegbene E-Mail Adresse exisitert nicht!"
                );
                break;
              case ResponseEnum.OK:
                this.showForm = false;
                this.$toast.success(
                  "Das Zurücksetzen wurde erfolgreich angefordert."
                );
                break;
              default:
                this.$router.go(-1);
            }
          }
        }
      }
    }
  },
  validations: {
    passwordDTO: {
      email: {
        required,
        email
      }
    }
  }
};
</script>

<style scoped></style>
