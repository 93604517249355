<template>
  <div class="content-background">
    <div>
      <h4>Persönliche Daten</h4>
      <div class="margin-top-20 width800">
        <div class="basic-data-grid">
          <div>Nachname:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              v-bind:class="{ validationError: !$v.userDTO.nachname.maxLength }"
              v-model="userDTO.nachname"
            />
            <span class="error-label" v-show="!$v.userDTO.nachname.maxLength"
              >Maximal {{ this.validationLengths.text }} Zeichen</span
            >
          </div>
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Vorname:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              v-bind:class="{ validationError: !$v.userDTO.vorname.maxLength }"
              v-model="userDTO.vorname"
            />
            <span class="error-label" v-show="!$v.userDTO.vorname.maxLength"
              >Maximal {{ this.validationLengths.text }} Zeichen</span
            >
          </div>
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>E-Mail:</div>
          <div>
            <input disabled type="text" v-model="userDTO.identifikationsName" />
          </div>
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Neue E-Mail:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              v-bind:class="{
                validationError:
                  !$v.userDTO.neuerIdentifikationsName.maxLength ||
                  !$v.userDTO.neuerIdentifikationsName.email
              }"
              v-model="userDTO.neuerIdentifikationsName"
            />
            <span
              class="error-label"
              v-show="!$v.userDTO.neuerIdentifikationsName.maxLength"
              >Maximal {{ this.validationLengths.text }} Zeichen</span
            >
            <span
              class="error-label"
              v-show="!$v.userDTO.neuerIdentifikationsName.email"
              >Gültige E-Mail Adresse eingeben</span
            >
          </div>
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Telefon:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              v-bind:class="{
                validationError:
                  !$v.userDTO.telefonNummer.maxLength ||
                  !$v.userDTO.telefonNummer.numeric
              }"
              v-model="userDTO.telefonNummer"
            />
            <span
              class="error-label"
              v-show="!$v.userDTO.telefonNummer.maxLength"
              >Maximal {{ this.validationLengths.text }} Zeichen</span
            >
            <span class="error-label" v-show="!$v.userDTO.telefonNummer.numeric"
              >Die Telefonnummer darf nur aus Zahlen bestehen.</span
            >
            <span
              class="error-label"
              v-show="!$v.userDTO.telefonNummer.required"
              >Es muss eine Telefonnummer eingeben werden.</span
            >
          </div>
        </div>
      </div>
      <h4 class="margin-top-20">Firma</h4>
      <div class="margin-top-20 width800">
        <div class="basic-data-grid margin-top-20">
          <div>Firmenname:</div>
          <input disabled type="text" v-model="userDTO.firma" />
        </div>
        <div class="basic-data-grid margin-top-20">
          <div>Aufgabenbereich:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              v-bind:class="{
                validationError: !$v.userDTO.aufgabenbereich.maxLength
              }"
              v-model="userDTO.aufgabenbereich"
            />
            <span
              class="error-label"
              v-show="!$v.userDTO.aufgabenbereich.maxLength"
              >Maximal {{ this.validationLengths.text }} Zeichen</span
            >
          </div>
        </div>
      </div>
      <div class="width800 text-align-right">
        <input
          @click="cancel"
          class="button confirm-button margin-right-20"
          type="button"
          value="Abbrechen"
        />
        <input
          :disabled="!canSave"
          @click="save"
          class="button confirm-button"
          type="button"
          v-bind:class="{
            'button-lightblue': canSave,
            'button-disabled': !canSave
          }"
          value="Speichern"
        />
      </div>
    </div>
    <div
      @click="editProfile"
      class="button square-button dark-blue top-corner disabled"
    >
      <font-awesome-icon icon="pen" />
    </div>
  </div>
</template>

<script>
import { email, maxLength, numeric, required } from "vuelidate/lib/validators";

export default {
  name: "ProfileSettings",
  data() {
    return {
      userDTO: {},
      validationLengths: {
        text: 100
      }
    };
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  },
  created() {
    this.getMyUser();
  },
  methods: {
    async getMyUser() {
      const user = await this.$apiCall(this.$technicianInfoService.getMyUser());
      if (user) {
        this.userDTO = user.data;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    save() {
      if (
        this.userDTO.neuerIdentifikationsName &&
        confirm("Wollen Sie wirklich ihre E-Mail Adresse ändern?")
      ) {
        this.updateUser(true);
      } else if (!this.userDTO.neuerIdentifikationsName) {
        this.updateUser();
      }
    },
    async updateUser(newIdentName = false) {
      const _response = await this.$apiCall(
        this.$technicianInfoService.updateUser(this.userDTO)
      );
      if (newIdentName) {
        this.$store.dispatch("user/clear");
        this.$toast.info(
          "Da Sie Ihre E-Mail Adresse geändert haben, müssen Sie sich neu anmelden."
        );
        this.$router.push({ name: "login" });
      } else this.$router.go(-1);
      this.$toast.success("Nutzer erfolgreich aktualisiert.");
    },
    editProfile() {
      this.$router.push({ name: "editProfile" });
    }
  },
  validations() {
    return {
      userDTO: {
        nachname: {
          maxLength: maxLength(this.validationLengths.text)
        },
        vorname: {
          maxLength: maxLength(this.validationLengths.text)
        },
        neuerIdentifikationsName: {
          maxLength: maxLength(this.validationLengths.text),
          email
        },
        telefonNummer: {
          maxLength: maxLength(this.validationLengths.text),
          numeric,
          required
        },
        aufgabenbereich: {
          maxLength: maxLength(this.validationLengths.text)
        }
      }
    };
  }
};
</script>
