var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-background width800",attrs:{"id":"CreateUser"}},[_c('div',{staticClass:"basic-data-grid"},[(_vm.showMelzerTechnicianButton)?_c('div',[_vm._v("Melzer Techniker anlegen")]):_vm._e(),(_vm.showMelzerTechnicianButton)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userDTO.istMelzerUser),expression:"userDTO.istMelzerUser"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.userDTO.istMelzerUser)?_vm._i(_vm.userDTO.istMelzerUser,null)>-1:(_vm.userDTO.istMelzerUser)},on:{"click":_vm.changeIsMelzerTechnician,"change":function($event){var $$a=_vm.userDTO.istMelzerUser,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.userDTO, "istMelzerUser", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.userDTO, "istMelzerUser", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.userDTO, "istMelzerUser", $$c)}}}})]):_vm._e(),_c('div',[_vm._v("Nachname")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.nachname.$model),expression:"$v.userDTO.nachname.$model"}],class:{
          validationError: !_vm.$v.userDTO.nachname.maxLength
        },attrs:{"placeholder":"Nachname","type":"text"},domProps:{"value":(_vm.$v.userDTO.nachname.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.nachname, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.nachname.required),expression:"!$v.userDTO.nachname.required"}],staticClass:"error-label"},[_vm._v("Nachname erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.nachname.maxLength),expression:"!$v.userDTO.nachname.maxLength"}],staticClass:"error-label"},[_vm._v("Maximal 255 Zeichen")])]),_c('div',[_vm._v("Vorname")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.vorname.$model),expression:"$v.userDTO.vorname.$model"}],class:{
          validationError: !_vm.$v.userDTO.vorname.maxLength
        },attrs:{"placeholder":"Vorname","type":"text"},domProps:{"value":(_vm.$v.userDTO.vorname.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.vorname, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.vorname.required),expression:"!$v.userDTO.vorname.required"}],staticClass:"error-label"},[_vm._v("Vorname erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.vorname.maxLength),expression:"!$v.userDTO.vorname.maxLength"}],staticClass:"error-label"},[_vm._v("Maximal 255 Zeichen")])]),_c('div',[_vm._v("Telefon")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.telefonNummer.$model),expression:"$v.userDTO.telefonNummer.$model"}],class:{
          validationError: !_vm.$v.userDTO.telefonNummer.maxLength
        },attrs:{"placeholder":"Telefonnummer","type":"text"},domProps:{"value":(_vm.$v.userDTO.telefonNummer.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.telefonNummer, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.telefonNummer.required),expression:"!$v.userDTO.telefonNummer.required"}],staticClass:"error-label"},[_vm._v("Telefonnummer erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.telefonNummer.maxLength),expression:"!$v.userDTO.telefonNummer.maxLength"}],staticClass:"error-label"},[_vm._v("Maximal 255 Zeichen")])]),_c('div',[_vm._v("E-Mail")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.identifikationsName.$model),expression:"$v.userDTO.identifikationsName.$model"}],class:{
          validationError: !_vm.$v.userDTO.identifikationsName.maxLength
        },attrs:{"placeholder":"E-Mail","type":"email"},domProps:{"value":(_vm.$v.userDTO.identifikationsName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.identifikationsName, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.$v.userDTO.identifikationsName.required ||
            !_vm.$v.userDTO.identifikationsName.email
        ),expression:"\n          !$v.userDTO.identifikationsName.required ||\n            !$v.userDTO.identifikationsName.email\n        "}],staticClass:"error-label"},[_vm._v("Gültige E-Mail Adresse erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.identifikationsName.maxLength),expression:"!$v.userDTO.identifikationsName.maxLength"}],staticClass:"error-label"},[_vm._v("Maximal 255 Zeichen")])]),_c('div',[_vm._v("Kundennummer")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.kundenNummer.$model),expression:"$v.userDTO.kundenNummer.$model"}],attrs:{"disabled":_vm.isRegistration ? false : true,"placeholder":"Kundennummer","type":"text"},domProps:{"value":(_vm.$v.userDTO.kundenNummer.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.kundenNummer, "$model", $event.target.value)}}}),(_vm.isRegistration)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.kundenNummer.required),expression:"!$v.userDTO.kundenNummer.required"}],staticClass:"error-label"},[_vm._v("Kundennummer erforderlich")]):_vm._e()]),_c('div',[_vm._v("Passwort")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.passwort.$model),expression:"$v.userDTO.passwort.$model"}],class:{
          validationError: !_vm.$v.userDTO.passwort.minLength
        },attrs:{"placeholder":'Passwort mit mindestens ' +
            _vm.validationLengths.minPassword +
            ' Zeichen',"type":"password"},domProps:{"value":(_vm.$v.userDTO.passwort.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userDTO.passwort, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.userDTO.passwort.required),expression:"!$v.userDTO.passwort.required"}],staticClass:"error-label"},[_vm._v("Passwort erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.$v.userDTO.passwort.maxLength || !_vm.$v.userDTO.passwort.minLength
        ),expression:"\n          !$v.userDTO.passwort.maxLength || !$v.userDTO.passwort.minLength\n        "}],staticClass:"error-label"},[_vm._v("Das Passwort muss aus 8 Zeichen bestehen.")])]),(_vm.isRegistration)?_c('div',[_vm._v(" Ich habe die Informationen zum "),_c('a',{staticClass:"link",attrs:{"href":_vm.datenschutzerklaerungPdfLink,"target":"_blank"}},[_vm._v("Datenschutz")]),_vm._v(" gelesen und akzeptiert ")]):_vm._e(),(_vm.isRegistration)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.userDTO.dsgvo.$model),expression:"$v.userDTO.dsgvo.$model"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.userDTO.dsgvo.$model)?_vm._i(_vm.$v.userDTO.dsgvo.$model,null)>-1:(_vm.$v.userDTO.dsgvo.$model)},on:{"change":function($event){var $$a=_vm.$v.userDTO.dsgvo.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.userDTO.dsgvo, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.userDTO.dsgvo, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.userDTO.dsgvo, "$model", $$c)}}}})]):_vm._e(),_c('div'),_c('div',{staticClass:"text-align-right margin-top-20 width800"},[_c('input',{staticClass:"button confirm-button margin-right-20",attrs:{"type":"button","value":"Abbrechen"},on:{"click":_vm.cancel}}),_c('input',{staticClass:"button confirm-button",class:{
          'button-lightblue': _vm.canSave,
          'button-disabled': !_vm.canSave
        },attrs:{"disabled":!_vm.canSave,"type":"button","value":"Speichern"},on:{"click":_vm.save}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.successfullCreated === false),expression:"successfullCreated === false"}]},[_vm._v(_vm._s(_vm.failureMessage))])])}
var staticRenderFns = []

export { render, staticRenderFns }