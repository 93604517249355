<template>
  <div class="content-background" id="ChangePassword">
    <h4>Passwort ändern</h4>
    <div class="margin-top-20 width800">
      <form class="basic-data-grid">
        <div>Altes Passwort</div>
        <div>
          <input
            autocomplete="current-password"
            type="password"
            v-model="$v.userDTO.altesPasswort.$model"
          />
          <span class="error-label" v-show="oldPasswordFailureMessage">{{
            oldPasswordFailureMessage
          }}</span>
        </div>
        <div>Neues Passwort</div>
        <div>
          <input
            autocomplete="new-password"
            type="password"
            v-model="$v.userDTO.passwort.$model"
          />
          <span class="error-label" v-show="newPasswordFailureMessage">{{
            newPasswordFailureMessage
          }}</span>
        </div>
        <div>Neues Passwort Wiederholung</div>
        <div>
          <input
            autocomplete="new-password"
            type="password"
            v-model="$v.checkNewPw.$model"
          />
          <span class="error-label" v-show="checkNewPwFailureMessage">{{
            checkNewPwFailureMessage
          }}</span>
        </div>
        <div></div>
        <div class="text-align-right">
          <input
            @click="$router.go(-1)"
            class="button confirm-button margin-right-20"
            data-vv-delay="100"
            type="button"
            value="Abbrechen"
          />
          <input
            :disabled="!canSave"
            @click="saveUser"
            class="button confirm-button"
            data-vv-delay="100"
            type="button"
            v-bind:class="{
              'button-lightblue': canSave,
              'button-disabled': !canSave
            }"
            value="Speichern"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { ResponseEnum } from "../../enums/responseEnum";

export default {
  name: "ChangeOwnPassword",
  data() {
    return {
      userDTO: {
        appKundenId: 0,
        identifikationsName: "",
        berechtigungsStufe: 6,
        zuletztVeraendertDurch: "",
        istMelzerUser: false,
        firma: "",
        freigeschaltet: true,
        vorname: "",
        nachname: "",
        passwort: "",
        telefonNummer: "",
        aufgabenbereich: "",
        altesPasswort: "",
        kundenNummer: null
      },
      checkNewPw: null,
      submitStatus: null,
      wrongOldPassword: false
    };
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    oldPasswordFailureMessage() {
      return this.wrongOldPassword
        ? "Ein falsches Passwort wurde eingegeben"
        : !this.$v.userDTO.altesPasswort.required
        ? "Sie müssen Ihr altes Passwort eintragen"
        : !this.$v.userDTO.altesPasswort.minLength
        ? "Ein Passwort muss mindestens 8 Zeichen lang sein"
        : "";
    },
    newPasswordFailureMessage() {
      return !this.$v.userDTO.passwort.required
        ? "Sie müssen Ihr neues Passwort eintragen"
        : !this.$v.userDTO.passwort.minLength
        ? "Ein Passwort muss mindestens 8 Zeichen lang sein"
        : "";
    },
    checkNewPwFailureMessage() {
      return !this.$v.checkNewPw.sameAsNewPassword
        ? "Sie müssen Ihr neues Passwort wiederholen"
        : "";
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      const user = await this.$apiCall(this.$technicianInfoService.getMyUser());
      this.userDTO = user.data;
    },
    async saveUser() {
      if (this.canSave) {
        this.wrongOldPassword = false;
        const passwort = await this.$apiCall(
          this.$technicianInfoService.saveUserWithPassword(this.userDTO)
        );
        if (passwort) {
          switch (passwort.data) {
            case ResponseEnum.PasswortFalsch:
              this.wrongOldPassword = true;
              break;
            default:
              this.$router.go(-1);
          }
        }
      }
    }
  },
  validations: {
    userDTO: {
      altesPasswort: { required, minLength: minLength(8) },
      passwort: { required, minLength: minLength(8) }
    },
    checkNewPw: {
      sameAsNewPassword: sameAs(function() {
        return this.userDTO.passwort;
      })
    }
  }
};
</script>

<style scoped></style>
