<template>
  <div class="content-grid" id="settings">
    <div class="table-area">
      <SwipeBar :elements="swipeBarElements" class="tab-bar" type="DEVICE" />
      <router-view />
    </div>
  </div>
</template>

<script>
import SwipeBar from "../../helper/SwipeBar";

export default {
  name: "Settings",
  data() {
    return {
      swipeBarElements: [
        {
          name: "Profil",
          route: "profileMain"
        },
        {
          name: "Nutzer",
          route: "user"
        },
        {
          name: "Konfiguration",
          route: "configuration"
        }
      ]
    };
  },
  components: {
    SwipeBar
  },
  created() {
    this.reloadSwipebar();
  },
  methods: {
    reloadSwipebar() {
      const isAdmin = this.$store.getters["user/role"] === 10;
      let swipeBar = [
        {
          name: "Profil",
          route: "profileMain"
        },
        {
          name: "Nutzer",
          route: "user"
        },
        {
          name: "Konfiguration",
          route: "configuration"
        }
      ];
      if (isAdmin) {
        swipeBar.push({
          name: "Anlagen-Logbuch",
          route: "anlagenLogbuch"
        });
        this.swipeBarElements = swipeBar;
      }
    }
  }
};
</script>

<style scoped></style>
