<template>
  <div class="general-wrapper center-grid">
    <div class="flex-column-center">
      <img
        alt="Logo der Firma"
        class="short-info-logo"
        src="@/assets/melzer_flocke.png"
      />
      <div><h2>Vielen Dank für Ihre Meldung!</h2></div>
      <div>
        <h3>
          Bitte überprüfen Sie Ihr Postfach und bestätigen Sie Ihre E-Mail mit
          dem in der Mail enthaltenen Link. Erst nach Bestätigung der E-Mail
          Adresse wird Ihr Störungsmeldung weitergeleitet. Sie können das
          Fenster schließen oder:
          <router-link
            class="font-lightblue"
            :to="{ name: 'infoPage', params: this.$route.params.deviceNumber }"
          >
            Zurück zur Übersicht
          </router-link>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckMail"
};
</script>
