<template>
  <div id="plantDetails" class="content-grid">
    <div class="info-area">
      <h2>Anlagendetails</h2>
      <div class="wrapper">
        <div class="data-list">
          <span>Anlage: </span><span>{{ plant.nr }}</span>
          <span>Beschreibung: </span><span>{{ plant.info }}</span>
          <span>Anzahl Geräte: </span
          ><span>{{ plant.zugehoerigeGeraete.length }}</span>
          <span>Vetragsnummer: </span><span>{{ plant.vertragsNummer }}</span>
        </div>
        <div class="data-list">
          <span>Standort: </span
          ><span
            >{{ plant.standortAnschriftStr }} {{ plant.standortAnschriftHNr }},
            {{ plant.standortAnschriftPLZ }}
            {{ plant.standortAnschriftOrt }}</span
          >
        </div>
        <div class="data-list">
          <span>Kundenname: </span
          ><span>{{ customer.kdNameTabellenAnzeige }}</span>
          <span>Kundenadresse: </span
          ><span
            >{{ customer.kdAnschriftStr }} {{ customer.kdAnschriftHNr }},
            {{ customer.kdAnschriftPLZ }} {{ customer.kdAnschriftOrt }}</span
          >
          <span>Kundennummer: </span><span>{{ customer.kundenNummer }}</span>
          <span>Matchcode: </span><span>{{ customer.kdMatch }}</span>
        </div>
      </div>
    </div>
    <div class="table-area">
      <SwipeBar :elements="swipeBarElements" class="tab-bar" type="PLANT" />
      <router-view
        :key="plant.id"
        :devices="plant.zugehoerigeGeraete"
        :emptyDevicesResponse="emptyDevicesResponse"
        :emptyMaintenancesResponse="emptyMaintenancesResponse"
        :emptyYearsResponse="emptyYearsResponse"
        :maintenances="plant.zugehoerigeVertragsLeistungen"
        :plantId="plant.id"
        :years="plant.messungJahresZahlen"
      />
    </div>
  </div>
</template>

<script>
import SwipeBar from "../../helper/SwipeBar";

export default {
  name: "PlantDetails",
  components: { SwipeBar },
  data() {
    return {
      plant: {
        messungJahresZahlen: [],
        zugehoerigeGeraete: [],
        zugehoerigeVertragsLeistungen: null,
        anlagenStoerungsStatus: "",
        info: "",
        mtMatch: "",
        mtName2: "",
        vtId: null,
        mtAnschriftOrt: "",
        mtName1: "",
        mtName3: "",
        nr: "",
        standortAnschriftOrt: "",
        guid: "",
        standortAnschriftHNr: "",
        standortAnschriftStr: "",
        standortAnschriftPLZ: "",
        mtWohnungLage: "",
        mtAnschriftHNr: "",
        mtCtyISO: "",
        mtAnschriftStr: "",
        mtAnschriftPLZ: "",
        mtWohnung: "",
        ojAnschriftOrt: "",
        ojAnschriftStr: "",
        ojAnschriftHNr: "",
        ojName3: "",
        ojName2: "",
        standortCtyISO: "",
        ojName1: "",
        ojAnschriftPLZ: "",
        ojCtyISO: "",
        etMatch: "",
        etAnschriftStr: "",
        hmName1: "",
        etAnschriftPLZ: "",
        etName1: "",
        etAnschriftOrt: "",
        etName3: "",
        etAnschriftHNr: "",
        etName2: "",
        etCtyISO: "",
        hmMatch: "",
        hmCtyISO: "",
        hmAnschriftPLZ: "",
        hmName2: "",
        hmAnschriftStr: "",
        hmName3: "",
        hmAnschriftOrt: "",
        hmAnschriftHNr: "",
        vertragsNummer: "VXXXXX"
      },
      customer: {},
      swipeBarElements: [
        { name: "Wartung", route: "maintenances" },
        { name: "Geräte", route: "devices" },
        { name: "Messung", route: "measurements" }
      ],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyMaintenancesResponse: false,
      emptyDevicesResponse: false,
      emptyYearsResponse: false
    };
  },
  created() {
    this.getPlantFullInfoByPlantNumber();
  },
  methods: {
    async getPlantFullInfoByPlantNumber() {
      let plantNr = this.$route.params.nr;
      await this.$store.dispatch("misc/setPlantNumber", plantNr);
      const plantFullInfo = await this.$apiCall(
        this.$technicianInfoService.getPlantFullInfoByPlantNumber(plantNr)
      );
      if (plantFullInfo) {
        this.plant = plantFullInfo.data;
        await this.$store.dispatch("misc/setContract", this.plant.vtId);
        if (this.plant.zugehoerigeVertragsLeistungen.length === 0)
          this.emptyMaintenancesResponse = true;
        if (this.plant.zugehoerigeGeraete.length === 0)
          this.emptyDevicesResponse = true;
        if (this.plant.messungJahresZahlen.length === 0) {
          this.emptyYearsResponse = true;
        }
        const customerInfo = await this.$apiCall(
          this.$technicianInfoService.getCustomerInfo(plantFullInfo.data.vtId)
        );
        if (customerInfo) {
          this.customer = customerInfo.data;
        }
      }
    }
  }
};
</script>
