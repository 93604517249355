<template>
  <div id="topDate">
    {{ now }}
  </div>
</template>

<script>
export default {
  name: "TopDate",
  data() {
    return {
      now: new Date()
    };
  },
  created() {
    var options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
    // sets time at start, the intervall starts one second later
    this.now = new Date().toLocaleString("de-DE", options);
    setInterval(
      () => (this.now = new Date().toLocaleString("de-DE", options)),
      1000
    );
  }
};
</script>
