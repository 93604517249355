<template>
  <div class="content-background" id="userDetails">
    <h3>Persönliche Informationen</h3>
    <div class="button square-button top-corner " v-if="canDeleteUser">
      <font-awesome-icon class="" icon="trash" v-on:click="deleteUser" />
    </div>
    <div
      class="button square-button top-corner margin-top-60"
      v-if="canChangePassword"
    >
      <font-awesome-icon class="" icon="key" v-on:click="changePassword" />
    </div>
    <div class="basic-data-grid margin-top-20">
      <div>Nachname:</div>
      <div>{{ user.nachname }}</div>
      <div>Vorname:</div>
      <div>{{ user.vorname }}</div>
      <div>Email:</div>
      <div>{{ user.identifikationsName }}</div>
      <div>Berechtigungsstufe:</div>
      <div>{{ user.berechtigungsStufe | role }}</div>
      <div>Freigeschaltet:</div>
      <div>
        <span class="margin-right-20">{{ user.freigeschaltet | boolean }}</span>
        <span
          class="link cursor-pointer"
          v-if="!user.freigeschaltet"
          v-on:click="toggleActive"
          >User freischalten</span
        >
      </div>
      <div>Firma:</div>
      <div>{{ user.firma }}</div>
      <div>Telefon Nummer:</div>
      <div>{{ user.telefonNummer }}</div>
      <div>Aufgabenbereich:</div>
      <div>{{ user.aufgabenbereich }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetails",
  data() {
    return {
      user: {}
    };
  },
  computed: {
    canDeleteUser() {
      return (
        this.$store.getters["user/isMelzerUser"] === true &&
        this.$store.getters["user/role"] === 10
      );
    },
    canChangePassword() {
      return (
        this.$store.getters["user/isMelzerUser"] === true &&
        this.$store.getters["user/role"] === 10
      );
    }
  },
  created() {
    this.getUserByName();
  },
  methods: {
    async getUserByName() {
      const userName = this.$route.params.nr;
      const response = await this.$apiCall(
        this.$technicianInfoService.getUserByName(userName)
      );
      if (response) {
        this.user = response.data;
      }
    },
    async createUser() {
      const _response = await this.$apiCall(
        this.$technicianInfoService.createUser()
      );
    },
    toggleActive() {
      this.user.freigeschaltet = true;
      this.changeUser();
    },
    async changeUser() {
      const _response = await this.$apiCall(
        this.$technicianInfoService.setUserActive(this.user)
      );
    },
    async changePassword() {
      if (
        confirm(
          "Wollen Sie wirklich das Passwort für diesen Benutzer zurücksetzen?"
        )
      ) {
        const _respone = await this.$apiCall(
          this.$userInfoService.requestResetPassword({
            email: this.user.identifikationsName,
            rueckSetzGeheimnis: null
          })
        );
        this.$toast.success("Passwort Zurücksetzen wurde angefordert.");
      }
    },
    async deleteUser() {
      if (confirm("Wollen Sie wirklich den Benutzer löschen?")) {
        const _response = await this.$apiCall(
          this.$administrationService.deleteUser(this.user)
        );
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style scoped></style>
