<template>
  <div class="general-wrapper center-grid" id="PageNotFound">
    <div class="flex-column-center">
      <span>
        Die Seite wurde nicht gefunden. Zurück zum
        <router-link :to="{ name: 'dashboard' }" class="font-lightblue"
          >Dashboard
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
  created() {
    this.checkForGetRequests(this.$route.path);
  },
  methods: {
    checkForGetRequests(to) {
      if (this.isPasswordReset(to)) {
        this.routeToPasswordReset(to);
      } else if (this.isVerification(to)) {
        this.routeToVerification(to);
      } else if (this.isQrCode(to)) {
        this.routeToQrCode(to);
      } /*else {
        this.$router.push({ name: "login" });
      }*/
    },

    isQrCode(to) {
      return to.includes("id") || to.includes("QR");
    },

    routeToQrCode(to, next) {
      this.$router.push({
        name: "infoPage",
        params: {
          deviceNumber: this.$route.path.substr(4, this.$route.path.length)
        }
      });
      let deviceNumber =
        to.query["QR"] !== undefined ? to.query["QR"] : to.query["id"];
      next({
        name: "infoPage",
        params: {
          deviceNumber: deviceNumber
        }
      });
    },

    isVerification(to) {
      return to.includes("id") && to.includes("geheimnis");
    },

    routeToVerification(to) {
      this.$router.push({
        name: "failureMessageAccept",
        params: {
          id: this.$route.path.substr(4, this.$route.path.length)
        }
      });
      next({
        name: "failureMessageAccept",
        params: {
          id: to.query["id"],
          secret: to.query["geheimnis"]
        }
      });
    },

    isPasswordReset(to) {
      return to.includes("type") && to.includes("secret");
    },

    routeToPasswordReset(to, next) {
      next({
        name: "resetPassword",
        params: {
          secret: to.query["secret"]
        }
      });
    }
  }
};
</script>
