import Vue from "vue";
//Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowRight,
  faCamera,
  faCheck,
  faCog,
  faExclamationTriangle,
  faFileAlt,
  faHome,
  faKey,
  faLevelDownAlt,
  faPen,
  faPlus,
  faQrcode,
  faScrewdriver,
  faSearch,
  faSnowflake,
  faTimes,
  faTimesCircle,
  faTrash,
  faWrench,
  faBook,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/*Fontawesome*/
library.add(
  faHome,
  faSnowflake,
  faQrcode,
  faCog,
  faScrewdriver,
  faLevelDownAlt,
  faExclamationTriangle,
  faCamera,
  faArrowRight,
  faPen,
  faSearch,
  faCheck,
  faTimes,
  faWrench,
  faArrowCircleLeft,
  faArrowCircleRight,
  faPlus,
  faTimesCircle,
  faKey,
  faTrash,
  faFileAlt,
  faBook,
  faFilePdf,
  faSnowflake
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
