<template>
  <div class="table-area overflow-y-scroll" id="sendFailureMessage">
    <div class="flex-column-center">
      <h2>Störungsmeldung</h2>
      <h3>
        Sie sind dabei eine Störungsmeldung an Melzer Klimatechnik zu senden.
      </h3>
      <textarea
        placeholder="Hier Beschreibung hinzufügen"
        v-bind:class="{
          validationError: !$v.failureDTO.stoerungsbeschreibung.maxLength
        }"
        v-model="$v.failureDTO.stoerungsbeschreibung.$model"
      />
      <span
        class="error-label"
        v-show="!$v.failureDTO.stoerungsbeschreibung.required"
        >Beschreibung erforderlich</span
      >
      <span
        class="error-label"
        v-show="!$v.failureDTO.stoerungsbeschreibung.maxLength"
        >Maximal 500 Zeichen</span
      >
      <div class="width800">
        <input
          :disabled="failureDTO.stoerungsBilder.length > 5"
          @change="addFailurePicture"
          accept="image/*"
          id="imgUpload"
          name="imgUpload"
          type="file"
        />
        <label
          :class="failureDTO.stoerungsBilder.length > 5 ? 'opacity-half' : ''"
          for="imgUpload"
        >
          <div class="imgUploadAlt">
            <h3>Foto hochladen (max. 6 Bilder)</h3>
            <div class="button">
              <font-awesome-icon icon="camera" />
            </div>
          </div>
        </label>
      </div>
      <div
        class="picture-gallery margin-top-20 width800"
        v-if="failureDTO.stoerungsBilder.length > 0"
      >
        <div
          v-bind:key="v.name"
          v-for="(v, index) in $v.failureDTO.stoerungsBilder.$each.$iter"
        >
          <ImageWrapper
            :index="parseInt(index)"
            :isValidImage="v.typ.isValidImage"
            :src="v.$model.base64Data"
            @delete-picture="deletePicture"
          />
        </div>
      </div>
      <div class="width800" style="text-align: right">
        <input
          :disabled="!canSave"
          @click="sendFailureMessage()"
          class="button confirm-button"
          type="button"
          v-bind:class="{
            'button-lightblue': canSave,
            'button-disabled': !canSave
          }"
          value="Senden"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDateBackend,
  imageToBase64
} from "../../../services/helpServices";
import { maxLength, required } from "vuelidate/lib/validators";
import ImageWrapper from "../../helper/ImageWrapper";

const isValidImage = imageType => imageType.indexOf("image/") >= 0;

export default {
  name: "DeviceSendFailureMessage",
  components: {
    ImageWrapper
  },
  data() {
    return {
      failureDTO: {
        ID: 0,
        behoben: false,
        geraeteNummer: 0,
        anlagenNummer: 0,
        eMailAdresse: "",
        stoerungsbeschreibung: "",
        stoerungsZeitpunkt: formatDateBackend(new Date()), // backend requires correct Datestrings but overwrites/ignores them
        emailBestaetigt: true,
        behobenAm: formatDateBackend(new Date()), // backend requires correct Datestrings but overwrites/ignores them
        kundenNummer: 0,
        stoerungsBilder: []
      }
    };
  },
  computed: {
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  },
  created() {
    this.failureDTO.geraeteNummer = this.$route.params.deviceNr;
    this.failureDTO.anlagenNummer = this.$route.params.plantNr;
    this.failureDTO.eMailAdresse = this.$store.getters["user/userName"];
  },
  methods: {
    async addFailurePicture(event) {
      let file = event.target.files[0];
      this.failureDTO.stoerungsBilder.push({
        id: null,
        stoerMeldungsID: null,
        typ: file.type,
        base64Data: await imageToBase64(file)
      });
    },
    async sendFailureMessage() {
      this.callPending = true;
      const failureMessage = await this.$apiCall(
        this.$technicianInfoService.sendFailureMessage(this.failureDTO)
      );

      if (failureMessage) {
        this.$toast.success("Störung erfolgreich gesendet.");
      }
      this.$router.go(-1);
    },
    deletePicture(index) {
      this.failureDTO.stoerungsBilder.splice(index, 1);
    }
  },
  validations: {
    failureDTO: {
      stoerungsbeschreibung: {
        required,
        maxLength: maxLength(500)
      },
      stoerungsBilder: {
        $each: {
          typ: {
            isValidImage
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
