<template>
  <div>
    <router-link
      v-if="routerLink"
      :to="{ name: path, params: { nr: params } }"
      class="tile"
    >
      <img :src="require('@/assets/img/symbol_' + this.symbol + '.svg')" />
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </router-link>
    <a v-if="!routerLink" :href="path" class="tile">
      <img :src="require('@/assets/img/symbol_' + this.symbol + '.svg')" />
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Tile",
  data() {
    return {};
  },
  props: {
    title: { type: String },
    subtitle: { type: String, default: "" },
    path: { type: String },
    params: { type: Object },
    symbol: { type: String },
    routerLink: { type: Boolean, default: true }
  },
  computed: {
    symbolUrl() {
      return "@/assets/symbols/symbol_" + this.symbol + ".svg";
    }
  }
};
</script>
