<template>
  <div class="table-has-filter" id="ContractsForCustomer">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      location-heading="Kundenverträge"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="contracts"
      @change-order-by-click="changeOrderByClick"
      :datatype="dataTypeComp"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

export default {
  name: "Contracts",
  data() {
    return {
      contracts: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  components: { FrontendFilter, TableNew },
  computed: {
    dataTypeComp() {
      if (this.$route.path.includes("Logbuch")) {
        return "CONTRACTS_FOR_CUSTOMER_LOGBUCH";
      } else {
        return "CONTRACTS_FOR_CUSTOMER";
      }
    }
  },
  created() {
    this.getContractsForCustomerByCustomerNumber();
  },
  methods: {
    async getContractsForCustomerByCustomerNumber() {
      let customerNumber = this.$route.params.nr;
      const contract = await this.$apiCall(
        this.$technicianInfoService.getContractsForCustomerByCustomerNumber(
          customerNumber
        )
      );
      if (contract) {
        this.contracts = contract.data;
        this.tableDataBackup = contract.data;
        if (contract.data.length === 0) this.emptyResponse = true;
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.contracts = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
    }
  }
};
</script>

<style scoped></style>
