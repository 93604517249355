<template>
  <div class="center-grid general-wrapper">
    <div class="flex-column-center">
      <div v-if="failureMessageAccepted">
        Vielen Dank für die Meldung einer Störung!
      </div>
      <div v-else>
        Störung wird gemeldet...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptFailureMessage",
  data() {
    return {
      failureMessageAccepted: false,
      failureId: "",
      secret: ""
    };
  },
  created() {
    if (this.$store.getters["user/token"] === "") {
      this.$loginService
        .login("StandardBenutzer", "123abc")
        .then(response => {
          if (response.data["token"]) {
            this.$store.dispatch("user/setToken", response.data["token"]);
            this.$store.dispatch(
              "user/setRole",
              response.data["berechtigungsStufe"]
            );
            this.$store.dispatch(
              "user/setIsMelzerUser",
              response.data["istMelzerUser"]
            );
            this.check();
          }
        }, this)
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    } else {
      this.check();
    }
  },
  methods: {
    check: function() {
      let failureId = this.$route.params.id;
      let secret = this.$route.params.secret;
      let failureMessageAcceptDTO = {
        id: failureId,
        geheimnis: secret
      };
      this.$userInfoService
        .acceptFailureMessage(failureMessageAcceptDTO)
        .then(response => {
          if (response.status === 200) {
            this.failureMessageAccepted = true;
          }
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    }
  }
};
</script>

<style scoped></style>
