"use strict";
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "normalize.css";
import "./filter/filter.js";
import "./assets/icons";
import Vuelidate from "vuelidate";
import VueLuxon from "vue-luxon";
import Paginate from "vuejs-paginate";
import plugin from "./plugins";
import TechnicianInfoService from "./services/technicianInfoService";
import AdministrationService from "./services/administrationService";
import UserInfoService from "./services/userInfoService";
import LoginService from "./services/loginService";
import vmodal from "vue-js-modal";
import VCalendar from "v-calendar";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import vClickOutside from "v-click-outside";

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueLuxon);
Vue.component("paginate", Paginate);
Vue.use(plugin);
Vue.use(VueToast, { position: "top-right" });

Vue.use(vmodal, { dialog: true });
Vue.use(VCalendar);
Vue.use(vClickOutside);

Vue.prototype.$technicianInfoService = new TechnicianInfoService();
Vue.prototype.$administrationService = new AdministrationService();
Vue.prototype.$userInfoService = new UserInfoService();
Vue.prototype.$loginService = new LoginService();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
