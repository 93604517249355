import axios from "axios";
import store from "../store/index.js";
import { AGENT, BASE_URL, BEARER } from "./configuration";

export default class TechnicianInfoService {
  technicianPath = BASE_URL + "TechnikerInfo/";

  getMyContracts() {
    return axios.get(this.technicianPath + "getMeineVertraege", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getContractsForCustomerByCustomerNumber(customerNumber) {
    return axios.get(this.technicianPath + "getVertraegeFuerKunde", {
      params: {
        KundenNummer: customerNumber
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyUser() {
    return this.getUserByName(store.getters["user/userName"]);
  }

  getUserByName(name) {
    return axios.get(this.technicianPath + "getBenutzerMitIDName", {
      params: {
        IDName: name
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomerConfiguration(customerNumber) {
    return axios.get(this.technicianPath + "getKundenKonfig", {
      params: {
        kundenNummer: customerNumber
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyAppUserId() {
    return axios.get(this.technicianPath + "getMeineAppUserId", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyUsers() {
    return axios.get(this.technicianPath + "getMeineBenutzer", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  deleteMyUser(userDTO) {
    return axios.post(this.technicianPath + "loescheMeinenBenutzer", userDTO, {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getUsersOfCustomer(customerId) {
    return axios.get(this.technicianPath + "getBenutzerFuerKunde", {
      params: {
        AppKundenID: customerId
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomerInfo(vertragsId) {
    return axios.get(this.technicianPath + "getKundenInfo", {
      params: {
        vertragsId: vertragsId
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomers() {
    return axios.get(this.technicianPath + "getKunden", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomersPagination(kundenSeiteSuchDTO) {
    return axios.post(
      this.technicianPath + "getKundenSeite",
      kundenSeiteSuchDTO,
      {
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getCustomersPaginationPageCount(kundenSeiteSuchDTO) {
    return axios.post(
      this.technicianPath + "getKundenSeiteAnzahl",
      kundenSeiteSuchDTO,
      {
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getMyDeactivatedUsers() {
    return axios.get(
      this.technicianPath + "getMeineNichtFreigeschaltetenBenutzer",
      {
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getMyDeactivatedUsersForCustomer(appUserId) {
    return axios.get(
      this.technicianPath + "getMeineNichtFreigeschaltetenBenutzer",
      {
        params: {
          AppKundenID: appUserId
        },
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  createUser(userDTO) {
    userDTO.freigeschaltet = true;
    return axios.post(this.technicianPath + "erstelleBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  saveUser(userDTO) {
    return axios.post(this.technicianPath + "speichereBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  updateUser(userDTO) {
    return axios.post(this.technicianPath + "aendereMeinenBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  setUserActive(userDTO) {
    return axios.post(this.technicianPath + "schalteBenutzerFrei", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  saveUserWithPassword(userDTO) {
    return axios.post(
      this.technicianPath + "speichereBenutzerMitPasswort",
      userDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  sendFailureMessage(failureDTO) {
    return axios.post(this.technicianPath + "meldeStoerung", failureDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyPlantsShortInfo() {
    return axios.get(this.technicianPath + "getMeineAnlagen", {
      httpsAgent: AGENT,
      headers: {
        httpsAgent: AGENT,
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyPlantsShortInfoContract(vertragsId) {
    return axios.get(
      this.technicianPath + "getMeineAnlagenTabelleFuerVertrag",
      {
        params: { vertragsId: vertragsId },
        httpsAgent: AGENT,
        headers: {
          httpsAgent: AGENT,
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }
  getPlantShortInfoByCustomerNumber(customerNumber) {
    return axios.get(this.technicianPath + "getAnlagenFuerKunde", {
      params: { KundenNummer: customerNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyPlantsTable() {
    return axios.get(this.technicianPath + "getMeineAnlagenTabelle", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getPlantTableByCustomerNumber(customerNumber) {
    return axios.get(this.technicianPath + "getAnlagenTabelleFuerKunde", {
      params: { KundenNummer: customerNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getPlantTableByContractId(contractId) {
    return axios.get(this.technicianPath + "getAnlagenTabelleFuerVertrag", {
      params: { vertragsId: contractId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyPlantsFullInfo() {
    return axios.get(this.technicianPath + "getMeineAnlagenVoll", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMeasurementsByPlantIdAndYear(plantId, year) {
    return axios.get(this.technicianPath + "getMessungFuerAnlageMitJahr", {
      params: { agid: plantId, Jahr: year },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMeasurementsByDeviceIdAndYear(deviceNumber, year) {
    return axios.get(this.technicianPath + "getMessungFuerGeraetMitJahr", {
      params: { geraeteNummer: deviceNumber, Jahr: year },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMeasurementsByPlantId(plantId) {
    return axios.get(this.technicianPath + "getMessungFuerAnlage", {
      params: { agid: plantId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getDeviceFullInfoByDeviceNumber(deviceNumber) {
    return axios.get(this.technicianPath + "getGeraeteVollInfo", {
      params: { geraeteNummer: deviceNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMeasurementFullInfoById(measurementId) {
    return axios.get(this.technicianPath + "getMessungVollInfo", {
      params: { messungId: measurementId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getPlantsFullInfoByCustomerNumber(customerNumber) {
    return axios.get(this.technicianPath + "getAnlagenVollFuerKunde", {
      params: { KundenNummer: customerNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getPlantFullInfoByPlantNumber(plantNumber) {
    return axios.get(this.technicianPath + "getAnlagenVollInfo", {
      params: { AnlagenNummer: plantNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyFailures() {
    return axios.get(this.technicianPath + "getMeineStoerungen", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getMyClosedFailures() {
    return axios.get(this.technicianPath + "getMeineBehobenenStoerungen", {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getAllFailuresOfDevice(deviceNumber) {
    return axios.get(this.technicianPath + "getAlleStoerungenZuGeraet", {
      params: { geraeteNummer: deviceNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getDeviceNumberByQR(encryptedDeviceNumber) {
    return axios.get(this.technicianPath + "geraeteNummerMitQRCode", {
      params: { QR: encryptedDeviceNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getEncryptedDeviceNumber(decryptedDeviceNumber) {
    return axios.get(this.technicianPath + "verschluessleGeraeteNummer", {
      params: { geraeteNummer: decryptedDeviceNumber },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomersForSearch(searchString) {
    return axios.get(this.technicianPath + "getKundeSuche", {
      params: { sucheingabe: searchString },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getPlantsForSearch(searchString) {
    return axios.get(this.technicianPath + "getAnlagenSuche", {
      params: { sucheingabe: searchString },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getDevicesForSearch(searchString) {
    return axios.get(this.technicianPath + "getGeraetSuche", {
      params: { sucheingabe: searchString },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getSmartSearch(searchString) {
    return axios.get(this.technicianPath + "getIntelligenteSuche", {
      params: { sucheingabe: searchString },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getFailurePictures(failureMessageId) {
    return axios.get(this.technicianPath + "getStoerMeldungsBild", {
      params: { stoerunggsMeldungsID: failureMessageId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getNotActivatedUsersForAllCustomers() {
    return axios.get(
      this.technicianPath + "getAppKundenMitNichtFreigeschaltetenNutzern",
      {
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  saveNewLogoForCustomer(customerLogoDTO) {
    return axios.post(
      this.technicianPath + "speichereLogoZuMeinemAppKunde",
      customerLogoDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  updateFailureMessage(failureMessageDTO) {
    return axios.post(
      this.technicianPath + "speichereStoerMeldung",
      failureMessageDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getMeasurementPDF(measurementId) {
    return axios.get(this.technicianPath + "createdownload", {
      params: { messwertid: measurementId },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getLogbuchUndMessungen(geraeteNummer) {
    return axios.get(this.technicianPath + "logbuchMessungen", {
      params: { geraeteNummer: geraeteNummer },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getGeraetLogbuchInfos(vertragsnummer) {
    return axios.get(this.technicianPath + "geraetLogbuchInfos", {
      params: { vertragsId: vertragsnummer },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getEingesetzteKaeltemittelVertrag(vertragsId) {
    return axios.get(this.technicianPath + "eingesetzteKaeltemittelVertrag", {
      params: { vertragsId: vertragsId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getLogbuchJahreVertrag(vertragsId) {
    return axios.get(this.technicianPath + "logbuchJahreVertrag", {
      params: { vertragsId: vertragsId },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  geteingesetzteKaeltemittelVertragJahr(vertragsId, jahr) {
    return axios.get(
      this.technicianPath + "eingesetzteKaeltemittelVertragJahr",
      {
        params: { vertragsId: vertragsId, jahr: jahr },
        headers: {
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getLogbuchInfo() {
    return axios.get(this.technicianPath + "getLogbuchInfo", {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  speichereLogbuchInfo(logbuchInfoDTO) {
    return axios.post(
      this.technicianPath + "speichereLogbuchInfo",
      logbuchInfoDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getLogbuch(logbuchId) {
    return axios.get(this.technicianPath + "logbuch", {
      params: { logbuchId: logbuchId },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  createLogbuchEintrag(logbuchDTO) {
    return axios.post(this.technicianPath + "logbuchHinzufuegen", logbuchDTO, {
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  logbuchAktuallisieren(logbuchInfoDTO) {
    return axios.post(
      this.technicianPath + "logbuchAktuallisieren",
      logbuchInfoDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  createLogbuchPdf(geraeteNummer) {
    return axios.get(this.technicianPath + "createLogbuchPdf", {
      params: { geraeteNummer: geraeteNummer },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  findeDokumenteZuGeraet(geraeteNummer) {
    return axios.get(this.technicianPath + "findeDokumenteZuGeraet", {
      params: { geraeteNummer: geraeteNummer },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  pdfSpeichern(dokumentPdf) {
    return axios.post(this.technicianPath + "uploadDokument", dokumentPdf, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  downloadDokument(dokumentId) {
    return axios.get(this.technicianPath + "downloadDokument", {
      params: { dokumentId: dokumentId },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  loescheDokument(dokument) {
    return axios.post(this.technicianPath + "loescheDokument", dokument, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  updateEndnutzerAnzeigen(dokument) {
    return axios.post(
      this.technicianPath + "endNutzerZeigenDokument",
      dokument,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  stoerungsBemerkungHinzufuegen(bemerkung) {
    return axios.post(
      this.technicianPath + "stoerungsBemerkungHinzufuegen",
      bemerkung,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  stoerungsBemerkungBestaetigen(bestaetigung) {
    return axios.post(
      this.technicianPath + "stoerungsBemerkungBestaetigen",
      bestaetigung,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }
}
