<template>
  <div class="outer-table">
    <table class="general-table dark-overlay" v-if="!emptyResponse">
      <GeneralTableHeader
        :datatype="datatype"
        :sortAscProp="sortAscProp"
        :sortByProp="sortByProp"
        :table-header="tableCatalogue.tableType[datatype]"
        @change-order-by-click="changeOrderByClick"
      />
      <GeneralTableBody
        :datatype="datatype"
        :filter="tableCatalogue.tableType[datatype].filter"
        :routeName="getRouteName"
        :table-content="tableCatalogue.tableType[datatype].tableContent"
        :tableData="tableData"
        :function-column="functionColumn"
        :vertragsId="$attrs.vertragsId ? $attrs.vertragsId : undefined"
        @click-event="clickEvent"
        @fault-click-event="faultClickEvent"
        :summen-zeile="summenZeile"
        :summen-inhalt="summenInhalt"
      >
        <template v-slot:function="slotProps">
          <slot name="function" :item="slotProps.item" :index="slotProps.index"
            >child</slot
          >
        </template>
      </GeneralTableBody>
    </table>
    <div v-else>Keine Daten vorhanden!</div>
  </div>
</template>

<script>
import GeneralTableHeader from "./GeneralTableHeader";
import GeneralTableBody from "./GeneralTableBody";
import tableCatalogue from "@/catalogue/table-catalogue.json";

export default {
  name: "TableNew",
  data() {
    return {
      TYPES: Object.freeze({
        PLANT: "PLANT",
        USER: "USER",
        DEVICES: "DEVICES",
        ADMINISTRATION: "ADMINISTRATION",
        MAINTENANCE: "MAINTENANCE",
        MEASUREMENT: "MEASUREMENT",
        FAULT: "FAULT",
        FAULT_MELZER: "FAULT_MELZER"
      }),
      tableCatalogue: tableCatalogue,
      scrollOptions: {
        height: "100%"
      },
      sideOverlayIndex: 0,
      showSideOverlayVar: false,
      sortBy: "kundenNummer",
      sortAsc: true
    };
  },
  props: {
    tableData: {
      type: [Array, String],
      default() {
        return [];
      }
    },
    datatype: {
      type: String
    },
    // dient dazu die Sortierung vorzugeben
    sortByProp: {
      datatype: String,
      default: ""
    },
    // dient dazu die Sortierung vorzugeben
    sortAscProp: {
      datatype: Boolean,
      default: null
    },
    // sagt der Tabelle dass die Daten per REST leer sind
    emptyResponse: {
      type: Boolean,
      default: false
    },
    functionColumn: {
      type: Boolean,
      default: false
    },
    summenZeile: {
      type: Boolean,
      default: false
    },
    summenInhalt: {
      type: Object,
      default: undefined
    }
  },
  components: {
    GeneralTableHeader,
    GeneralTableBody
  },
  created() {
    if (!!this.sortByProp) {
      this.sortBy = this.sortByProp;
    }
    if (!!this.sortAscProp) {
      this.sortAsc = this.sortAscProp;
    }
  },
  methods: {
    colCountUpdate: function() {
      let eList = document.getElementsByClassName("general-table");
      for (var i = 0; i < eList.length; i++) {
        eList[i].style.setProperty(
          "grid-template-columns",
          "repeat(" +
            this.tableCatalogue.tableType[this.datatype].tableHead.length +
            ", auto)"
        );
      }
    },
    clickEvent(index) {
      this.$emit("click-event", index);
    },
    faultClickEvent(index) {
      this.$emit("fault-click-event", index);
    },
    changeOrderByClick(sortBy, sortAsc) {
      this.sortBy = sortBy;
      this.sortAsc = sortAsc;
      this.$emit("change-order-by-click", this.sortBy, this.sortAsc);
    }
  },
  mounted() {
    this.colCountUpdate();
  },
  watch: {
    datatype: function() {
      this.colCountUpdate();
    }
  },
  computed: {
    getRouteName() {
      return this.tableCatalogue.tableType[this.datatype].routeName;
    }
  }
};
</script>
