<template>
  <div class="table-has-swipe-bar margin-top-20" id="PlantMeasurements">
    <TableNew
      :emptyResponse="emptyYearsResponse"
      :tableData="yearsToShow"
      @click-event="switchMeasurementStateToMeasurementPerYear"
      datatype="MEASUREMENT_DATE"
      v-if="measurementState === measurementStates.YEARS"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="measurements[measurementsToShow].data"
      @click-event="switchMeasurementStateToMeasurementDetails"
      datatype="MEASUREMENTS_PER_YEAR"
      v-else-if="measurementState === measurementStates.MEASUREMENTS_PER_YEAR"
    />
    <MeasurementDetails
      :measurementDetailId="measurementDetailId"
      v-else-if="measurementState === measurementStates.MEASUREMENT_DETAILS"
    />
  </div>
</template>

<script>
import TableNew from "../../helper/table/generalTable";
import MeasurementDetails from "../measurements/MeasurementDetails";

export default {
  name: "PlantMeasurements",
  components: { MeasurementDetails, TableNew },
  data() {
    return {
      measurementStates: Object.freeze({
        YEARS: "YEARS",
        MEASUREMENTS_PER_YEAR: "MEASUREMENTS_PER_YEAR",
        MEASUREMENT_DETAILS: "MEASUREMENT_DETAILS"
      }),
      swipeBarState: "MAINTENANCE",
      measurementState: "YEARS",
      measurements: [],
      measurementsToShow: 0,
      measurementDetailId: 0,
      yearsToShow: [],
      emptyResponse: false
    };
  },
  props: {
    years: { type: Array },
    plantId: { type: Number },
    device: { type: Object },
    emptyYearsResponse: { type: Boolean, default: false }
  },
  computed: {
    backRouting() {
      return this.$store.state.misc.alternativeRouteBack;
    }
  },
  created() {
    this.getMeasurementsByPlantIdAndYear();
  },
  watch: {
    measurementState() {
      if (
        this.measurementState === this.measurementStates.MEASUREMENTS_PER_YEAR
      ) {
        this.$store.dispatch("misc/setEnableRouterBack", false);
      }
    },
    backRouting() {
      if (
        this.backRouting &&
        this.measurementState === this.measurementStates.MEASUREMENT_DETAILS
      ) {
        this.measurementState = this.measurementStates.MEASUREMENTS_PER_YEAR;
        this.$store.dispatch("misc/setAlternativeRouteBack", false);
      }
      if (
        this.backRouting &&
        this.measurementState === this.measurementStates.MEASUREMENTS_PER_YEAR
      ) {
        {
          this.measurementState = this.measurementStates.YEARS;
          this.$store.dispatch("misc/setAlternativeRouteBack", false);
        }
      }
    }
  },
  methods: {
    async getMeasurementsByPlantIdAndYear() {
      // Jahreszahlen in ein Object packen
      if (this.years) {
        this.years.forEach(async year => {
          this.yearsToShow.push({ year: year });
          if (this.plantId) {
            const measurement = await this.$apiCall(
              this.$technicianInfoService.getMeasurementsByPlantIdAndYear(
                this.plantId,
                year
              )
            );
            if (measurement) {
              const singleMeasurement = { name: year, data: measurement.data };
              this.measurements.push(singleMeasurement);
              if (measurement.data.length === 0) {
                this.emptyResponse = true;
              }
            }
          } else if (this.device && this.device.nummer) {
            const measurementDevice = await this.$apiCall(
              this.$technicianInfoService.getMeasurementsByDeviceIdAndYear(
                this.device.nummer,
                year
              )
            );
            if (measurementDevice) {
              const singleMeasurement = { name: year, data: measurementDevice.data };
              this.measurements.push(singleMeasurement);
              if (measurementDevice.data.length === 0) {
                this.emptyResponse = true;
              }
            }
          }
        }, this);
      }
    },
   switchMeasurementStateToMeasurementPerYear(index) {
      this.measurementsToShow = this.measurements.findIndex(
        e => e.name === this.yearsToShow[index].year
      )
      this.measurementState = this.measurementStates.MEASUREMENTS_PER_YEAR;
    },
    switchMeasurementStateToMeasurementDetails(index) {
      this.measurementDetailId = this.measurements[this.measurementsToShow].data[
        index
      ].id;
      this.measurementState = this.measurementStates.MEASUREMENT_DETAILS;
    }
  }
};
</script>

<style scoped></style>
