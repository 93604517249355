import { render, staticRenderFns } from "./ProfileMain.vue?vue&type=template&id=79dcab25&scoped=true&"
import script from "./ProfileMain.vue?vue&type=script&lang=js&"
export * from "./ProfileMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79dcab25",
  null
  
)

export default component.exports