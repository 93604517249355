import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import AcceptFailureMessage from "../views/AcceptFailureMessage.vue";
import QrCode from "../views/QrCode.vue";
import Login from "../views/Login.vue";

import ShortInfo from "../components/qrCode/ShortInfo.vue";
import ShortInfoDetails from "../components/qrCode/ShortInfoDetails.vue";
import SendFailureMessage from "../components/qrCode/SendFailureMessage.vue";
import CheckMail from "../components/qrCode/CheckMail.vue";
import Dashboard from "../components/main/dashboard/Dashboard.vue";
import MyPlants from "../components/main/myPlants/MyPlants.vue";
import PlantDetails from "../components/main/myPlants/PlantDetails.vue";
import Settings from "../components/main/settings/Settings.vue";
import Profile from "../components/main/settings/Profile.vue";
import User from "../components/main/settings/User.vue";
import DeviceDetails from "../components/main/device/DeviceDetails";
import Administration from "../components/main/administration/Administration";

import axios from "axios";
import store from "../store/index.js";
import UserDetails from "../components/main/settings/UserDetails";
import Customers from "../components/main/melzer/Customers";
import Logbuch from "../components/main/logbuch/Logbuch";
import PlantsForCustomer from "../components/main/melzer/PlantsForCustomer";
import CreateUser from "../components/helper/CreateUser";
import ScanQrCode from "../components/main/scanQrCode/ScanQrCode";
import CustomerAdministration from "../components/main/administration/CustomerAdministration";
import Registration from "../views/Registration";
import CreateQrCode from "../components/main/createQrCode/CreateQrCode";
import Failures from "../components/main/Failures";
import Search from "../components/helper/Search";
import DeviceSendFailureMessage from "../components/main/sendFailureMessage/DeviceSendFailureMessage";
import Contracts from "../components/main/contracts/Contracts";
import ContractsForCustomer from "../components/main/melzer/ContractsForCustomer";
import ResetPassword from "../views/ResetPassword";
import RequestNewPassword from "../views/RequestNewPassword";
import PageNotFound from "../views/PageNotFound";
import App from "../App";
import ProfileMain from "../components/main/settings/ProfileMain";
import ProfileSettings from "../components/helper/ProfileSettings";
import TechnicianConfiguration from "../components/main/settings/TechnicianConfiguration";
import AnlagenLogbuch from "../components/main/settings/AnlagenLogbuch";
import Maintenances from "../components/main/myPlants/Maintenances";
import PlantDevices from "../components/main/myPlants/PlantDevices";
import PlantMeasurements from "../components/main/myPlants/PlantMeasurements";
import deviceInfo from "../components/main/device/deviceInfo";
import DeviceFailures from "../components/main/device/DeviceFailures";
import DeviceLogbuch from "../components/main/device/DeviceLogbuch";
import ChangeOwnPassword from "../components/helper/ChangeOwnPassword";
import CustomerConfiguration from "../components/main/administration/CustomerConfiguration";
import AdministrationTechnicians from "../components/main/administration/AdministrationTechnicians";
import CustomersNotActivatedMelzerUsers from "../components/main/administration/CustomersNotActivatedMelzerUsers";
import CreateLogbuchEintrag from "@/components/helper/CreateLogbuchEintrag";
import EditLogbuchEintrag from "@/components/helper/EditLogbuchEintrag";
import Logbuecher from "../components/main/logbuch/LogbuchUebersicht";
import LogbuchKaeltemittel from "@/components/main/logbuch/LogbuchKaeltemittel";
import LogbuchStatistik from "@/components/main/logbuch/LogbuchStatistik";
import LogbuchStatistikJahre from "@/components/main/logbuch/LogbuchStatistikJahre";
import EditLogbuchInfo from "@/components/helper/EditLogbuchInfo";
import LogbuchEintragDetails from "@/components/main/logbuch/LogbuchEintragDetails";
import DeviceTechnischeDaten from "@/components/main/device/DeviceTechnischeDaten";
import ShortInfoBedienung from "@/components/qrCode/ShortInfoBedienung";
Vue.use(VueRouter);
Vue.use(axios);

function guardRouteMelzerTechnician(to, from, next) {
  if (!isVerification(to, next) && !isQrCode(to, next)) {
    let isAuthenticated = isTechnician() || isAdministrator();
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  }
}

function guardRouteTechnician(to, from, next) {
  let isAuthenticated = isTechnician() || isAdministrator();
  if (isAuthenticated) {
    next();
  } else {
    next({ name: "login" });
  }
}

function guardRouteAdministrator(to, from, next) {
  let isAuthenticated = isAdministrator();
  if (isAuthenticated) {
    next();
  } else {
    next({ name: "login" });
  }
}

function isTechnician() {
  return getUserRole() === 6;
}

function isAdministrator() {
  return getUserRole() === 10;
}

function isMelzerUser() {
  return store.getters["user/isMelzerUser"];
}

function getUserToken() {
  return store.getters["user/token"];
}

function getUserRole() {
  return store.getters["user/role"];
}

function checkForGetRequests(to, from, next) {
  if (isPasswordReset(to, next)) {
    routeToPasswordReset(to, next);
  } else if (isVerification(to, next)) {
    routeToVerification(to, next);
  } else if (isQrCode(to)) {
    routeToQrCode(to, next);
  } else {
    next();
  }
}

function isQrCode(to) {
  return !!to.query["QR"] || !!to.query["id"];
}

function routeToQrCode(to, next) {
  let deviceNumber = !!to.query["QR"] ? to.query["QR"] : to.query["id"];
  next({
    name: "infoPage",
    params: {
      deviceNumber: deviceNumber
    }
  });
}

function isVerification(to) {
  return !!to.query["id"] && !!to.query["geheimnis"];
}

function routeToVerification(to, next) {
  next({
    name: "failureMessageAccept",
    params: {
      id: to.query["id"],
      secret: to.query["geheimnis"]
    }
  });
}

function isPasswordReset(to) {
  return !!to.query["type"] && !!to.query["secret"];
}

function routeToPasswordReset(to, next) {
  next({
    name: "resetPassword",
    params: {
      secret: to.query["secret"]
    }
  });
}

const routes = [
  {
    path: "/",
    component: App,
    children: [
      {
        path: "",
        redirect: "home" // default child path
      },
      {
        path: "home",
        component: Home,
        beforeEnter: guardRouteTechnician, // guards whole route
        children: [
          {
            path: "",
            redirect: "dashboard" // default child path
          },
          {
            path: "dashboard",
            name: "dashboard",
            component: Dashboard
          },
          {
            path: "contracts",
            name: "contracts",
            component: Contracts
          },
          {
            path: "plants",
            name: "plants",
            component: MyPlants
          },
          {
            path: "logbuchCustomers",
            name: "logbuchCustomers",
            component: Customers
          },
          {
            path: "contractsForCustomersLogbuch/:nr",
            name: "contractsForCustomersLogbuch",
            component: ContractsForCustomer
          },
          {
            path: "logbuch/:nr",
            component: Logbuch,
            children: [
              {
                path: "/",
                redirect: "logbuecher" // default child path
              },
              {
                path: "logbuecher",
                name: "logbuecher",
                component: Logbuecher,
                props: true
              },
              {
                path: "kaeltemittel",
                name: "logbuchKaeltemittel",
                component: LogbuchKaeltemittel,
                props: true
              },
              {
                path: "statistikJahre",
                name: "logbuchStatistikJahre",
                component: LogbuchStatistikJahre,
                props: true
              },
              {
                path: "statistikJahre/:secondparam/statistik",
                name: "logbuchStatistik",
                component: LogbuchStatistik,
                props: true
              }
            ]
          },
          {
            path: "customers",
            name: "customers",
            component: Customers
          },
          {
            path: "failures",
            name: "failures",
            component: Failures
          },
          {
            path: "deviceSendFailureMessage/:deviceNr/:plantNr",
            name: "deviceSendFailureMessage",
            component: DeviceSendFailureMessage
          },
          {
            path: "scan",
            name: "scan",
            component: ScanQrCode
          },
          {
            path: "generateQR",
            name: "generateQR",
            beforeEnter: guardRouteAdministrator,
            component: CreateQrCode
          },
          {
            path: "contractsForCustomer/:nr",
            name: "contractsForCustomer",
            component: ContractsForCustomer
          },
          {
            path: "plantsForCustomer/:nr",
            name: "plantsForCustomer",
            component: PlantsForCustomer
          },
          {
            path: "plantDetails/:nr",
            component: PlantDetails,
            children: [
              {
                path: "",
                redirect: "maintenances" // default child path
              },
              {
                path: "maintenances",
                name: "maintenances",
                component: Maintenances,
                props: true
              },
              {
                path: "devices",
                name: "devices",
                component: PlantDevices,
                props: true
              },
              {
                path: "measurements",
                name: "measurements",
                component: PlantMeasurements,
                props: true
              }
            ]
          },
          {
            path: "userDetails/:nr",
            name: "userDetails",
            component: UserDetails
          },
          {
            path: "deviceDetails/:nr",
            component: DeviceDetails,
            children: [
              {
                path: "",
                redirect: "deviceInfo" // default child path
              },
              {
                path: "info",
                name: "deviceInfo",
                component: deviceInfo
              },
              {
                path: "faults",
                name: "deviceFailures",
                component: DeviceFailures
              },
              {
                path: "technischeDaten",
                name: "technischeDaten",
                component: DeviceTechnischeDaten
              },
              {
                path: "measurements",
                name: "deviceMeasurements",
                component: PlantMeasurements
              },
              {
                path: "logbuch",
                name: "deviceLogbuch",
                component: DeviceLogbuch
              },
              {
                path: "neuerlogbuchEintrag",
                name: "neuerlogbuchEintrag",
                component: CreateLogbuchEintrag
              },
              {
                path: "logbuchEintragDetails/:secondparam",
                name: "logbuchEintragDetails",
                component: LogbuchEintragDetails,
                props: true
              },
              {
                path: "logbuchEintragbearbeiten/:secondparam",
                name: "logbuchEintragbearbeiten",
                component: EditLogbuchEintrag,
                props: true
              }
            ]
          },
          {
            path: "search/:type/:searchString",
            name: "search",
            component: Search
          },
          {
            path: "settings",
            component: Settings,
            children: [
              {
                path: "",
                redirect: "profile" // default child path
              },
              {
                path: "profile",
                component: Profile,
                children: [
                  {
                    path: "",
                    redirect: "profileMain" // default child path
                  },
                  { path: "main", name: "profileMain", component: ProfileMain },
                  {
                    path: "edit",
                    name: "editProfile",
                    component: ProfileSettings
                  },
                  {
                    path: "password",
                    name: "changePassword",
                    component: ChangeOwnPassword
                  }
                ]
              },
              { path: "user", name: "user", component: User },
              {
                path: "configuration",
                name: "configuration",
                component: TechnicianConfiguration
              },
              {
                path: "logbuch",
                name: "anlagenLogbuch",
                component: AnlagenLogbuch
              },
              {
                path: "logbuchSettings",
                name: "anlagenLogbuchSettings",
                component: EditLogbuchInfo
              }
            ]
          },
          {
            path: "createUser/:customerNumber/:appUserId",
            name: "createUser",
            component: CreateUser
          },
          {
            path: "administration",
            name: "administration",
            beforeEnter: guardRouteAdministrator,
            component: Administration
          },
          {
            path: "customerNotActivatedMelzerUsers",
            name: "customerNotActivatedMelzerUsers",
            beforeEnter: guardRouteAdministrator,
            component: CustomersNotActivatedMelzerUsers
          },
          {
            path: "customerAdministration/:nr/:appId",
            beforeEnter: guardRouteAdministrator,
            component: CustomerAdministration,
            children: [
              {
                path: "",
                redirect: "technicians" // default child path
              },
              {
                path: "technicians",
                name: "technicians",
                component: AdministrationTechnicians
              },
              {
                path: "configuration",
                name: "customerConfiguration",
                component: CustomerConfiguration
              }
            ]
          }
        ]
      },
      {
        path: "login",
        name: "login",
        component: Login
      },
      {
        path: "registration",
        name: "registration",
        component: Registration
      },
      {
        path: "QR",
        name: "qr",
        component: QrCode,
        children: [
          {
            path: "info/:deviceNumber",
            name: "infoPage",
            component: ShortInfo
          },
          {
            path: "info/:deviceNumber/details",
            name: "infoPageDetails",
            component: ShortInfoDetails
          },
          {
            path: "send/mail/:deviceNumber",
            name: "sendFailureMessage",
            component: SendFailureMessage
          },
          {
            path: "check/mail/:deviceNumber",
            name: "checkMail",
            component: CheckMail
          },
          {
            path: "info/:deviceNumber/bedienung",
            name: "bedienung",
            component: ShortInfoBedienung
          }
        ]
      },
      {
        path: "failureMessage/accept/:id/:secret",
        name: "failureMessageAccept",
        component: AcceptFailureMessage
      },
      {
        path: "requestNewPassword",
        name: "requestNewPassword",
        component: RequestNewPassword
      },
      {
        path: "resetPassword/:email/:secret",
        name: "resetPassword",
        component: ResetPassword
      },
      {
        path: "pageNotFound",
        name: "pageNotFound",
        component: PageNotFound
      }
    ]
  },
  {
    path: "*",
    redirect: "pageNotFound"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  checkForGetRequests(to, from, next);
});

export default router;
