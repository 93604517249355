var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{class:_vm.isLinking ? '' : 'nicht-clickbar'},[_vm._l((_vm.tableData),function(item,trIndex){return _c('tr',{key:item.nr,on:{"click":function($event){return _vm.clickEvent(trIndex)}}},[(_vm.isLinking)?_c('router-link',{attrs:{"to":{
        name: _vm.routeName,
        params: {
          nr: _vm.getRouteParameter(item),
          appId: _vm.getAppIdParameter(item),
          secondparam: _vm.getRouteSecondParameter(item)
        }
      }}},[_vm._l((_vm.tableContent),function(itemB,index){return _c('td',{key:itemB.nr},[(_vm.filter[index] === 'date')?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item[itemB]))+" ")]):(_vm.filter[index] === 'dateWithoutTime')?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateWithoutTime")(item[itemB]))+" ")]):(_vm.filter[index] === 'aktiv')?_c('div',[_vm._v(" "+_vm._s(_vm._f("aktiv")(item[itemB]))+" ")]):(_vm.filter[index] === 'status')?_c('div',[_vm._v(" "+_vm._s(_vm._f("status")(item[itemB]))+" ")]):(_vm.filter[index] === 'role')?_c('div',[_vm._v(" "+_vm._s(_vm._f("role")(item[itemB]))+" ")]):(_vm.filter[index] === 'boolean')?_c('div',[_vm._v(" "+_vm._s(_vm._f("boolean")(item[itemB]))+" ")]):(_vm.filter[index] === 'maintenanceState')?_c('div',[_vm._v(" "+_vm._s(_vm._f("maintenanceState")(item[itemB]))+" ")]):_c('div',[(_vm.showFailureStatus(item, item[itemB]))?_c('div',{class:_vm.hasNotResolvedFailures(item)
                ? 'red-circle'
                : _vm.isInProcess(item)
                ? 'yellow-circle'
                : 'green-circle'}):_c('div',[_vm._v(" "+_vm._s(item[itemB])+" ")])])])}),(_vm.functionColumn)?_c('td',{staticClass:"function-column"},[_vm._t("function",null,{"item":item,"index":trIndex})],2):_vm._e()],2):_vm._l((_vm.tableContent),function(itemB,index){return _c('td',{key:itemB.nr,staticStyle:{"cursor":"default"}},[(_vm.filter[index] === 'date')?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item[itemB]))+" ")]):(_vm.filter[index] === 'dateWithoutTime')?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateWithoutTime")(item[itemB]))+" ")]):(_vm.filter[index] === 'aktiv')?_c('div',[_vm._v(" "+_vm._s(_vm._f("aktiv")(item[itemB]))+" ")]):(_vm.filter[index] === 'status')?_c('div',[_vm._v(" "+_vm._s(_vm._f("status")(item[itemB]))+" ")]):(_vm.filter[index] === 'role')?_c('div',[_vm._v(" "+_vm._s(_vm._f("role")(item[itemB]))+" ")]):(_vm.filter[index] === 'maintenanceState')?_c('div',[_vm._v(" "+_vm._s(_vm._f("maintenanceState")(item[itemB]))+" ")]):_c('div',[_vm._v(_vm._s(item[itemB]))])])}),(_vm.functionColumn)?_c('td',{staticClass:"function-column"},[_vm._t("function",null,{"item":item,"index":trIndex})],2):_vm._e()],2)}),(_vm.summenZeile)?_c('tr',{staticClass:"summen-zeile"},[_c('td',[_vm._v("Summe")]),_vm._l((_vm.summenInhalt),function(summe,index){return _c('td',{key:index},[_vm._v(_vm._s(summe))])})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }