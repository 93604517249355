<template>
  <div class="content-background" id="ProfileMain">
    <div>
      <h4>Logbuch Details:</h4>
      <div class="basic-data-grid margin-top-20">
        <div>Datum:</div>
        <div>{{ logbuchDetails.datum | dateWithoutTime }}</div>
        <div>Kältemittelart:</div>
        <div>{{ logbuchDetails.kaeltemittelArt }}</div>
        <div>Gefüllt (kg):</div>
        <div>{{ logbuchDetails.gefuellt }}</div>
        <div>Entsorgt (kg):</div>
        <div>{{ logbuchDetails.entsorgt }}</div>
        <div>Angenommer Verlust (kg):</div>
        <div>{{ logbuchDetails.angenommenVerlust }}</div>
        <div>Grund:</div>
        <div>{{ logbuchDetails.grund }}</div>
        <div>Sachkundiger:</div>
        <div>{{ logbuchDetails.sachkundiger }}</div>
      </div>
    </div>
    <div @click="editInfo" class="button square-button dark-blue top-corner">
      <font-awesome-icon icon="pen" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogbuchEintragDetails",
  data() {
    return {
      logbuchDetails: {}
    };
  },
  props: {
    secondparam: {
      type: String,
      default: ""
    }
  },
  created() {
    this.getLogbuch();
  },
  methods: {
    async getLogbuch() {
      const logbuch = await this.$apiCall(
        this.$technicianInfoService.getLogbuch(this.secondparam)
      );
      if (logbuch) {
        this.logbuchDetails = logbuch.data;
      }
    },
    editInfo() {
      this.$router.push({
        name: "logbuchEintragbearbeiten",
        params: {
          logbuchDetails: this.logbuchDetails,
          secondparam: this.secondparam
        }
      });
    }
  }
};
</script>

<style scoped></style>
