<template>
  <div id="Pagination">
    <paginate
      :break-view-class="'break'"
      :break-view-text="'|'"
      :click-handler="changePage"
      :container-class="'pagination-container'"
      :disabled-class="'inactive'"
      :margin-pages="1"
      :next-text="'>>'"
      :page-class="'pagination-page'"
      :page-count="pages"
      :page-range="3"
      :prev-text="'<<'"
      v-model="paginationDTO.seite"
    >
    </paginate>
    <select
      @change="resetPage"
      class="pagination-entry-count"
      v-model="paginationDTO.seitenGroese"
    >
      <option v-bind:key="entry" v-for="entry in entrysPerPage"
        >{{ entry }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      pages: 1,
      entrysPerPage: [10, 25, 50],
      nextPageIcon: "<font-awesome-icon icon='pen' />"
    };
  },
  props: {
    paginationDTO: {
      type: Object,
      default() {
        return {
          seite: 0,
          seitenGroese: 50,
          orderBy: "nutztMIA",
          nutztMIA: null,
          ordneAufsteigend: true,
          filterBegriffe: []
        };
      }
    }
  },
  watch: {
    paginationDTO: {
      deep: true,
      handler() {
        this.getPageCount();
      }
    }
  },
  created() {
    this.getPageCount();
  },
  methods: {
    getPageCount() {
      this.$technicianInfoService
        .getCustomersPaginationPageCount(this.paginationDTO)
        .then(response => {
          this.pages = response.data;
        })
        .catch(error => {
          this.$handleRestExceptions(error.response.status, this.$router);
        });
    },
    resetPage() {
      this.paginationDTO.seite = 0;
      this.$emit("update-page");
    },
    changePage() {
      this.$emit("update-page");
    }
  }
};
</script>

<style scoped>
.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}
</style>
