export const ResponseEnum = Object.freeze({
  OK: "OK",
  KeineBerechtigung: "KeineBerechtigung",
  BenutzernameExistiertBereits: "BenutzernameExistiertBereits",
  BenutzernameExistiertNicht: "BenutzernameExistiertNicht",
  UngueltigeEingabe: "UngueltigeEingabe",
  BenutzerExistiertNicht: "BenutzerExistiertNicht",
  BenutzerNichtFreigeschaltet: "BenutzerNichtFreigeschaltet",
  PasswortFalsch: "PasswortFalsch",
  FalscheDaten: "FalscheDaten",
  InternerFehler: "InternerFehler"
});
