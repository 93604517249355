import axios from "axios";
import store from "../store/index.js";
import { BASE_URL, BEARER } from "./configuration";

export default class UserInfoService {
  userInfoPath = BASE_URL + "BenutzerInfo/";

  registerUser(userDTO) {
    return axios.post(this.userInfoPath + "registiereBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  sendDeviceFailure(failureMessageDTO) {
    return axios.post(this.userInfoPath + "meldeStoerung", failureMessageDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  acceptFailureMessage(failureMessageAcceptDTO) {
    return axios.post(
      this.userInfoPath + "bestaetigeStoerung",
      failureMessageAcceptDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  getDeviceShortInfoByDeviceNumber(number) {
    return axios.get(this.userInfoPath + "geraeteInfoSeiteMitGeraetNummer", {
      params: { geraeteNummer: number },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getDeviceShortInfoByDeviceNumberQR(qr) {
    return axios.get(this.userInfoPath + "geraeteInfoSeiteMitQRCode", {
      params: { QR: qr },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomerLogo(customerId) {
    return axios.get(this.userInfoPath + "holekundenlogo", {
      params: { id: customerId },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getCustomerLogoByAppUserId() {
    return axios.get(this.userInfoPath + "holekundenlogoMitAppKundenID", {
      params: { appKundenID: store.getters["user/appUserId"] },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  getDeviceShowConfigByDeviceNumber(number) {
    return axios.get(
      this.userInfoPath + "geraeteAnzeigeKonfigMitGeraeteNummer",
      {
        params: { geraeteNummer: number },
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  requestResetPassword(resetPasswordDTO) {
    return axios.post(
      this.userInfoPath + "setzeBenutzerPasswortZurueck",
      resetPasswordDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  acceptResetPassword(resetPasswordDTO) {
    return axios.post(
      this.userInfoPath + "bestaetigePasswortZurueckSetzen",
      resetPasswordDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  acceptDSGVO(tempLoginData) {
    return axios.post(
      this.userInfoPath + "dsgvo",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + tempLoginData.token
        }
      }
    );
  }

  findeDokumenteZuGeraet(geraeteNummer) {
    return axios.get(this.userInfoPath + "findeDokumenteZuGeraet", {
      params: { geraeteNummer: geraeteNummer },
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  downloadDokument(id) {
    return axios.get(this.userInfoPath + "downloadDokument", {
      params: { dokumentId: id },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }
}
