<template>
  <div class="search-wrap" ref="searchWrap" v-click-outside="hideSearchResults">
    <input
      placeholder="Kunden, Anlagen oder Geräte suchen"
      type="text"
      v-model="searchString"
      ref="search"
    />
    <input @click="search" id="search-submit" type="submit" />
    <label for="search-submit">
      <font-awesome-icon
        :icon="callPending ? 'snowflake' : 'search'"
        :class="callPending ? 'fa-spin' : ''"
      />
    </label>
    <div id="search-results" v-show="searchString !== ''">
      <ul v-if="!showDialog">
        <li>
          <span>{{ searchPlaceholder }}</span>
        </li>
      </ul>
      <ul v-show="showDialog">
        <li
          v-if="
            searchDTO.anzahlGefundenerAppKunden === 0 &&
              searchDTO.anzahlGefundenerAnlagen === 0 &&
              searchDTO.anzahlGefundenerGeraete === 0
          "
        >
          <span>Keine Ergebnisse gefunden!</span>
        </li>
        <li>
          <ul v-if="searchDTO.anzahlGefundenerAppKunden > 0">
            <li><span class="font-bold">Kunden</span></li>
            <li
              class="search-result"
              v-bind:key="kunde.appKundenID"
              v-for="kunde in searchDTO.appKunden"
            >
              <router-link
                :to="{
                  name: 'contractsForCustomer',
                  params: getCustomerParams(kunde)
                }"
                @click="hideSearchResults"
              >
                {{ kunde.kdNameTabellenAnzeige }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'search',
                  params: {
                    type: SEARCH_CUSTOMER,
                    searchString: searchString
                  }
                }"
              >
                <span v-if="searchDTO.anzahlGefundenerAppKunden > 3"
                  >Es wurden insgesamt
                  {{ searchDTO.anzahlGefundenerAppKunden - 3 }} weitere Kunden
                  gefunden</span
                >
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <ul
            class="margin-top-20"
            v-if="searchDTO.anzahlGefundenerAnlagen > 0"
          >
            <li><span class="font-bold">Anlagen</span></li>
            <li
              class="search-result"
              v-bind:key="anlage.nr"
              v-for="anlage in searchDTO.anlagen"
            >
              <router-link
                :to="{ name: 'maintenances', params: { nr: anlage.nr } }"
              >
                Anlage mit der Nummer: {{ anlage.nr }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'search',
                  params: {
                    type: SEARCH_PLANTS,
                    searchString: searchString
                  }
                }"
              >
                <span v-if="searchDTO.anzahlGefundenerAnlagen > 3"
                  >Es wurden insgesamt
                  {{ searchDTO.anzahlGefundenerAnlagen - 3 }} weitere Anlagen
                  gefunden</span
                >
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <ul
            class="margin-top-20"
            v-if="searchDTO.anzahlGefundenerGeraete > 0"
          >
            <li><span class="font-bold">Geräte</span></li>
            <li
              class="search-result"
              v-bind:key="geraet.nr"
              v-for="geraet in searchDTO.geraete"
            >
              <router-link
                :to="{ name: 'deviceInfo', params: { nr: geraet.nummer } }"
              >
                <span>Gerät mit der Nummer: {{ geraet.nummer }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'search',
                  params: {
                    type: SEARCH_DEVICES,
                    searchString: searchString
                  }
                }"
              >
                <span v-if="searchDTO.anzahlGefundenerGeraete > 3"
                  >Es wurden insgesamt
                  {{ searchDTO.anzahlGefundenerGeraete - 3 }} weitere Geräte
                  gefunden</span
                >
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      showDialog: false,
      searchString: "",
      searchDTO: {},
      callPending: false,
      SEARCH_CUSTOMER: "SEARCH_CUSTOMER",
      SEARCH_PLANTS: "SEARCH_PLANTS",
      SEARCH_DEVICES: "SEARCH_DEVICES"
    };
  },
  computed: {
    customerPath: function() {
      switch (this.$route.name) {
        case "customers" ||
          "plantsForCustomer" ||
          "plantDetails" ||
          "deviceDetails":
          return "plantsForCustomer";
      }
      return "plantsForCustomer";
    },
    searchPlaceholder() {
      return this.callPending
        ? "Bitte Warten, es wird gesucht..."
        : "Sie müssen mindestens 4 Zeichen eingeben!";
    }
  },
  methods: {
    search() {
      // hide the dialog until data has arrived
      // this.showDialog = false;
      if (this.searchString.length < 4) {
        this.showDialog = false;
      }
      if (this.searchString.length > 3) {
        this.callPending = true;
        this.$technicianInfoService
          .getSmartSearch(this.searchString)
          .then(response => {
            this.searchDTO = response.data;
            // show dialog
            this.showDialog = true;
            this.callPending = false;
          })
          .catch(error => {
            this.callPending = false;
            this.$handleRestExceptions(error.response.status, this.$router);
          });
      }
    },
    resetSearch() {
      this.searchDTO = null;
    },
    getCustomerParams(kunde) {
      switch (this.$route.name) {
        case "customers":
        case "plantsForCustomer":
        case "plantDetails":
        case "deviceDetails":
          return { nr: kunde.kundenNummer };
        default:
          return { nr: kunde.kundenNummer, appId: kunde.appKundenId };
      }
    },
    hideSearchResults(event) {
      this.showDialog = false;
      this.searchString = "";
      this.$refs.search.blur();
      this.$refs.searchWrap.blur();
    }
  },
  watch: {
    $route(to, from) {
      this.$refs.search.blur();
      this.searchString = "";
    },
    searchString() {
      this.search();
    },
    callPending() {
      if (this.callPending) {
        this.showDialog = false;
      }
    }
  }
};
</script>
