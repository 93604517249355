<template>
  <div class="content-background margin-top-20" id="customerConfiguration">
    <h4>Störungsmeldungen</h4>
    <div class="basic-data-grid width800">
      <div>E-Mail</div>
      <div>
        <input
          @change="updateCustomer"
          type="email"
          v-model="customerConfiguration.stoerungsMeldungsEMailFach"
          v-bind:class="{
            validationError: !$v.customerConfiguration
              .stoerungsMeldungsEMailFach.email
          }"
        />
        <span
          class="error-label"
          v-show="!$v.customerConfiguration.stoerungsMeldungsEMailFach.email"
          >Gültige E-Mail Adresse eingeben</span
        >
      </div>
    </div>
    <h4 class="margin-top-20">Kurzinformationen</h4>
    <div class="grid-with-open-ends width800">
      <div>Logo</div>
      <div class="picture-gallery margin-bottom-10" v-if="showNewLogo">
        <ImageWrapper
          :index="0"
          :isValidImage="isValidLogoType"
          :src="customerLogoDTO.base64Data"
          @delete-picture="deletePicture"
        />
      </div>
      <div v-if="showNewLogo"></div>
      <div class="flex-row-start">
        <input
          @change="chooseNewLogo"
          accept="image/*"
          class="button small-button margin-right-20 display-none"
          id="hochladen-button"
          type="file"
          value="Durchsuchen"
        /><label for="hochladen-button">
          <div class="button small-button margin-right-20">
            <span>Durchsuchen</span>
          </div>
        </label>
        <input
          :disabled="!canSetNewLogo"
          @click="saveAppCustomerLogo"
          class="button small-button"
          type="button"
          v-bind:class="{
            'button-lightblue': canSetNewLogo,
            'button-disabled': !canSetNewLogo
          }"
          value="Hochladen"
        />
      </div>

      <div style="align-self: start">Anzeigeoptionen</div>

      <div class="checkbox-grid">
        <label>zeige Baujahr</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeBaujahr"
        />
        <label>zeige Fabrikat</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeFabrikat"
        />
        <label>zeige Gerätenummer</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeNummer"
        />
        <label>zeige Gerätebeschreibung</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeInfo"
        />
        <label>zeige Anlage</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeAgNr"
        />
        <label>zeige Gerätetyp</label>
        <input
          @change="updateCustomer"
          class="checkbox-switch"
          type="checkbox"
          v-model="customerConfiguration.zeigeTyp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { imageToBase64 } from "../../../services/helpServices";
import ImageWrapper from "../../helper/ImageWrapper";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "CustomerConfiguration",
  components: {
    ImageWrapper
  },
  data() {
    return {
      customerLogoDTO: {
        name: "",
        type: "",
        base64Data: "",
        kundenNummer: 0
      }
    };
  },
  props: {
    customerConfiguration: {
      type: Object
    }
  },
  computed: {
    canSetNewLogo() {
      return !!this.customerLogoDTO.base64Data && this.isValidLogoType;
    },
    showNewLogo() {
      return this.customerLogoDTO.base64Data !== "";
    },
    isValidLogoType() {
      return this.customerLogoDTO.type.indexOf("image/") >= 0;
    }
  },
  created() {
    this.customerLogoDTO.kundenNummer = this.customerConfiguration.kundenNummer;
  },
  methods: {
    async saveAppCustomerLogo() {
      const _response = await this.$apiCall(
        this.$administrationService.saveAppCustomerLogo(this.customerLogoDTO)
      );
      this.customerLogoDTO.base64Data = "";
      this.$toast.success("Logo erfolgreich geändert!");
    },
    async updateCustomer() {
      if (!this.$v.$invalid) {
        const _response = await this.$apiCall(
          this.$administrationService.saveAppCustomer(
            this.customerConfiguration
          )
        );
        this.$toast.success("Kunde erfolgreich aktualisiert!");
      }
    },
    async chooseNewLogo(event) {
      let file = event.target.files[0];
      this.customerLogoDTO.base64Data = await imageToBase64(file);
      this.customerLogoDTO.name = file.name;
      this.customerLogoDTO.type = file.type;
    },
    deletePicture(index) {
      this.customerLogoDTO.name = "";
      this.customerLogoDTO.type = "";
      this.customerLogoDTO.base64Data = "";
      this.customerLogoDTO.kundenNummer = 0;
    }
  },
  validations() {
    return {
      customerConfiguration: {
        stoerungsMeldungsEMailFach: {
          required,
          email
        }
      }
    };
  }
};
</script>

<style scoped></style>
