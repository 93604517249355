<template>
  <div class="table-details-has-filter" id="user">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      class="margin-top-20"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="users"
      @change-order-by-click="changeOrderByClick"
      datatype="USER"
      v-if="restDataLoaded"
    />
    <input
      @click="createUser"
      class="button margin-top-20"
      type="button"
      value="Nutzer hinzufügen"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import FrontendFilter from "../../helper/table/FrontendFilter";
import {
  changeOrderOfTable,
  createUserDisplayName
} from "../../../services/helpServices";

export default {
  name: "User",
  components: {
    FrontendFilter,
    TableNew
  },
  data() {
    return {
      restDataLoaded: false, // show table after rest data is loaded, because the table doesn`t show the created displayName
      ownAppUserId: null,
      users: [],
      deactivatedUsers: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      tableOptions: [
        { name: "zeige nur freigeschaltete Benutzer", value: false },
        { name: "zeige beide", value: false }
      ],
      emptyResponse: false
    };
  },
  created() {
    this.getMyUsers();
  },
  methods: {
    async getMyUsers() {
      const myUsers = await this.$apiCall(
        this.$technicianInfoService.getMyUsers()
      );
      if (myUsers) {
        this.users = myUsers.data;
        this.tableDataBackup = myUsers.data;
        if (myUsers.data.length === 0) {
          this.emptyResponse = true;
        }
        const deactivatedUsers = await this.$apiCall(
          this.$technicianInfoService.getMyDeactivatedUsers()
        );
        if (deactivatedUsers) {
          this.users.concat(deactivatedUsers.data);
          this.users.forEach(
            (user, index) =>
              (this.users[index].displayName = createUserDisplayName(
                user.nachname,
                user.vorname
              ))
          );
        }
        this.restDataLoaded = true;
      }
    },
    createUser() {
      this.$router.push({
        name: "createUser",
        params: { customerNumber: this.$store.getters["user/customerNumber"] }
      });
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.users = changeOrderOfTable(this.tableDataBackup, this.paginationDTO);
    }
  }
};
</script>

<style scoped></style>
