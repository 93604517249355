<template>
  <div
    v-show="showButton"
    id="PlantsOverviewButton"
    class="button"
    @click="goToPlants"
  >
    {{ buttonName }}
  </div>
</template>

<script>
export default {
  name: "PlantsOverviewButton",
  data() {
    return {};
  },
  computed: {
    showButton() {
      return (
        this.$route.path.includes("plantDetails") ||
        this.$route.path.includes("deviceDetails")
      );
    },
    buttonName() {
      return this.$route.path.includes("plantDetails") ? "Anlagen" : "Anlage";
    }
  },
  methods: {
    goToPlants: function() {
      let isInDevice = this.$route.path.includes("deviceDetails"),
        isAdmin = this.$store.getters["user/role"] === 10,
        isMelzerUser = this.$store.getters["user/isMelzerUser"],
        name = "",
        params = {};
      if (isInDevice) {
        name = "devices";
        params = { nr: this.$store.getters["misc/plantNumber"] };
      } else {
        params = { nr: this.$store.getters["misc/contract"] };
        if (isAdmin || isMelzerUser) {
          name = "plantsForCustomer";
        } else {
          name = "plants";
        }
      }
      this.$router.push({
        name: name,
        params: params
      });
    }
  }
};
</script>

<style scoped></style>
