<template xmlns="http://www.w3.org/1999/html">
  <div class="table-details-has-filter" id="user">
    <FrontendFilter
      class="margin-top-20"
      @update-filter="updateFilter"
      :filters="paginationDTO.filterBegriffe"
    />
    <TableNew
      :key="rerenderKey"
      :emptyResponse="emptyResponse"
      :tableData="dokumenteEintraegePage"
      datatype="DEVICETECHNISCHEDATEN"
      :function-column="true"
      v-if="restDataLoaded"
      @change-order-by-click="changeOrderByClick"
    >
      <template v-slot:function="slotProps">
        <div class="end-user-anzeige">
          <input
            type="checkbox"
            v-model="dokumente[slotProps.index].endNutzerZeigen"
            @change="updateEndnutzerAnzeigen(slotProps.index)"
          />
        </div>
        <div class="pdf-anzeigen">
          <input
            type="button"
            class="button button-grau second-lvl margin-right-20"
            value="PDF anzeigen"
            @click="downloadPDF(slotProps.index)"
          />
          <button
            v-if="isMelzer"
            class="button button-grau second-lvl"
            @click="loescheDokument(slotProps.item)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </template>
    </TableNew>
    <div class="pagination">
      <div class="button-container">
        <input
          v-if="isMelzer"
          class="button"
          type="button"
          value="PDF Hochladen"
          @click="
            toggleModal();
            resetDokumentDTO;
          "
        />
      </div>
      <paginate
        :page-count="pages"
        :page-range="3"
        v-model="paginationDTO.aktuelleSeite"
        :click-handler="paginateTableDataBackup"
        :break-view-class="'break'"
        :break-view-text="'|'"
        :container-class="'pagination-container'"
        :disabled-class="'inactive'"
        :margin-pages="1"
        :next-text="'>>'"
        :page-class="'pagination-page'"
        :prev-text="'<<'"
      />
    </div>
    <div class="modalwrapper" v-if="showModal">
      <div class="modal width800">
        <h2>PDF hochladen</h2>
        <div class="basic-data-grid margin-top-20">
          <label>PDF auswählen:</label>
          <div>
            <input
              id="technische-daten-pdf-upload"
              type="file"
              value="PDF Hochladen"
              accept="application/pdf"
              @change="choosePdf"
            />
            <span class="error-label" v-show="!$v.dokumentDTO.base64Data"
              >Datei auswählen</span
            >
          </div>
          <div>Dokumentname:</div>
          <div>
            <input
              class="input-edit"
              type="text"
              placeholder="Wird automatisch eingetragen"
              v-model="$v.dokumentDTO.quellDateiName.$model"
            />
            <span class="error-label" v-show="!$v.dokumentDTO.quellDateiName"
              >Datei auswählen</span
            >
          </div>
          <div>Typ:</div>
          <div>
            <select v-model="$v.dokumentDTO.typ.$model">
              <option :value="''" selected disabled hidden>
                Bitte auswählen
              </option>
              <option
                v-for="(typ, index) in dokumentTypListe"
                :value="typ"
                :key="index"
              >
                {{ typ }}</option
              >
            </select>
            <span class="error-label" v-show="!$v.dokumentDTO.typ"
              >Datei auswählen</span
            >
          </div>
          <label>Für Endnutzer anzeigen:</label>
          <div>
            <input
              class="checkbox-switch"
              type="checkbox"
              v-model="$v.dokumentDTO.endNutzerZeigen.$model"
            />
          </div>
        </div>
        <div class="margin-top-20">
          <input
            type="button"
            class="button confirm-button margin-right-20"
            value="PDF speichern"
            @click="savePdf()"
            :disabled="!canSave"
            v-bind:class="{
              'button-lightblue': canSave,
              'button-disabled': !canSave
            }"
          />
          <input
            type="button"
            class="button"
            value="Abbrechen"
            @click="
              toggleModal();
              resetDokumentDTO();
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";
import { pdfToBase64 } from "../../../services/helpServices";
import { maxLength, numeric, required } from "vuelidate/lib/validators";

export default {
  name: "DeviceTechnischeDaten",
  components: {
    FrontendFilter,
    TableNew
  },
  data() {
    return {
      restDataLoaded: false,
      dokumente: [],
      dokumentDTO: {
        geraeteNummer: "",
        typ: "",
        endNutzerZeigen: false,
        base64Data: "",
        quellDateiName: ""
      },
      dokumentUpdate: {
        id: null,
        endNutzerZeigen: false
      },
      emptyResponse: false,
      paginationDTO: {
        orderBy: "datum",
        ordneAufsteigend: false,
        filterBegriffe: [],
        aktuelleSeite: 1
      },
      tableDataBackup: [],
      dokumenteEintraegePage: [],
      showModal: false,
      dokumentTypListe: [
        "Sicherheitshinweis",
        "Bedienungsanleitung",
        "Beschreibung",
        "Bild",
        "Sonstiges"
      ],
      rerenderKey: 0
    };
  },
  props: {
    geraeteNummer: {
      type: String,
      default: ""
    },
    device: {
      type: Object,
      default: null
    }
  },
  computed: {
    pages() {
      return Math.ceil((this.dokumente.length - 1) / 20);
    },
    canSave() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    isMelzer() {
      return this.$store.getters["user/isMelzerUser"];
    }
  },
  created() {
    this.getDokumente();
  },
  methods: {
    async getDokumente(render) {
      const dokumente = await this.$apiCall(
        this.$technicianInfoService.findeDokumenteZuGeraet(this.geraeteNummer)
      );
      if (dokumente) {
        this.dokumente = dokumente.data;
        this.tableDataBackup = dokumente.data;
        this.paginateTableDataBackup(this.paginationDTO.aktuelleSeite);
        this.restDataLoaded = true;
        if (dokumente.data.length === 0) {
          this.emptyResponse = true;
        } else {
          this.emptyResponse = false;
        }
        if (render === true) {
          this.rerenderKey = Date.now();
        }
      }
    },
    async downloadPDF(index) {
      const id = this.dokumente[index].id;
      const dokument = await this.$apiCall(
        this.$technicianInfoService.downloadDokument(id)
      );
      if (dokument) {
        const url = window.URL.createObjectURL(new Blob([dokument.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = this.dokumente[index].speicherPfad + ".pdf";
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        // window.open(url, "_blank"); // im neuen Tab oeffnen
        link.click(); //für den Download
      }
    },
    async loescheDokument(dokument) {
      const _response = await this.$apiCall(
        this.$technicianInfoService.loescheDokument(dokument)
      );
      this.$toast.success("Dokument erfolgreich gelöscht!");
      this.getDokumente(true);
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.dokumente = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
      this.paginationDTO.aktuelleSeite = 1;
      this.paginateTableDataBackup();
    },
    paginateTableDataBackup() {
      let page = this.paginationDTO.aktuelleSeite - 1;
      let start = page * 20;
      let end = start + 20;
      let eintraege = this.dokumente.slice(start, end);
      this.dokumenteEintraegePage = eintraege;
    },
    async choosePdf(event) {
      let name = event.target.files[0].name;
      if (
        (name.substring(name.lastIndexOf(".") + 1, name.length) || name) ===
        "pdf"
      ) {
        let file = event.target.files[0];
        name = name.replace(/\.[^/.]+$/, "");
        this.dokumentDTO.quellDateiName = name;
        this.$v.dokumentDTO.base64Data.$model = await pdfToBase64(file);
      } else {
        document.getElementById("technische-daten-pdf-upload").value = "";
        name = "";
        this.resetDokumentDTO();
        this.$toast.error("Falscher Dateityp. Es sind nur PDFs erlaubt!");
      }
    },
    async savePdf() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dokumentDTO.geraeteNummer = this.geraeteNummer;
        const _response = await this.$apiCall(
          this.$technicianInfoService.pdfSpeichern(this.dokumentDTO)
        );
        this.$toast.success("Dokument erfoglreich hochgeladen!");
        this.toggleModal();
        await this.getDokumente(true);
        this.resetDokumentDTO();
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
      this.resetDokumentDTO();
    },
    async updateEndnutzerAnzeigen(index) {
      this.dokumentUpdate.id = this.dokumente[index].id;
      this.dokumentUpdate.endNutzerZeigen = this.dokumente[
        index
      ].endNutzerZeigen;
      const _response = await this.$apiCall(
        this.$technicianInfoService.updateEndnutzerAnzeigen(this.dokumentUpdate)
      );
    },
    resetDokumentDTO() {
      this.dokumentDTO.geraeteNummer = "";
      this.dokumentDTO.quellDateiName = "";
      this.dokumentDTO.typ = "";
      this.dokumentDTO.endNutzerZeigen = false;
      this.dokumentDTO.base64Data = "";
    }
  },
  validations() {
    let v = {
      dokumentDTO: {
        quellDateiName: { required },
        typ: { required },
        base64Data: { required },
        endNutzerZeigen: { required }
      }
    };
    return v;
  }
};
</script>

<style scoped lang="scss"></style>
