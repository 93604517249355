var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.device.hatLogbuch)?_c('div',{staticClass:"table-details-has-filter",attrs:{"id":"user"}},[_c('FrontendFilter',{staticClass:"margin-top-20",attrs:{"filters":_vm.paginationDTO.filterBegriffe},on:{"update-filter":_vm.updateFilter}}),(_vm.restDataLoaded)?_c('TableNew',{attrs:{"emptyResponse":_vm.emptyResponse,"tableData":_vm.logbuchEintraegePage,"datatype":"DEVICELOGBUCH","function-column":true},on:{"change-order-by-click":_vm.changeOrderByClick},scopedSlots:_vm._u([{key:"function",fn:function(slotProps){return [_c('div',{staticClass:"eintrag-bearbeiten"},[_c('router-link',{attrs:{"to":{
            name: 'logbuchEintragbearbeiten',
            params: {
              logbuchDetails: _vm.logbuchEintraege[slotProps.index],
              secondparam: _vm.logbuchEintraege[slotProps.index].id
            }
          }}},[_c('input',{class:_vm.logbuchEintraege[slotProps.index].id
                ? 'button button-grau second-lvl'
                : 'device-logbuch-messung',attrs:{"type":"button","value":!_vm.logbuchEintraege[slotProps.index].id ? 'Messung' : 'Bearbeiten',"disabled":!_vm.logbuchEintraege[slotProps.index].id}})])],1)]}}],null,false,236914021)}):_vm._e(),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"button-container"},[(_vm.isMelzer && _vm.device.hatLogbuch)?_c('input',{staticClass:"button margin-right-20",attrs:{"type":"button","value":"Logbuch-Eintrag erfassen"},on:{"click":function($event){return _vm.createLogbucheintrag()}}}):_vm._e(),(_vm.logbuchEintraege.length > 0 && _vm.device.hatLogbuch)?_c('input',{staticClass:"button",attrs:{"type":"button","value":"PDF erzeugen"},on:{"click":function($event){return _vm.createPdf()}}}):_vm._e()]),(_vm.device.hatLogbuch)?_c('paginate',{attrs:{"page-count":_vm.pages,"page-range":3,"click-handler":_vm.paginateTableDataBackup,"break-view-class":'break',"break-view-text":'|',"container-class":'pagination-container',"disabled-class":'inactive',"margin-pages":1,"next-text":'>>',"page-class":'pagination-page',"prev-text":'<<'},model:{value:(_vm.paginationDTO.aktuelleSeite),callback:function ($$v) {_vm.$set(_vm.paginationDTO, "aktuelleSeite", $$v)},expression:"paginationDTO.aktuelleSeite"}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }