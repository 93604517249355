<template>
  <div id="profile">
    <router-view />
    <!--    <ProfileSettings-->
    <!--      :userData="userDTO"-->
    <!--      @cancel="cancelEditProfile"-->
    <!--      @save="saveEditProfile"-->
    <!--      canChangeAvatar="true"-->
    <!--      profile=""-->
    <!--      v-if="editingProfile"-->
    <!--    />-->
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      userDTO: {},
      editingProfile: false,
      changePassword: false
    };
  },
  methods: {
    editProfile() {
      this.editingProfile = true;
    },
    cancelEditProfile() {
      this.editingProfile = false;
    },
    saveEditProfile() {
      this.editingProfile = false;
    }
  }
};
</script>

<style scoped></style>
