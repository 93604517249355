var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"side-overlay-background"}),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],attrs:{"id":"sideOverlay"}},[_c('div',{staticClass:"side-overlay-header"},[_c('h3',[_vm._v("Störung im Status "+_vm._s(_vm.bearbeitungsStatusComp))]),_c('div',{staticClass:"flex-row-center"},[_c('div',{staticClass:"button margin-right-20",on:{"click":_vm.changeBearbeitungsStatus}},[_vm._v(" "+_vm._s(_vm.labelButtonStatus)+" ")]),_c('div',{staticClass:"button square-button",on:{"click":function($event){return _vm.closeSideOverlay()}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]),_c('div',{staticClass:"meldungsdaten"},[_c('p',[_vm._v("Störungsmeldung Nummer: "+_vm._s(_vm.sideData.id))]),_c('p',[_vm._v(" Datum der Störungsaufnahme: "+_vm._s(_vm._f("date")(_vm.sideData.stoerungsZeitpunkt))+" ")]),_c('p',[_vm._v(" Aufgenommen von: "),_c('a',{staticClass:"font-lightblue",attrs:{"href":'mailto:' +
              _vm.sideData.eMailAdresse +
              '?subject=' +
              encodeURIComponent('Störungsmeldung ' + _vm.sideData.geraeteNummer)}},[_vm._v(_vm._s(_vm.sideData.eMailAdresse))])]),_c('p',[_vm._v("Störungsbeschreibung: "+_vm._s(_vm.sideData.stoerungsbeschreibung))]),(_vm.sideData.anzahlBilder > 0 && !_vm.isGalleryLoaded)?_c('div',{staticClass:"button button-gallery",on:{"click":_vm.loadGallery}},[_vm._v(" Bilder laden ("+_vm._s(_vm.sideData.anzahlBilder)+" Anhänge) ")]):_vm._e(),(_vm.sideData.anzahlBilder > 0 && _vm.isGalleryLoaded)?_c('div',{staticClass:"picture-gallery margin-bottom-20"},_vm._l((_vm.sideData.stoerungsBilder),function(picture){return _c('img',{key:picture.id,attrs:{"src":picture.base64Data},on:{"click":function($event){_vm.showImg($event);
            _vm.bigPicture = true;}}})}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bigPicture),expression:"bigPicture"}],staticClass:"img-container",attrs:{"id":"bigPictureStoerung"},on:{"click":function($event){_vm.bigPicture = false}}}),_vm._l((_vm.sideData.stoerungsBemerkungen),function(bemerkung){return _c('div',{key:bemerkung.id,class:[
          bemerkung.automatischErstellt ? 'systemnachricht' : 'bemerkung',
          {
            'ist-ungelesen': !bemerkung.gelesen,
            'ist-eigene':
              bemerkung.vonMelzerErstellt === _vm.userDTO.istMelzerUser
          }
        ]},[_c('div',{staticClass:"nachricht"},[(
              !bemerkung.gelesen &&
                bemerkung.vonMelzerErstellt !== _vm.userDTO.istMelzerUser
            )?_c('div',{staticClass:"button",on:{"click":function($event){return _vm.bemerkungBestaetigen(bemerkung.id)}}},[_vm._v(" zur Kenntnis genommen ")]):_vm._e(),_vm._v(" "+_vm._s(bemerkung.bemerkungsText)+" ")]),_c('div',{staticClass:"metadaten"},[_vm._v(" "+_vm._s(bemerkung.verfasserVorname)+" "+_vm._s(bemerkung.verfasserName)+" "+_vm._s(bemerkung.verfasserFirmaName ? "von " + bemerkung.verfasserFirmaName + ";" : "")+" "+_vm._s(_vm._f("date")(bemerkung.erstellDatum))+" ")]),(!bemerkung.automatischErstellt && bemerkung.gelesen)?_c('div',{staticClass:"metadaten"},[_vm._v(" "+_vm._s(bemerkung.gelesenStatus === 2 ? "Autom. bestätigt" : "Zur Kenntnis genommen")+"; "+_vm._s(_vm._f("date")(bemerkung.gelesenDatum))+" ")]):_vm._e()])}),(_vm.sideData.bearbeitungsStatus !== 'Behoben')?_c('div',{staticClass:"bemerkung-schreiben"},[_c('span',[_vm._v("Bemerkung:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.eingabeNeueBemerkung.$model),expression:"$v.eingabeNeueBemerkung.$model"}],class:{
            validationError:
              !_vm.$v.eingabeNeueBemerkung.maxLength ||
              _vm.meldungBemerkungBenoetigtSichtbar
          },attrs:{"placeholder":"Bitte eine Bemerkung angeben."},domProps:{"value":(_vm.$v.eingabeNeueBemerkung.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.eingabeNeueBemerkung, "$model", $event.target.value)}}}),_vm._v(" "),(_vm.meldungBemerkungBenoetigtSichtbar)?_c('div',{staticClass:"error-label margin-bottom-10"},[_vm._v(" Störung kann erst Erledigt markiert werden, wenn mindestens eine Anmerkung hinzugefügt wurde. ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.$v.eingabeNeueBemerkung.$anyError &&
              _vm.$v.eingabeNeueBemerkung.$dirty
          ),expression:"\n            !$v.eingabeNeueBemerkung.$anyError &&\n              $v.eingabeNeueBemerkung.$dirty\n          "}],staticClass:"button",on:{"click":_vm.bemerkungHinzufuegen}},[_vm._v(" Anmerkung Speichern ")])]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }