<template>
  <div class="main-content" id="Contracts">
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      locationHeading="Verträge"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :tableData="contracts"
      @change-order-by-click="changeOrderByClick"
      datatype="CONTRACTS"
    />
  </div>
</template>

<script>
import TableNew from "@/components/helper/table/generalTable.vue";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

export default {
  name: "Contracts",
  data() {
    return {
      contracts: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      },
      emptyResponse: false
    };
  },
  components: { FrontendFilter, TableNew },
  created() {
    this.getMyContracts();
  },
  methods: {
    async getMyContracts() {
      const myContracts = await this.$apiCall(
        this.$technicianInfoService.getMyContracts()
      );
      if (myContracts) {
        this.contracts = myContracts.data;
        this.tableDataBackup = myContracts.data;
        if (myContracts.data.length === 0) this.emptyResponse = true;
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.contracts = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
    }
  }
};
</script>
