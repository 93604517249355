<template>
  <div class="table-details-has-filter" id="DeviceFailures">
    <SideOverlay
      :side-data="getTableRowById(sideOverlayId)"
      @close-click="closeSideOverlay"
      @close-event="closeSideOverlay"
      @reload-device-details="reloadDeviceDetails"
      class="side-overlay"
      ref="testUnique"
      v-if="showSideOverlayVar"
    />
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilter"
      class="margin-top-20"
    />
    <TableNew
      :emptyResponse="emptyFailureResponse"
      :tableData="tableData"
      @change-order-by-click="changeOrderByClick"
      @fault-click-event="showSideOverlay"
      :datatype="datatype"
    >
    </TableNew>
  </div>
</template>

<script>
import SideOverlay from "../../helper/sideOverlay";
import TableNew from "../../helper/table/generalTable";
import FrontendFilter from "../../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../../services/helpServices";

export default {
  name: "DeviceFailures",
  components: { FrontendFilter, TableNew, SideOverlay },
  data() {
    return {
      sideOverlayId: 0,
      showSideOverlayVar: false,
      sideOverlayWidth: 0,
      tableData: [],
      paginationDTO: {
        orderBy: "",
        ordneAufsteigend: true,
        filterBegriffe: []
      }
    };
  },
  props: {
    device: {
      type: Object
    },
    emptyFailureResponse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    datatype() {
      return this.$store.getters["user/isMelzerUser"]
        ? "FAULT_MELZER"
        : "FAULT";
    }
  },
  watch: {
    device() {
      this.tableData = this.device.offeneStoerungen;
    }
  },
  created() {
    this.tableData = this.device.offeneStoerungen;
  },
  methods: {
    showSideOverlay(index) {
      this.showSideOverlayVar = !this.showSideOverlayVar;
      this.sideOverlayId = this.tableData[index].id;
    },
    closeSideOverlay() {
      this.showSideOverlayVar = !this.showSideOverlayVar;
    },
    getTableRowById(id) {
      for (let row of this.tableData) {
        if (row.id === id) {
          return row;
        }
      }
      return {};
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.sortTable();
    },
    updateFilter(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.sortTable();
    },
    sortTable() {
      this.tableData = changeOrderOfTable(
        this.device.offeneStoerungen,
        this.paginationDTO
      );
    },
    reloadDeviceDetails() {
      this.$emit("reload-device-details");
    }
  }
};
</script>

<style scoped></style>
