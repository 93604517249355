<template>
  <div id="Failures">
    <div class="table-area">
      <div id="swipeBar" class="tab-bar margin-bottom-20">
        <div
          v-for="(opt, index) in statusFilterOptions"
          :key="index"
          :class="[
            'tab-bar-element',
            { selected: filterStatus === opt.keyName }
          ]"
          @click="updateFilterStatus(opt.keyName)"
        >
          {{ opt.displayName }} ({{ failureCount[opt.keyName] }})
        </div>
      </div>
    </div>
    <FrontendFilter
      :filters="paginationDTO.filterBegriffe"
      @update-filter="updateFilterBegriffe"
      location-heading="Störungen"
    />
    <TableNew
      :emptyResponse="emptyResponse"
      :sortAscProp="paginationDTO.ordneAufsteigend"
      :sortByProp="paginationDTO.orderBy"
      :tableData="failureListFiltered"
      :datatype="isMelzerUser ? 'FAILURE_LIST_MELZER' : 'FAILURE_LIST'"
      @change-order-by-click="changeOrderByClick"
    />
  </div>
</template>

<script>
import TableNew from "../helper/table/generalTable";
import FrontendFilter from "../helper/table/FrontendFilter";
import { changeOrderOfTable } from "../../services/helpServices";

export default {
  name: "Failures",
  components: { FrontendFilter, TableNew },
  data() {
    return {
      failureList: [],
      failureListFiltered: [],
      tableDataBackup: [],
      paginationDTO: {
        orderBy: "stoerungsZeitpunkt",
        ordneAufsteigend: false,
        filterBegriffe: []
      },
      statusFilterOptions: [
        {
          keyName: "Neu",
          displayName: "Offen"
        },
        {
          keyName: "InBearbeitung",
          displayName: "In Bearbeitung"
        }
      ],
      emptyResponse: false,
      isMelzerUser: false,
      filterStatus: "Neu",
      failureCount: {
        Alle: 0,
        Neu: 0,
        InBearbeitung: 0
      },
      numberOfNew: 0,
      numberOfInProgress: 0
    };
  },
  created() {
    this.isMelzerUser = this.$store.getters["user/isMelzerUser"] === true;
    this.getfailureList();
  },
  methods: {
    async getfailureList() {
      const failures = await this.$apiCall(
        this.$technicianInfoService.getMyFailures()
      );
      if (failures) {
        this.failureList = failures.data;
        this.tableDataBackup = failures.data;
        if (failures.data.length === 0) this.emptyResponse = true;
        this.changeOrderByClick("zustaendigkeitMelzer", false);
      }
    },
    changeOrderByClick(orderBy, ascending) {
      this.paginationDTO.orderBy = orderBy;
      this.paginationDTO.ordneAufsteigend = ascending;
      this.prepareFailureList();
    },
    updateFilterBegriffe(filter) {
      this.paginationDTO.filterBegriffe = filter;
      this.prepareFailureList();
    },
    updateFilterStatus(status) {
      this.filterStatus = status;
      this.prepareFailureList();
    },
    prepareFailureList() {
      this.failureList = changeOrderOfTable(
        this.tableDataBackup,
        this.paginationDTO
      );
      let counter = {
          Alle: this.failureList.length,
          Neu: 0,
          InBearbeitung: 0
        },
        filteredList = [];
      for (let failure of this.failureList) {
        if (failure.bearbeitungsStatus in counter) {
          counter[failure.bearbeitungsStatus]++;
        }
        if (
          this.filterStatus === "Alle" ||
          this.filterStatus === failure.bearbeitungsStatus
        ) {
          filteredList.push(failure);
        }
      }
      this.failureListFiltered = filteredList;
      this.failureCount = counter;
    }
  }
};
</script>

<style scoped></style>
