<template>
  <div>
    <div id="dashboard">
      <div></div>
      <DashboardNotifications></DashboardNotifications>
      <div class="tiles">
        <Tile :path="plantPath" :title="plantTitle" symbol="anlage" />
        <Tile
          :path="logbuchPath"
          :params="userDTO.kundenNummer"
          title="Logbücher"
          symbol="logbuch"
        />
        <Tile path="profileMain" title="Einstellungen" symbol="einstellungen" />
        <Tile path="scan" title="Gerät scannen" symbol="scannen" />
        <Tile
          path="failures"
          :title="'Störungen: ' + failureList.length"
          :subtitle="'Neue Bemerkungen: ' + failureTileNewComments"
          :symbol="failureTileColor"
        />
        <Tile
          path="administration"
          title="Administration"
          v-if="isAdministrator"
          symbol="administration"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Tile from "@/components/main/Tile.vue";
import DashboardNotifications from "./DashboardNotifications";

export default {
  name: "Dashboard",
  components: {
    DashboardNotifications,
    Tile
  },
  data() {
    return {
      isAdministrator: false,
      isMelzerUser: false,
      plantPath: "",
      plantTitle: "",
      userDTO: {},
      failureList: [],
      failureTileColor: "",
      failureTileNewComments: 0
    };
  },
  computed: {
    logbuchPath() {
      if (this.isMelzerUser || this.isAdministrator) {
        return "logbuchCustomers";
      } else {
        return "contractsForCustomersLogbuch";
      }
    }
  },
  created() {},
  mounted() {
    this.isAdministrator = this.$store.getters["user/role"] === 10;
    // TODO: pruefen ob === true gebraucht wird
    this.isMelzerUser = this.$store.getters["user/isMelzerUser"] === true;
    this.plantPath = this.isMelzerUser ? "customers" : "contracts";
    this.plantTitle = this.isMelzerUser ? "Kunden Anlagen" : "Meine Anlagen";
    this.getUserDTO();
    this.loadFailureList();
  },
  methods: {
    async getUserDTO() {
      const user = await this.$apiCall(
        this.$technicianInfoService.getUserByName(
          this.$store.getters["user/userName"]
        )
      );
      if (user) {
        this.userDTO = user.data;
      }
    },
    async loadFailureList() {
      const myFailures = await this.$apiCall(
        this.$technicianInfoService.getMyFailures()
      );
      if (myFailures) {
        this.failureList = myFailures.data;
        this.computeFailureTile();
      }
    },
    computeFailureTile() {
      let countNewFailures = 0;
      let countNewComments = 0;
      for (let failure of this.failureList) {
        if (failure.bearbeitungsStatus === "Neu") {
          countNewFailures++;
        }
        if (this.isMelzerUser && failure.ungeleseneKundenBemerkungen) {
          countNewComments += failure.ungeleseneKundenBemerkungen;
        } else if (!this.isMelzerUser && failure.ungeleseneMelzerBemerkungen) {
          countNewComments += failure.ungeleseneMelzerBemerkungen;
        }
      }
      if (countNewFailures > 0) {
        this.failureTileColor = "stoerung-rot";
      } else if (this.failureList.length > 0) {
        this.failureTileColor = "stoerung-gelb";
      } else {
        this.failureTileColor = "stoerung";
      }
      this.failureTileNewComments = countNewComments;
    }
  }
};
</script>
