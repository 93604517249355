var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"general-wrapper center-grid",attrs:{"id":"sendFailureMessage"}},[_c('div',{staticClass:"flex-column-center"},[_c('img',{staticClass:"short-info-logo",attrs:{"src":_vm.customerLogo.base64Data,"alt":"Logo der Firma"}}),_c('h2',[_vm._v("Störungsmeldung")]),_c('h3',[_vm._v(" Sie sind dabei eine Störungsmeldung an Melzer Klimatechnik zu senden. ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.failureMessageDTO.eMailAdresse.$model),expression:"$v.failureMessageDTO.eMailAdresse.$model"}],attrs:{"id":"StoerungsEmail","placeholder":"E-Mail Adresse eintragen","type":"email"},domProps:{"value":(_vm.$v.failureMessageDTO.eMailAdresse.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.failureMessageDTO.eMailAdresse, "$model", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.$v.failureMessageDTO.eMailAdresse.email ||
          !_vm.$v.failureMessageDTO.eMailAdresse.required
      ),expression:"\n        !$v.failureMessageDTO.eMailAdresse.email ||\n          !$v.failureMessageDTO.eMailAdresse.required\n      "}],staticClass:"error-label"},[_vm._v(" Gültige E-Mail Adresse eintragen ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.failureMessageDTO.stoerungsbeschreibung.$model),expression:"$v.failureMessageDTO.stoerungsbeschreibung.$model"}],class:{
        validationError: !_vm.$v.failureMessageDTO.stoerungsbeschreibung.maxLength
      },attrs:{"placeholder":"Hier Beschreibung hinzufügen"},domProps:{"value":(_vm.$v.failureMessageDTO.stoerungsbeschreibung.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.failureMessageDTO.stoerungsbeschreibung, "$model", $event.target.value)}}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.failureMessageDTO.stoerungsbeschreibung.required),expression:"!$v.failureMessageDTO.stoerungsbeschreibung.required"}],staticClass:"error-label"},[_vm._v("Beschreibung erforderlich")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.failureMessageDTO.stoerungsbeschreibung.maxLength),expression:"!$v.failureMessageDTO.stoerungsbeschreibung.maxLength"}],staticClass:"error-label"},[_vm._v("Maximal 500 Zeichen")]),_c('div',{staticClass:"width800"},[_c('input',{attrs:{"accept":"image/*","id":"imgUpload","name":"imgUpload","type":"file"},on:{"change":_vm.addFailurePicture}}),_c('label',{attrs:{"for":"imgUpload"}},[_c('div',{staticClass:"imgUploadAlt"},[_c('h3',[_vm._v("Foto hochladen")]),_c('div',{staticClass:"button"},[_c('font-awesome-icon',{attrs:{"icon":"camera"}})],1)])])]),(_vm.failureMessageDTO.stoerungsBilder.length > 0)?_c('div',{staticClass:"width800"},[_c('div',{staticClass:"picture-gallery"},_vm._l((_vm.$v.failureMessageDTO.stoerungsBilder.$each
            .$iter),function(v,index){return _c('div',{key:v.name},[_c('ImageWrapper',{attrs:{"index":parseInt(index),"isValidImage":v.typ.isValidImage,"src":v.$model.base64Data},on:{"delete-picture":_vm.deletePicture}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"width800",staticStyle:{"text-align":"right"}},[_c('input',{staticClass:"button confirm-button",class:{
          'button-lightblue': _vm.canSave,
          'button-disabled': !_vm.canSave
        },attrs:{"disabled":!_vm.canSave,"type":"button","value":"Senden"},on:{"click":function($event){return _vm.sendFailure()}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }