<template>
  <div id="customerAdministration">
    <SwipeBar
      :elements="swipeBarElements"
      class="tab-bar"
      type="PLANT"
      :customer="customerConfiguration.kdNameTabellenAnzeige"
    />
    <router-view
      :customerConfiguration="customerConfiguration"
      :emptyTechniciansResponse="emptyTechniciansResponse"
      :key="customerConfiguration.appKundenId"
      :users="users"
    />
  </div>
</template>

<script>
import SwipeBar from "../../helper/SwipeBar";
import { createUserDisplayName } from "../../../services/helpServices";
import user from "@/store/modules/user";

export default {
  name: "CustomerAdministration",
  components: { SwipeBar },
  data() {
    return {
      users: [],
      customerConfiguration: {
        appKundenId: 0,
        kundenNummer: 0,
        stoerungsMeldungsEMailFach: "",
        aktiv: false,
        nutztMIA: false,
        kundenLogo: 9,
        infoSeitenBeschreibung: "",
        zeigeVtNr: false,
        zeigeAgNr: false,
        zeigeName: false,
        zeigeInfo: false,
        zeigeNummer: false,
        zeigeBaujahr: false,
        zeigeEinbauDate: false,
        zeigeFabrikat: false,
        zeigeTyp: false,
        zeigeBstNr: false,
        zeigeSN: false,
        zeigeGarantie: false,
        zeigeStatus: false,
        kdMatch: "",
        kdName1: "",
        kdName2: "",
        kdName3: "",
        kdNameTabellenAnzeige: "",
        kdAnschriftStr: "",
        kdAnschriftHNr: "",
        kdAnschriftPLZ: "",
        kdAnschriftOrt: ""
      },
      swipeBarElements: [
        { name: "Techniker", route: "technicians" },
        { name: "Konfiguration", route: "customerConfiguration" }
      ],
      emptyTechniciansResponse: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let customerNumber = this.$route.params.nr;
      let customerAppId = this.$route.params.appId;
      const userCustomer = await this.$apiCall(
        this.$technicianInfoService.getUsersOfCustomer(customerAppId)
      );
      if (userCustomer) {
        this.users = userCustomer.data;
        if (this.users.length === 0) this.emptyTechniciansResponse = true;
        this.users.forEach(
          (user, index) =>
            (this.users[index].displayName = createUserDisplayName(
              user.nachname,
              user.vorname
            ))
        );
      }
      const customerConfig = await this.$apiCall(
        this.$administrationService.getCustomerConfig(customerNumber)
      );
      if (customerConfig) {
        this.customerConfiguration = customerConfig.data;
      }
    }
  }
};
</script>

<style scoped></style>
