import axios from "axios";
import store from "../store/index.js";
import { BASE_URL, BEARER } from "./configuration";

export default class AdministrationService {
  administrationPath = BASE_URL + "Administration/";

  getCustomerConfig(customerNumber) {
    return axios.get(this.administrationPath + "getKundenKonfig", {
      params: {
        kundenNummer: customerNumber
      },
      headers: {
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  createAppCustomer(customerDTO) {
    return axios.post(
      this.administrationPath + "erstelleAppKunde",
      customerDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  saveAppCustomer(customerDTO) {
    return axios.post(
      this.administrationPath + "speichereAppKunde",
      customerDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  saveAppCustomerLogo(customerLogoDTO) {
    return axios.post(
      this.administrationPath + "speichereLogoZuAppKunde",
      customerLogoDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  deleteAppCustomer(customerDTO) {
    return axios.post(
      this.administrationPath + "loescheAppKunde",
      customerDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  createUser(userDTO) {
    userDTO.freigeschaltet = true;
    return axios.post(this.administrationPath + "erstelleBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  saveUser(userDTO) {
    return axios.post(this.administrationPath + "speichereBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }

  saveUserWithPassword(userDTO) {
    return axios.post(
      this.administrationPath + "speichereBenutzerMitPasswort",
      userDTO,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + store.getters["user/token"]
        }
      }
    );
  }

  deleteUser(userDTO) {
    return axios.post(this.administrationPath + "loescheBenutzer", userDTO, {
      headers: {
        "Content-Type": "application/json",
        Authorization: BEARER + store.getters["user/token"]
      }
    });
  }
}
